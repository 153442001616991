<template>
  <v-menu offset-y transition="slide-y-transition" origin="top right">
    <template v-slot:activator="{ on, attrs }">
      <AppBtnWithIndicator
        v-bind="attrs"
        v-on="on"
        :indicatorText="selectedText"
        :text="$t('common.view')"
        show-indicator
      />
    </template>
    <div class="pa-2 ui-background d-flex flex-column" style="min-width: 70px">
      <AppMenuItem
        v-for="type in calendarTypes"
        :key="type.key"
        :text="$options.filters.capitalize(type.text)"
        :icon="type.icon"
        :isSelected="calendarType === type.key"
        @click="emit(type.key)"
      />
    </div>
  </v-menu>
</template>

<script>
export default {
  props: {
    calendarType: String,
    model: String,
  },
  data() {
    return {
      calendarTypes: [
        {
          text: this.$t("common.day"),
          icon: this.$icon.REGULAR.COMMON.CALENDAR_DAY,
          key: "day",
        },
        {
          text: this.$t("common.week"),
          icon: this.$icon.REGULAR.COMMON.CALENDAR_WEEK,
          key: "week",
        },
        {
          text: this.$t("common.month"),
          icon: this.$icon.REGULAR.COMMON.CALENDAR_DAYS,
          key: "month",
        },
      ],
    };
  },
  computed: {
    selectedText() {
      return this.calendarTypes.find((type) => type.key === this.calendarType).text;
    },
  },
  methods: {
    emit(type) {
      this.$emit("type:change", type);
    },
  },
};
</script>
