<template>
  <AppMenuItem
    :text="$t('common.goTo')"
    :disabled="disabled"
    :icon="$icon.REGULAR.COMMON.ARROW_RIGHT"
    @click="click"
  />
</template>

<script>
export default {
  props: {
    disabled: Boolean,
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>
