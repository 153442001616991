<template>
  <v-dialog v-model="localDialog" max-width="600px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $t("actionRequest.remindDialog.title") }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-simple-table
          class="border-a overflow-hidden rounded mb-4"
          fixed-header
          height="200px"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left input-background" style="height: 30px">
                  <span class="text--disabled">
                    {{ $t("actionRequest.remindDialog.tableTitle") }}
                    ({{ recipients.length }})
                  </span>
                </th>
                <th class="text-left input-background" style="height: 30px"></th>
              </tr>
            </thead>
            <tbody class="border-radius-0">
              <tr
                v-for="(recipient, i) in recipients"
                :key="i"
                class="cursor-pointer"
                @click="selectRecipient(recipient)"
              >
                <td class="border-radius-0">
                  <v-icon
                    class="pr-2"
                    :color="canRecipientBeSelected(recipient) ? '' : 'disabled'"
                  >
                    {{
                      isRecipientSelected(recipient)
                        ? $icon.SOLID.COMMON.SQUARE_CHECK
                        : $icon.LIGHT.COMMON.SQUARE
                    }}
                  </v-icon>
                  <span
                    :class="canRecipientBeSelected(recipient) ? '' : 'text--disabled'"
                  >
                    {{ recipient.name }} ({{ recipient.email }})
                  </span>
                </td>
                <td>
                  <AppColorChip
                    :text="getRecipientStatusText(recipient)"
                    :color="getRecipientStatusColor(recipient)"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="label--small">
          {{ $t("actionRequest.remindDialog.messageLabel") }}
        </div>
        <AppDefaultTextarea ref="messageTextarea" v-model="message" label="" rows="2" />
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onSubmit"
          @cancel="close"
          :confirmText="$t('actionRequest.remindDialog.confirmButton')"
          :loading="isReminding"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { remindActionRequest } from "@/services/actionRequest/actionRequest";

export default {
  data() {
    return {
      isReminding: false,
      isValid: false,
      selectedEmails: [],
      message: "",
    };
  },
  props: {
    dialog: Boolean,
    actionRequestId: String,
    recipients: Array,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  methods: {
    isRecipientSelected(recipient) {
      return this.selectedEmails.includes(recipient.email);
    },
    canRecipientBeSelected(recipient) {
      return recipient.status === "IN_PROGRESS";
    },
    selectRecipient(recipient) {
      if (!this.canRecipientBeSelected(recipient)) return;
      if (this.isRecipientSelected(recipient)) {
        this.selectedEmails = this.selectedEmails.filter(
          (email) => email !== recipient.email,
        );
      } else {
        this.selectedEmails.push(recipient.email);
      }
    },
    getRecipientStatusColor(recipient) {
      let color = "primary";
      if (recipient.status === this.$constant.ACTION_REQUEST_STATUS.IN_PROGRESS) {
        color = "warning";
      } else if (recipient.status === this.$constant.ACTION_REQUEST_STATUS.COMPLETED) {
        color = "success";
      } else if (recipient.status === this.$constant.ACTION_REQUEST_STATUS.DECLINED) {
        color = "error";
      }
      return `${color}--text`;
    },
    getRecipientStatusText(recipient) {
      return this.$t(`actionRequest.status.${recipient.status}`);
    },
    onSubmit() {
      if (this.selectedEmails.length === 0) return;
      const body = {
        emails: this.selectedEmails,
        comment: this.message,
      };
      this.isReminding = true;
      remindActionRequest({ id: this.actionRequestId, body })
        .then((data) => {
          this.$emit("submit");
          this.close();
        })
        .catch((err) => {
          throw new Error(err);
        })
        .finally(() => {
          this.isReminding = false;
        });
    },
    close() {
      this.localDialog = false;
    },
  },
};
</script>
