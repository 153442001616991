<script>
import ResponseItem from "./ResponseItem.vue";
import { formTemplateBuilderMixins } from "@/helpers/mixins";
import GroupItem from "./GroupItem.vue";
import ResponseItemHeader from "./ResponseItemHeader.vue";
import { VIcon } from "vuetify/lib";
import Draggable from "vuedraggable";
import { VBtn } from "vuetify/lib";
import { VCard } from "vuetify/lib";

export default {
  mixins: [formTemplateBuilderMixins],
  props: {
    template: Object,
    item: Object,
    items: Array,
    isCreatingItem: Boolean,
    sectionIndex: Number,
    isCms: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    onChange(e, parentId) {
      if (e.moved) {
        this.formTemplateBuilderMixins_moveItem({
          item: e.moved?.element,
          body: { index: e.moved?.newIndex, parentId },
        });
      }
      if (e.added) {
        this.formTemplateBuilderMixins_moveItem({
          item: e.added?.element,
          body: { index: e.added?.newIndex, parentId },
        });
      }
    },
    genActionButton(item) {
      return this.$createElement(
        VBtn,
        {
          class: `ui-background--background text-transform-none mt-4`,
          props: {
            text: true,
            color: "primary",
            disabled: this.isCreatingItem,
            outlined: true,
            small: true,
          },
          on: {
            click: (e) =>
              this.formTemplateBuilderMixins_createItem({
                body: {
                  parentId: item.id,
                  type: this.$constant.TEXTAREA,
                  name: "",
                },
              }),
          },
        },
        [
          this.$createElement(
            VIcon,
            { props: { small: true } },
            this.$icon.REGULAR.ACTION.ADD,
          ),
          this.$createElement(
            "div",
            { class: "pl-2" },
            this.$t("templateManagement.form.builder.item.addQuestion"),
          ),
        ],
      );
    },
    genGroup(item, groupIndex) {
      return this.$createElement(
        VCard,
        {
          class: `ui-background py-12`,
          attrs: {
            id: `form-template-item-${item.id}`,
          },
          props: {
            flat: true,
            disabled: item.isLoading && item.type === this.$constant.GROUP,
          },
        },
        [
          this.$createElement("div", { class: "d-flex w-100 justify-center" }, [
            this.$createElement(
              "div",
              {
                class: "d-flex align-center flex-column",
                style: "min-width: 32px",
              },
              [
                this.$createElement(
                  VIcon,
                  {
                    class: "draggable-handle cursor-grab",
                    style: "margin-top: 10px;",
                  },
                  this.$icon.SOLID.ACTION.DRAG,
                ),
              ],
            ),
            this.$createElement("div", { class: "w-100" }, [
              this.$createElement(GroupItem, {
                props: { item },
                on: {
                  "item:update": (e) => this.formTemplateBuilderMixins_updateItem(e),
                  "item:delete": (e) => this.formTemplateBuilderMixins_deleteItem(e),
                },
              }),

              this.$createElement(ResponseItemHeader),
              this.$createElement(
                Draggable,
                {
                  props: {
                    list: item.children,
                  },
                  attrs: {
                    handle: ".draggable-handle",
                    group: "a",
                    animation: this.$constant.DRAG_SETTINGS.ANIMATION_DURATION,
                    ghostClass: this.$constant.DRAG_SETTINGS.GHOST_CLASS_DASHED_OUTLINE,
                    dragClass: this.$constant.DRAG_SETTINGS.DRAG_CLASS,
                    emptyInsertThreshold: 150,
                    invertedSwapThreshold: 0.4,
                    forceFallback: true,
                  },
                  class: "draggable-container-gap-8",
                  on: {
                    change: (e) => this.onChange(e, item.id),
                  },
                },
                this.genHierarchy(item.children, groupIndex),
              ),
              this.genActionButton(item),
            ]),
          ]),
        ],
      );
    },
    genHierarchy(items, parentGroupIndex) {
      const hierarchy = [];
      let responseItemIndex = 0;
      for (let [index, item] of items.entries()) {
        if (item.type === this.$constant.GROUP) {
          hierarchy.push(this.genGroup(item, responseItemIndex));
          responseItemIndex++;
        } else {
          hierarchy.push(
            this.$createElement(
              "div",
              {
                key: item.id,
                class: "ui-background--background",
              },
              [
                this.$createElement(ResponseItem, {
                  class: `w-100`,
                  props: {
                    template: this.template,
                    item,
                    responseItemIndex,
                    sectionIndex: this.sectionIndex,
                    parentGroupIndex,
                    isCms: this.isCms,
                  },
                  on: {
                    "item:update": (e) => this.formTemplateBuilderMixins_updateItem(e),
                    "item:delete": (e) => this.formTemplateBuilderMixins_deleteItem(e),
                    "defaultAnswerTemplateMenu:open": (e) =>
                      this.formTemplateBuilderMixins_openDefaultAnswerTemplateMenu(e),
                  },
                }),
              ],
            ),
          );
          responseItemIndex++;
        }
      }

      return hierarchy;
    },
  },
  render(h) {
    const items = this.formTemplateBuilderMixins_getHierarchyByParentId(
      this.items,
      this.item.id,
    );
    return h("div", [
      h(ResponseItemHeader),
      h(
        Draggable,
        {
          props: {
            list: this.formTemplateBuilderMixins_getChildrenByParentId(
              this.items,
              this.item.id,
            ),
          },
          attrs: {
            handle: ".draggable-handle",
            group: "a",
            animation: this.$constant.DRAG_SETTINGS.ANIMATION_DURATION,
            dragClass: this.$constant.DRAG_SETTINGS.DRAG_CLASS,
            ghostClass: this.$constant.DRAG_SETTINGS.GHOST_CLASS_DASHED_OUTLINE,
            emptyInsertThreshold: 100,
            invertedSwapThreshold: 0.4,
            forceFallback: true,
          },
          on: {
            change: (e) => this.onChange(e, this.item.id),
          },
          class: "text-border-reset draggable-container-gap-8",
        },

        this.genHierarchy(items),
      ),
    ]);
  },
};
</script>
