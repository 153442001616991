<template>
  <AppMenuItem
    :text="text"
    :icon="$icon.REGULAR.COMMON.ENVELOPE"
    @click="click"
    :disabled="disabled"
  />
</template>

<script>
export default {
  props: {
    user: Object,
    disabled: Boolean,
  },
  computed: {
    text() {
      return this.user?.status === this.$constant.INVITED
        ? this.$t("users.action.reInvite")
        : this.$t("users.action.invite");
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>
