import { ROUTE_NAMES } from "@/helpers/routeNames";
import { authGuard, userGuard } from "../../auth/authGuard";
import multiguard from "../multiguard";
import { makeBeforeEnter, redirectFromLocalStorage } from "../redirect";

const {
  ROOT,
  LIST,
  LIST_MENU,
  LIST_MENU_DETAILS,
  LIST_MENU_HISTORY,
  LIST_MENU_TO_DO_LIST,
  LIST_MENU_STORAGE,
  LIST_MENU_FORMS,
  LIST_MENU_PRODUCTS_USAGE,
  LIST_MENU_COMMENTS,
  CALENDAR,
  CALENDAR_MENU,
  CALENDAR_MENU_DETAILS,
  CALENDAR_MENU_HISTORY,
  CALENDAR_MENU_TO_DO_LIST,
  CALENDAR_MENU_STORAGE,
  CALENDAR_MENU_FORMS,
  CALENDAR_MENU_PRODUCTS_USAGE,
  CALENDAR_MENU_COMMENTS,
} = ROUTE_NAMES.TASK;

const taskLocalStorageKey = "REDIRECT_ROUTE:task";
const beforeEnterTask = makeBeforeEnter({ localStorageKey: taskLocalStorageKey });

export const taskRoutes = [
  {
    path: "/task",
    name: ROOT,
    beforeEnter: multiguard([authGuard, userGuard()]),
    component: () =>
      import(/* webpackChunkName: "task" */ "../../pages/TaskManagement/Tasks"),
    redirect: () =>
      redirectFromLocalStorage({
        localStorageKey: taskLocalStorageKey,
        validRoutes: [LIST, CALENDAR],
        defaultRoute: LIST,
      }),
    children: [
      {
        path: "list",
        name: LIST,
        beforeEnter: beforeEnterTask,
        component: () =>
          import(
            /* webpackChunkName: "task" */ "../../pages/TaskManagement/Tasks/TaskList"
          ),
        children: [
          {
            path: "menu/:taskId",
            name: LIST_MENU,
            component: () =>
              import(
                /* webpackChunkName: "task" */ "../../pages/TaskManagement/Tasks/TaskList/MenuPage"
              ),
            children: [
              {
                path: "details",
                name: LIST_MENU_DETAILS,
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/DetailsPage"
                  ),
              },
              {
                path: "history",
                name: LIST_MENU_HISTORY,
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/HistoryPage"
                  ),
              },
              {
                path: "to-do-list",
                name: LIST_MENU_TO_DO_LIST,
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/ToDoList"
                  ),
              },
              {
                path: "storage/:folderId",
                name: LIST_MENU_STORAGE,
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/StoragePage"
                  ),
              },
              {
                path: "forms",
                name: LIST_MENU_FORMS,
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/FormPage"
                  ),
              },
              {
                path: "product-usage",
                name: LIST_MENU_PRODUCTS_USAGE,
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/ProductUsagePage"
                  ),
              },
              {
                path: "comments",
                name: LIST_MENU_COMMENTS,
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/CommentsPage"
                  ),
              },
            ],
          },
        ],
      },
      {
        path: "calendar",
        name: CALENDAR,
        beforeEnter: beforeEnterTask,
        component: () =>
          import(
            /* webpackChunkName: "task" */ "../../pages/TaskManagement/Tasks/TaskCalendar"
          ),
        children: [
          {
            path: "menu/:taskId",
            name: CALENDAR_MENU,
            component: () =>
              import(
                /* webpackChunkName: "task" */ "../../pages/TaskManagement/Tasks/TaskCalendar/MenuPage"
              ),
            children: [
              {
                path: "details",
                name: CALENDAR_MENU_DETAILS,
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/DetailsPage"
                  ),
              },
              {
                path: "history",
                name: CALENDAR_MENU_HISTORY,
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/HistoryPage"
                  ),
              },
              {
                path: "to-do-list",
                name: CALENDAR_MENU_TO_DO_LIST,
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/ToDoList"
                  ),
              },
              {
                path: "storage/:folderId",
                name: CALENDAR_MENU_STORAGE,
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/StoragePage"
                  ),
              },
              {
                path: "forms",
                name: CALENDAR_MENU_FORMS,
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/FormPage"
                  ),
              },
              {
                path: "product-usage",
                name: CALENDAR_MENU_PRODUCTS_USAGE,
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/ProductUsagePage"
                  ),
              },
              {
                path: "comments",
                name: CALENDAR_MENU_COMMENTS,
                component: () =>
                  import(
                    /* webpackChunkName: "task" */ "../../pages/TaskManagement/MenuPages/CommentsPage"
                  ),
              },
            ],
          },
        ],
      },
    ],
  },
];
