<template>
  <div v-if="action">
    <AppMenuItem
      :disabled="disabled"
      :text="text"
      :icon="icon"
      :showStar="!planRestrictionsMixins_canUseOfficeOnline"
      :planCode="$constant.PLAN_CODE.GRIPR_PRO"
      @click="onWopiOpenActionPress"
    >
    </AppMenuItem>
  </div>
</template>

<script>
import { planRestrictionsMixins } from "@/helpers/mixins";
import { mapGetters } from "vuex";

export default {
  mixins: [planRestrictionsMixins],
  props: {
    item: Object,
    disabled: Boolean,
  },
  computed: {
    ...mapGetters("wopiDiscovery", {
      getWopiApplicationOpenAction: "getApplicationOpenAction",
    }),
    action() {
      if (this.item?.type !== this.$constant.FILE) return null;
      if (!this.item?.extension) return null;
      const extension = this.item.extension.split(".").pop();
      const action = this.getWopiApplicationOpenAction(extension);
      return action;
    },
    icon() {
      if (!this.action) return "";
      const extension = this.item.extension.split(".").pop();
      if (this.$constant.OFFICE_WORD_EXTENSIONS.includes(extension)) return "$word";
      if (this.$constant.OFFICE_EXCEL_EXTENSIONS.includes(extension)) return "$excel";
      if (this.$constant.OFFICE_POWERPOINT_EXTENSIONS.includes(extension))
        return "$powerpoint";
      return this.$icon.REGULAR.COMMON.EYE;
    },
    text() {
      if (!this.action) return "";
      const extension = this.item.extension.split(".").pop();
      if (this.$constant.OFFICE_WORD_EXTENSIONS.includes(extension))
        return this.$t("fileManagement.fileExplorer.menu.openInWord");
      if (this.$constant.OFFICE_EXCEL_EXTENSIONS.includes(extension))
        return this.$t("fileManagement.fileExplorer.menu.openInExcel");
      if (this.$constant.OFFICE_POWERPOINT_EXTENSIONS.includes(extension))
        return this.$t("fileManagement.fileExplorer.menu.openInPowerPoint");
      return this.$t("fileManagement.fileExplorer.menu.openInOffice");
    },
  },
  methods: {
    onWopiOpenActionPress() {
      if (!this.planRestrictionsMixins_canUseOfficeOnline) {
        setTimeout(() => {
          this.openUpgradePlanDialog();
        }, 100);
        return;
      }
      const fileId = this.item.id;
      const action = this.action;
      const route = this.$router.resolve({
        name: this.$routeNames.OFFICE_ONLINE.ROOT,
        params: { fileId },
        query: { actionUrl: action.url, faviconUrl: action.faviconUrl },
      });

      window.open(route.href, "_blank");
      this.$emit("openInOffice");
    },
    openUpgradePlanDialog() {
      this.$store.dispatch("upgradePlanDialog/setUpgradePlanDialog", {
        active: true,
        planCode: this.$constant.PLAN_CODE.GRIPR_PRO,
      });
    },
  },
};
</script>
