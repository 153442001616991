import Vue from "vue";
import VueRouter from "vue-router";
import {
  projectRoutes,
  timeRegistrationRoutes,
  authRoutes,
  settingsRoutes,
  getStartedRoutes,
  officeOnlineRoutes,
  cmsRoutes,
  externalRoutes,
  formViewRoutes,
  taskViewRoutes,
  templateRoutes,
  taskRoutes,
  formRoutes,
  storageRoutes,
  contactRoutes,
  employeeRoutes,
  dashboardRoutes,
} from "./routes";
import multiguard from "./multiguard";
import { authGuard, rootRouteRedirectGuard, userGuard } from "@/auth";
import store from "@/store";
import { referralRoutes } from "./routes/referral";
import { setupDocumentTitleWatcher } from "./documentTitleWatcher";
import { setupQueryParamPreservation } from "./preserveQueryParams";
import {
  SERVER_SIDE_PAGINATION_TABLE__PARENT_PAGE_NAMES,
  CALENDAR__PARENT_PAGE_NAMES,
} from "@/helpers/routeNames";
import _ from "lodash";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    name: "notFound",
    component: () =>
      import(/* webpackChunkName: "notFound" */ "../pages/common/ErrorPage"),
  },
  {
    path: "/error",
    name: "error",
    component: () => import(/* webpackChunkName: "error" */ "../pages/common/ErrorPage"),
  },
  {
    path: "/forbidden",
    name: "forbidden",
    component: () =>
      import(/* webpackChunkName: "forbidden" */ "../pages/common/ForbiddenPage"),
  },
  {
    path: "/",
    name: "root",
    beforeEnter: multiguard([authGuard, userGuard(), rootRouteRedirectGuard]),
  },

  // routes to keep
  ...dashboardRoutes,
  ...authRoutes,
  ...referralRoutes,
  ...taskRoutes,
  ...timeRegistrationRoutes,
  ...formRoutes,
  ...storageRoutes,
  ...contactRoutes,
  ...taskViewRoutes,
  ...templateRoutes,
  ...employeeRoutes,
  ...formViewRoutes,
  ...externalRoutes,
  ...projectRoutes,
  ...settingsRoutes,
  ...getStartedRoutes,
  ...officeOnlineRoutes,
  ...cmsRoutes,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

setupQueryParamPreservation(router, [
  //preserve current page query for AppDataTableServerSidePagination pages
  {
    preserveParams: ["page"],
    parentRouteNames: SERVER_SIDE_PAGINATION_TABLE__PARENT_PAGE_NAMES,
  },
  //preserve start and end date queries for calendar routes
  {
    preserveParams: ["start", "end"],
    parentRouteNames: CALENDAR__PARENT_PAGE_NAMES,
  },
]);

setupDocumentTitleWatcher(router);

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  store.commit("route/addVisitedRoute", location.name);
  return routerPush.call(this, location).catch((error) => error);
};

export default router;
