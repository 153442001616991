<template>
  <div>
    <DropArea
      ref="dropArea"
      :isDraggingOver="isDraggingOver"
      :hasFiles="hasFiles"
      :loading="loading"
      @addFile="handleAddFile"
      @dragOver="onDragOver"
      @dragLeave="onDragLeave"
      @fileDrop="onFileDrop"
    >
      <div class="file-picker-area">
        <FileList
          v-if="hasFiles"
          :files="files"
          @removeFile="removeFile"
          :isDraggingOver="isDraggingOver"
        />
        <EmptyState v-else :loading="loading" />
      </div>
    </DropArea>
    <AddFileBtn @click="handleAddFile" />
    <FileInput
      ref="uploader"
      :acceptedTypes="acceptedTypes"
      @fileSelect="afterFileSelect"
    />
  </div>
</template>

<script>
import { storageMixins } from "@/helpers/mixins";
import DropArea from "./DropArea.vue";
import FileList from "./FileList.vue";
import EmptyState from "./EmptyState.vue";
import AddFileBtn from "./AddFileBtn.vue";
import FileInput from "./FileInput.vue";

export default {
  components: {
    DropArea,
    FileList,
    EmptyState,
    AddFileBtn,
    FileInput,
  },
  mixins: [storageMixins],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    acceptedTypes: {
      type: String,
      default: "",
    },
    loading: Boolean,
  },
  data() {
    return {
      isDraggingOver: false,
    };
  },
  computed: {
    files: {
      get() {
        return this.value;
      },
      set(newFiles) {
        this.$emit("input", newFiles);
        this.$emit("change", newFiles);
      },
    },
    hasFiles() {
      return this.files.length > 0;
    },
  },
  methods: {
    onDragOver(event) {
      if (!event.dataTransfer?.types?.includes("Files")) return;
      this.isDraggingOver = true;
    },
    onDragLeave() {
      this.isDraggingOver = false;
    },
    onFileDrop(event) {
      this.isDraggingOver = false;
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        const acceptedFiles = [];
        const rejectedFileTypes = [];

        Array.from(files).forEach((file) => {
          if (this.isFileTypeAccepted(file)) {
            acceptedFiles.push(file);
          } else {
            rejectedFileTypes.push(file.type || "unknown");
          }
        });

        if (acceptedFiles.length > 0) {
          this.addFiles(acceptedFiles);
        }

        if (rejectedFileTypes.length > 0) {
          this.showRejectedFilesError(rejectedFileTypes);
        }
      }
    },
    isFileTypeAccepted(file) {
      if (!this.acceptedTypes) return true;
      const fileType = file.type || "";
      const fileExtension = "." + file.name.split(".").pop().toLowerCase();
      return this.acceptedTypes
        .split(",")
        .some((type) => type.trim() === fileType || type.trim() === fileExtension);
    },
    showRejectedFilesError(rejectedTypes) {
      const uniqueRejectedTypes = [...new Set(rejectedTypes)];
      const errorMessage = this.$tc(
        "fileManagement.fileExplorer.uploadRejectionTypes",
        uniqueRejectedTypes.length,
        {
          types: uniqueRejectedTypes
            .map(this.storageMixins_getReadableFileType)
            .join(", "),
        },
      );
      this.$store.dispatch("snackbar/snackbar", {
        show: true,
        text: errorMessage,
        color: "error",
      });
    },
    removeFile(file) {
      this.$refs.dropArea.disableClickTemporarily();
      const index = this.files.indexOf(file);
      if (index > -1) {
        const newFiles = [...this.files];
        newFiles.splice(index, 1);
        this.files = newFiles;
      }
    },
    handleAddFile() {
      this.$refs.uploader.$el.click();
    },
    afterFileSelect(fileList) {
      this.addFiles(Array.from(fileList));
    },
    addFiles(newFiles) {
      this.files = [...this.files, ...newFiles];
    },
  },
};
</script>

<style scoped lang="scss">
.file-picker-area {
  padding: 8px;
}

.drop-area--empty .file-picker-area {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
}
</style>
