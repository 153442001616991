<template>
  <div>
    <AppContextMenu ref="contextMenu">
      <template v-if="showOpenWith">
        <AppContextMoreMenu
          right
          offset-x
          :text="$t('fileManagement.fileExplorer.menu.openWith')"
          :icon="$icon.REGULAR.ACTION.OPEN_WITH"
        >
          <AppMenuItem
            :text="$t('common.preview')"
            :icon="$icon.REGULAR.COMMON.EYE"
            @click="onNestedActionClick($constant.STORAGE_CONTEXT_MENU_ACTIONS.PREVIEW)"
          />

          <div class="open-with-more">
            <AppMenuItemOpenInOffice :item="selectedStorages[0]" />
          </div>
        </AppContextMoreMenu>

        <v-divider class="my-2" />
      </template>

      <AppMenuItem
        :disabled="!canRename"
        :text="$t('fileManagement.fileExplorer.menu.rename')"
        :icon="$icon.REGULAR.ACTION.EDIT"
        @click="onNestedActionClick($constant.STORAGE_CONTEXT_MENU_ACTIONS.RENAME)"
      />
      <AppMenuItem
        :disabled="!canDownload"
        :text="$t('common.download')"
        :icon="$icon.REGULAR.ACTION.DOWNLOAD"
        @click="onActionClick($constant.STORAGE_CONTEXT_MENU_ACTIONS.DOWNLOAD)"
      />
      <v-divider class="my-2" />
      <AppMenuItem
        :disabled="!canMove"
        :text="$t('fileManagement.fileExplorer.menu.move')"
        :icon="$icon.REGULAR.FILE.FILE_EXPORT"
        @click="onActionClick($constant.STORAGE_CONTEXT_MENU_ACTIONS.MOVE)"
      />
      <AppMenuCopyBtn
        :disabled="!canCopy"
        @click="onActionClick($constant.STORAGE_CONTEXT_MENU_ACTIONS.COPY)"
      />
      <AppMenuItem
        :disabled="!canLink"
        :text="$t('fileManagement.linkedFile.title')"
        :icon="$icon.REGULAR.FILE.FILE_LINKED"
        @click="onActionClick($constant.STORAGE_CONTEXT_MENU_ACTIONS.LINK)"
      />
      <AppMenuItem
        :disabled="!canMerge"
        :text="$t('fileManagement.fileExplorer.menu.addToMerge')"
        :showStar="!planRestrictionsMixins_canMergeFiles"
        :planCode="$constant.PLAN_CODE.GRIPR_PRO"
        :icon="$icon.REGULAR.COMMON.MERGE"
        @click="onActionClick($constant.STORAGE_CONTEXT_MENU_ACTIONS.ADD_TO_MERGE)"
      />
      <AppMenuItem
        :disabled="!canCreateSignableDocument"
        :text="$t('fileManagement.fileExplorer.menu.signableDocument')"
        :icon="$icon.REGULAR.FILE.FILE_SIGNATURE"
        @click="
          onActionClick($constant.STORAGE_CONTEXT_MENU_ACTIONS.CREATE_SIGNABLE_DOCUMENT)
        "
      />
      <AppMenuItem
        :disabled="!canRequestReadConfirmation"
        :text="$t('fileManagement.fileExplorer.menu.requestReadConfirmation')"
        :icon="$icon.REGULAR.COMMON.BOOK_OPEN_READER"
        @click="
          onActionClick($constant.STORAGE_CONTEXT_MENU_ACTIONS.REQUEST_READ_CONFIRMATION)
        "
      />
      <AppMenuCopyUrlBtn :disabled="isMultipleSelected" :route="copyUrlRoute" />
      <AppMenuItem
        v-if="showBoligmappa"
        :disabled="!canUploadToBoligmappa"
        :text="$t('fileManagement.fileExplorer.menu.uploadToBoligmappa')"
        icon="$boligmappa"
        @click="
          onActionClick($constant.STORAGE_CONTEXT_MENU_ACTIONS.UPLOAD_TO_BOLIGMAPPA)
        "
      />
      <v-divider class="my-2" />
      <AppMenuDeleteBtn
        :disabled="!canDelete"
        @click="onActionClick($constant.STORAGE_CONTEXT_MENU_ACTIONS.DELETE)"
      />
    </AppContextMenu>
  </div>
</template>

<script>
import { planRestrictionsMixins } from "@/helpers/mixins";
import { mapGetters, mapState } from "vuex";

export default {
  mixins: [planRestrictionsMixins],
  props: {
    selectedStorages: Array,
    showBoligmappa: Boolean,
    folderRouterName: String,
    rootId: String,
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    ...mapGetters("activeIntegrations", {
      isIntegrationEnabled: "isIntegrationEnabled",
    }),
    ...mapGetters("auth", {
      domainId: "activeDomainId",
    }),
    ...mapState("project", {
      project: (state) => state.project,
    }),
    ...mapState("storage", {
      isDownloading: (state) => state.isDownloading,
      isDeleting: (state) => state.isDeleting,
    }),
    copyUrlRoute() {
      const storage = this.selectedStorages[0];
      const query = this.isFolderSelected ? {} : { fileId: storage?.id };
      const folderId = this.isFolderSelected ? storage.id : storage?.parentId;
      // const name = this.folderRouterName;
      // const rootId = this.rootId;

      //dont send user into project until this issue is fixed
      const rootId = this.domainId;
      const name = "storageAllFiles";

      const route = {
        name: name,
        params: { rootId: rootId, folderId },
        query,
      };
      //Oppgave eller skjema inne på prosjekt, hvis man linker til de på prosjektet så janker den
      //parent idn til oppgaver og skjemaer på et prosjekt er jank
      return route;
    },
    isFolderSelected() {
      return this.selectedStorages.some((s) => s.type === this.$constant.FOLDER);
    },
    isLockedSelected() {
      return this.selectedStorages.some((s) => s.isLocked);
    },
    isLinkedFileSelected() {
      return this.selectedStorages.some((s) => s.type === this.$constant.FILE_RELATION);
    },
    isSomeSelected() {
      return this.selectedStorages.length > 0;
    },
    isMultipleSelected() {
      return this.selectedStorages.length > 1;
    },
    isMultiplePermissionLevelsSelected() {
      const permissionLevels = this.selectedStorages.map((s) => s.permissionLevel);
      return !permissionLevels.every((level) => level === permissionLevels[0]);
    },
    isAllFilesAllowedToMerge() {
      return this.selectedStorages.every((file) => {
        const isAllowedFileType = this.$constant.TYPES_ALLOWED_TO_MERGE.includes(
          file?.type,
        );
        const isAllowedMimeType = this.$constant.MINE_TYPE_ALLOWED_TO_MERGE.includes(
          file?.mime,
        );
        return isAllowedFileType && isAllowedMimeType;
      });
    },
    showOpenWith() {
      return !this.isFolderSelected && !this.isMultipleSelected;
    },
    isLinkedFile() {
      return this.selectedStorages.some((s) => s?.type === this.$constant.FILE_RELATION);
    },
    canRename() {
      return !this.isMultipleSelected && !this.isLinkedFile;
    },
    canDownload() {
      return !this.isDownloading;
    },
    canMove() {
      return !this.isMultiplePermissionLevelsSelected && !this.isLockedSelected;
    },
    canCopy() {
      return !this.isFolderSelected;
    },
    canLink() {
      return (
        !this.isFolderSelected && !this.isLinkedFileSelected && !this.isLockedSelected
      );
    },
    canMerge() {
      return !this.isFolderSelected && this.isAllFilesAllowedToMerge;
    },
    canCreateSignableDocument() {
      if (this.isFolderSelected || this.isMultipleSelected) return false;
      const file = this.selectedStorages[0];
      if (!file) return false;
      const isAllowedFileType = this.$constant.TYPES_ALLOWED_TO_SIGN.some(
        (i) => i === file?.type,
      );
      const isAllowedExtension = this.$constant.EXTENSIONS_ALLOWED_TO_SIGN.some(
        (i) => i === file?.extension,
      );
      const meta = file.meta || [];
      const isESignFile = meta.some((m) => m.field === this.$constant.E_SIGN_META_FIELD);
      const allAllowedTypes = [isAllowedFileType, isAllowedExtension];
      const canCreate = allAllowedTypes.every((e) => e === true) && !isESignFile;
      return canCreate;
    },
    canRequestReadConfirmation() {
      return !this.isFolderSelected && !this.isMultipleSelected;
    },
    canUploadToBoligmappa() {
      return !this.isFolderSelected;
    },
    canDelete() {
      return !this.isLockedSelected && this.isSomeSelected && !this.isDeleting;
    },
  },
  methods: {
    open({ pointerEvent }) {
      this.$refs.contextMenu.open({ pointerEvent });
    },
    onNestedActionClick(action) {
      this.$refs.contextMenu.close();
      this.onActionClick(action);
    },
    onActionClick(action, context = {}) {
      if (
        action === this.$constant.STORAGE_CONTEXT_MENU_ACTIONS.ADD_TO_MERGE &&
        !this.planRestrictionsMixins_canMergeFiles
      ) {
        this.openUpgradePlanDialog();
        return;
      }

      this.$emit("action:click", { action, storages: this.selectedStorages, context });
    },
    openUpgradePlanDialog() {
      this.$store.dispatch("upgradePlanDialog/setUpgradePlanDialog", {
        active: true,
        planCode: this.$constant.PLAN_CODE.GRIPR_PRO,
      });
    },
    escapeListener(event) {
      if (event.key === "Delete") {
        if (!this.canDelete) return;
        this.onActionClick(this.$constant.STORAGE_CONTEXT_MENU_ACTIONS.DELETE);
      }
    },
  },
  created() {
    addEventListener("keydown", this.escapeListener);
  },
  destroyed() {
    removeEventListener("keydown", this.escapeListener);
  },
};
</script>
