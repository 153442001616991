<template>
  <div class="file_storage_node" v-ripple="itemClick">
    <v-hover v-slot="{ hover }">
      <div
        class="file_storage_node__root"
        :class="[
          { 'file_storage_node__root--active': isActive },
          { 'cursor-pointer': itemClick },
        ]"
        @click="onClick(item)"
      >
        <div class="file_storage_node__prepend">
          <slot name="prepend" :item="item" :state="{ isSelected, hover }"> </slot>
        </div>
        <div class="file_storage_node__label">
          <slot name="label" :item="item" :state="{ isSelected, hover }">
            <span class="hyphenate">
              {{ item.name }}
            </span>
          </slot>
        </div>
        <div class="file_storage_node__append">
          <slot name="append" :item="item" :state="{ isSelected, hover }"> </slot>
        </div>
      </div>
    </v-hover>
  </div>
</template>

<script>
export default {
  name: "AppStorageItem",
  props: {
    item: Object,
    active: String,
    itemClick: Boolean,
  },
  data() {
    return {
      isActive: false,
    };
  },
  computed: {
    isSelected: {
      get() {
        return this.item.isSelected;
      },
    },
  },
  watch: {
    active: {
      immediate: true,
      handler(newVal) {
        this.isActive = newVal === this.item.id;
      },
    },
  },
  methods: {
    onClick(item) {
      if (this.itemClick) {
        this.$emit("item:click", item);
      }
    },
  },
};
</script>
