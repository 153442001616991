<script>
import {
  VMenu,
  VBtn,
  VList,
  VListItem,
  VListItemAvatar,
  VListItemContent,
  VIcon,
  VForm,
  VDivider,
  VSubheader,
  VSpacer,
} from "vuetify/lib";
import { formTemplateBuilderMixins } from "@/helpers/mixins";
import ResponseItemChoices from "./ResponseItemChoices.vue";
import { VCheckbox } from "vuetify/lib";
import { VCard } from "vuetify/lib";
import _ from "lodash";

export default {
  mixins: [formTemplateBuilderMixins],
  props: {
    template: Object,
    item: Object,
    responseItemIndex: Number,
    sectionIndex: Number,
    parentGroupIndex: Number,
    isCms: Boolean,
  },
  data() {
    return {
      name: {
        isValid: false,
      },
    };
  },
  computed: {
    showExplanation() {
      return this.item.hasExplanation;
    },
    showDefaultAnswer() {
      return this.item.hasDefaultValue && !this.isCms;
    },
    isResponseTypeTextArea() {
      return this.item?.type === this.$constant.TEXTAREA;
    },
  },
  methods: {
    onBlur(e, field) {
      const value = typeof e === "object" && e !== null ? e?.target?.value : e;
      if (typeof value !== "undefined" && value !== this.item[field]) {
        const isFormField = this[field] ? true : false;
        if (isFormField && !this[field].isValid) return;
        this.formTemplateBuilderMixins_updateItem({
          item: this.item,
          body: { [field]: value },
        });
      }
    },
    deleteItem() {
      this.$emit("item:delete", { item: this.item });
    },
    genNameInput() {
      return this.$createElement(
        VForm,
        {
          class: `d-flex`,
          props: { value: this.name.isValid },
          on: {
            submit: (e) => e.preventDefault(),
            input: (e) => (this.name.isValid = e),
          },
        },
        [
          this.$createElement("AppDefaultTextField", {
            props: {
              id: `formItemTextField_${this.item.id}`,
              label: this.$t("templateManagement.form.builder.item.questionTitle"),
              hideDetails: true,
              value: this.item?.name,
              rules: this.$rules.NAME_RULES,
              backgroundColor: "ui-background",
            },
            on: { blur: (e) => this.onBlur(e, "name") },
          }),
        ],
      );
    },
    isResponseTypeActive(type) {
      const typeValues = type?.values ? JSON.stringify(type?.values) : "";
      const itemValues = this.item?.values ? JSON.stringify(this.item?.values) : "";
      const isRadioGroup = type.key === this.$constant.RADIO_GROUP;
      const isTypesEqual = type.key === this.item?.type;
      const isValuesEqual = typeValues === itemValues;

      const isActive = (isTypesEqual && !isRadioGroup) || (isTypesEqual && isValuesEqual);
      return isActive;
    },
    genResponseTypes() {
      const responseTypes = [];
      for (let [index, type] of this.formTemplateBuilderMixins_responseTypes.entries()) {
        const isLast = index === this.formTemplateBuilderMixins_responseTypes.length - 1;
        const text = this.$te(`templateManagement.form.builder.item.type.${type.key}`)
          ? this.$t(`templateManagement.form.builder.item.type.${type.key}`)
          : type.key;
        const isActive = this.isResponseTypeActive(type);
        responseTypes.push(
          this.$createElement(
            VListItem,
            {
              class: `${isLast ? "mb-2" : ""} ${isActive ? "v-list-item--active" : ""}`,
              on: {
                click: (e) =>
                  isActive
                    ? ""
                    : this.formTemplateBuilderMixins_updateItem({
                        item: this.item,
                        body: { type: type.key, values: type?.values },
                      }),
              },
            },
            [
              this.$createElement(
                VListItemAvatar,
                { class: "d-flex justify-center align-center", props: { size: 24 } },
                [this.$createElement(VIcon, { props: { small: true } }, type.icon)],
              ),
              this.$createElement(
                VListItemContent,
                `${text} ${type?.showValues ? `(${type?.values})` : ""}`,
              ),
            ],
          ),
        );
      }
      return responseTypes;
    },
    genResponseSelector() {
      const text = this.$te(
        `templateManagement.form.builder.item.type.${this.item?.type}`,
      )
        ? this.$t(`templateManagement.form.builder.item.type.${this.item?.type}`)
        : this.item?.type ||
          this.$t("templateManagement.form.builder.item.selectResponseType");

      const showValues = this.item.type === this.$constant.RADIO_GROUP;
      return this.$createElement(VMenu, {
        props: {
          offsetY: true,
        },
        scopedSlots: {
          activator: (props) =>
            this.$createElement(
              "div",
              { ...props, class: "w-100 h-100 px-3 d-flex align-center" },
              [
                this.$createElement(
                  "div",
                  `${text} ${showValues ? `(${this.item?.values})` : ""}`,
                ),
                this.$createElement(VSpacer),
                this.$createElement(VIcon, this.$icon.SOLID.ACTION.DROPDOWN),
              ],
            ),
          default: () =>
            this.$createElement(
              VList,
              { props: { subheader: true }, style: "min-width: 320px;" },
              [
                this.$createElement(
                  VSubheader,
                  this.$t("templateManagement.form.builder.item.selectResponseType"),
                ),
                this.genResponseTypes(),
                this.$createElement(VDivider),
                this.$createElement(
                  VListItem,
                  {
                    class: "mt-2",
                    on: { click: (e) => this.deleteItem({ item: this.item }) },
                  },
                  [
                    this.$createElement(VListItemAvatar, { props: { size: 24 } }, [
                      this.$createElement(
                        VIcon,
                        { props: { small: true, color: "error" } },
                        this.$icon.REGULAR.ACTION.DELETE,
                      ),
                    ]),
                    this.$createElement(
                      VListItemContent,
                      { class: "error--text" },
                      this.$t("common.delete"),
                    ),
                  ],
                ),
              ],
            ),
        },
      });
    },
    genExplanation() {
      return this.$createElement("div", { class: "w-100 border-b" }, [
        this.genExplanationInput(),
      ]);
    },
    genExplanationInput() {
      return this.$createElement("div", { class: "d-flex" }, [
        this.$createElement("div", { style: "min-width: 32px" }),
        this.$createElement("div", { class: "w-100 py-2 pr-2 " }, [
          this.$createElement(
            "div",
            {
              class: "text-caption font-weight-medium d-flex align-center pb-2",
              style: "height: 40px",
            },
            [
              this.$createElement("div", { class: "subtitle--text" }, [
                this.$t("templateManagement.form.builder.item.helpText"),
              ]),
            ],
          ),
          this.$createElement("AppDefaultTextField", {
            props: {
              id: `formItemExplanationField_${this.item.id}`,
              label: this.$t("templateManagement.form.builder.item.helpTextPlaceholder"),
              hideDetails: true,
              value: this.item?.explanation,
              backgroundColor: "ui-background",
            },
            on: { blur: (e) => this.onBlur(e, "explanation") },
          }),
        ]),
      ]);
    },
    genAnswerBadge() {
      return this.$createElement("AppRectangleBadge", {
        props: {
          backgroundColor: this.item?.defaultValueTemplate?.labelColor,
          text: this.item?.defaultValueTemplate?.labelName,
        },
      });
    },
    genRemoveDefaultAnswerTemplateBtn() {
      return this.$createElement(
        VBtn,
        {
          props: { text: true, xSmall: true },
          on: {
            click: (e) => {
              this.formTemplateBuilderMixins_updateItem({
                item: this.item,
                body: {
                  defaultValueTemplateId: null,
                  value: null,
                },
                extra: { defaultValueTemplate: null },
              });
              this.$nextTick(() => {
                this.focusAnswerInput();
              });
            },
          },
          class: "error--text error-super-light--background ml-2",
          style: "height: 32px; width: 32px; min-width: 32px",
        },
        [
          this.$createElement(
            VIcon,
            {
              props: { color: "error", small: true },
            },
            [this.$icon.REGULAR.ACTION.DELETE],
          ),
        ],
      );
    },
    genAnswer() {
      const hideBadge = this.item.hideDefaultValueBadge;
      const hasDefaultValueId = !!this.item.defaultValueTemplateId;

      const showBadge = !hideBadge && hasDefaultValueId;

      return this.$createElement("div", { class: "d-flex border-b" }, [
        this.$createElement("div", { style: "min-width: 32px" }),
        this.$createElement("div", { class: "w-100 py-2 pr-2" }, [
          this.$createElement(
            "div",
            {
              class: "text-caption font-weight-medium d-flex align-center pb-2",
              style: "height: 40px",
            },
            [
              this.$createElement("div", { class: "subtitle--text" }, [
                this.$t("defaultAnswer.titleSingular"),
              ]),
              this.$createElement(VSpacer),
              this.$createElement("div", { class: "d-flex" }, [
                showBadge ? this.genAnswerBadge() : null,
                this.item.defaultValueTemplateId
                  ? this.genRemoveDefaultAnswerTemplateBtn()
                  : null,
              ]),
            ],
          ),
          this.genAnswerInput(),
          this.$createElement("div", { class: "w-100 d-flex pt-2" }, [
            this.$createElement(VSpacer),
            this.genAnswerBtn(),
          ]),
        ]),
      ]);
    },
    genAnswerInput() {
      const hasDefaultValueTemplate = !!this.item.defaultValueTemplate?.id;

      const inputValue = hasDefaultValueTemplate
        ? this.item.defaultValueTemplate.value
        : this.item?.value;
      return this.$createElement("div", { class: "d-flex" }, [
        this.$createElement("AppDefaultTextarea", {
          ref: "defaultAnswerInput",
          props: {
            hideDetails: true,
            value: inputValue,
            backgroundColor: "ui-background",
            rows: 1,
            label: hasDefaultValueTemplate
              ? ""
              : this.$t("templateManagement.form.builder.item.answerPlaceholder"),
            readonly: hasDefaultValueTemplate,
          },
          on: {
            blur: (e) => {
              if (hasDefaultValueTemplate) return;
              this.onBlur(e, "value");
              this.isNewDefaultAnswer = false;
            },
          },
        }),
      ]);
    },
    focusAnswerInput() {
      this.isNewDefaultAnswer = true;
      this.$nextTick(() => {
        this.$refs.defaultAnswerInput.focus();
      });
    },
    genValues() {
      switch (this.item?.type) {
        // case this.$constant.RADIO_GROUP: <-- Add this when users can add their own choices
        case this.$constant.MULTI_CHOICE:
          return this.$createElement(ResponseItemChoices, {
            class: "border-b",
            props: {
              values: this.item.values,
              id: this.item.id,
            },
            on: {
              "values:update": ({ values }) => {
                this.formTemplateBuilderMixins_updateItem({
                  item: this.item,
                  body: { values },
                });
              },
            },
          });
      }
    },
    genAnswerBtn() {
      if (!this.isResponseTypeTextArea) return;

      return this.$createElement(
        "AppDefaultTooltip",
        {
          props: {
            top: true,
            disabled: !!this.template?.categoryId,
          },
          scopedSlots: {
            activator: ({ on }) =>
              this.$createElement("div", { on }, [
                this.$createElement(
                  VCard,
                  {
                    props: {
                      disabled: !this.template?.categoryId,
                      flat: true,
                      rounded: true,
                    },
                    class: "px-2",
                    on: {
                      click: (e) => {
                        this.formTemplateBuilderMixins_openDefaultAnswerTemplateMenu({
                          pointerEvent: e,
                          item: this.item,
                        });
                      },
                    },
                  },
                  [
                    this.$createElement(
                      "div",
                      {
                        class: "d-flex text-body-2 pr-1 cursor-pointer",
                        style: "color: rgba(0, 0, 0, 0.6);",
                      },
                      [
                        this.$createElement(
                          VIcon,
                          {
                            props: { size: 12 },
                            class: "pr-2",
                            style: "color: rgba(0, 0, 0, 0.6);",
                          },
                          this.$icon.REGULAR.COMMON.BOLT,
                        ),
                        this.$t("answerTemplates.title"),
                      ],
                    ),
                  ],
                ),
              ]),
          },
        },
        [this.$t("templateManagement.form.builder.item.addDefaultAnswerTooltip")],
      );
    },
    genCheckbox({ inputValue, label, changeEvent }) {
      return this.$createElement(
        VCheckbox,
        {
          class: `ma-0 pa-0 mr-5`,
          props: {
            inputValue: inputValue,
            dense: true,
            hideDetails: true,
            color: "primary",
          },
          on: {
            change: changeEvent,
          },
        },
        [
          this.$createElement("template", { slot: "label" }, [
            this.$createElement(
              "div",
              {
                class: `text-body-2 ${inputValue ? " primary--text" : ""}`,
              },
              [this.$t(label)],
            ),
          ]),
        ],
      );
    },
    genOptionsBar() {
      return this.$createElement(
        "div",
        {
          class: "pa-2 d-flex align-center",
        },
        [
          this.genCheckbox({
            inputValue: this.item.isRequired,
            label: "common.mandatory",
            changeEvent: (e) =>
              this.formTemplateBuilderMixins_updateItem({
                item: this.item,
                body: { isRequired: e },
              }),
          }),
          this.genCheckbox({
            inputValue: this.item.hasExplanation,
            label: "templateManagement.form.builder.item.helpText",
            changeEvent: (e) =>
              this.formTemplateBuilderMixins_updateItem({
                item: this.item,
                body: { hasExplanation: e },
              }),
          }),
          this.genCheckbox({
            inputValue: this.item.hasOptions,
            label: "templateManagement.form.builder.item.options",
            changeEvent: (e) =>
              this.formTemplateBuilderMixins_updateItem({
                item: this.item,
                body: { hasOptions: e },
              }),
          }),
          !this.isCms && this.isResponseTypeTextArea
            ? this.genCheckbox({
                inputValue: this.item.hasDefaultValue,
                label: "defaultAnswer.titleSingular",
                changeEvent: (e) => {
                  this.formTemplateBuilderMixins_updateItem({
                    item: this.item,
                    body: { hasDefaultValue: e },
                  });
                  if (e && !this.item.defaultValueTemplateId) {
                    this.$nextTick(this.focusAnswerInput);
                  }
                },
              })
            : null,
        ],
      );
    },
  },
  render(h) {
    const indexingText = this.formTemplateBuilderMixins_genIndexingText({
      responseItemIndex: this.responseItemIndex,
      sectionIndex: this.sectionIndex,
      parentGroupIndex: this.parentGroupIndex,
    });

    return h(
      VCard,
      {
        class: `d-flex flex-column border-a rounded`,
        props: { flat: true, disabled: this.item?.isLoading },
        attrs: {
          id: `form-template-item-${this.item.id}`,
        },
      },
      [
        h(
          "div",
          {
            class:
              "border-b input-background--background pl-2 py-1 text-body-2 font-weight-bold",
          },
          indexingText,
        ),
        h(
          "div",
          {
            class: `d-flex border-b align-center justify-center `,
          },
          [
            this.$createElement(
              "div",
              { style: "min-width: 32px", class: "d-flex align-center justify-center" },
              [
                this.$createElement(
                  VIcon,
                  {
                    class: "draggable-handle cursor-grab align-self-center",
                  },
                  this.$icon.SOLID.ACTION.DRAG,
                ),
              ],
            ),

            h("div", { class: "pt-1 pr-1 pb-1", style: "width: 70%;" }, [
              this.genNameInput(),
            ]),
            h(VDivider, { props: { vertical: true } }),
            h("div", { class: "d-flex align-center", style: "width: 30%;" }, [
              this.genResponseSelector(),
            ]),
          ],
        ),
        ...(this.showExplanation ? [this.genExplanation()] : []),
        ...(this.showDefaultAnswer ? [this.genAnswer()] : []),
        this.genValues(),
        this.genOptionsBar(),
      ],
    );
  },
};
</script>
