<template>
  <div class="d-flex align-center" v-if="selectedCount > 0">
    <div class="pl-2 subtitle--text text-no-wrap">
      {{ $t("common.numSelected", { n: selectedCount }) }}
    </div>
    <AppDefaultTooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-on="on" v-bind="attrs" class="ml-1" icon @click="$emit('click')">
          <v-icon>{{ $icon.REGULAR.ACTION.CLOSE }}</v-icon>
        </v-btn>
      </template>
      <span class="break-word">
        {{ $t("common.clearSelection") }}
      </span>
    </AppDefaultTooltip>
  </div>
</template>

<script>
export default {
  props: {
    selectedCount: Number,
  },
};
</script>
