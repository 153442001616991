<template>
  <v-card>
    <div class="dialog__close-btn">
      <v-btn icon dark @click="cancel">
        <v-icon>{{ $icon.LIGHT.ACTION.CLOSE }}</v-icon>
      </v-btn>
    </div>
    <v-card-title>
      <span class="headline">{{
        $t("settings.integrations.common.loginDialog.title", {
          integrationName: integrationNameTitle,
        })
      }}</span>
    </v-card-title>
    <v-card-text>
      <v-form
        ref="loginForm"
        v-model="login.valid"
        v-on:submit.prevent
        @submit="onNormalLoginSubmit"
      >
        <div class="p-relative">
          <div class="label--small required-asterisk">
            {{ $t("common.email") }}
          </div>
          <AppDefaultTextField
            v-model="login.username.value"
            :rules="$rules.NAME_RULES"
            autofocus
            type="email"
          />
        </div>

        <div class="p-relative">
          <div class="label--small required-asterisk">
            {{ $t("settings.integrations.common.loginDialog.password") }}
          </div>
          <AppDefaultTextField
            v-model="login.password.value"
            :rules="$rules.NAME_RULES"
            :type="showPassword ? 'text' : 'password'"
            @keydown.enter="onEnterPress"
            :append-icon="
              showPassword ? $icon.LIGHT.COMMON.EYE_SLASH : $icon.LIGHT.COMMON.EYE
            "
            @click:append="showPassword = !showPassword"
          />
        </div>
      </v-form>
      <v-btn
        ref="submitBtn"
        class="mb-3"
        depressed
        block
        color="primary"
        @click="onNormalLoginSubmit"
        :loading="isLoggingIn"
      >
        {{ $t("settings.integrations.common.loginDialog.login") }}
      </v-btn>

      <div v-if="forgotPasswordUrl">
        <AppLinkText :link="forgotPasswordUrl" underline-on-hover>
          {{ $t("settings.integrations.common.loginDialog.forgotPassword") }}
        </AppLinkText>
      </div>
      <div v-if="signUpUrl">
        <AppLinkText :link="signUpUrl" underline-on-hover>
          {{
            $t("settings.integrations.common.loginDialog.createAccount", {
              integrationName: integrationNameTitle,
            })
          }}
        </AppLinkText>
      </div>

      <!-- This is useful when we implement social login -->
      <!-- <div class="d-flex align-center pt-5">
            <div class="flex-grow-1">
              <v-divider />
            </div>
            <div class="px-2">
              {{ $t("integration.autogear.loginDialog.or") }}
            </div>
            <div class="flex-grow-1">
              <v-divider />
            </div>
          </div> -->

      <AppErrorAlert v-if="hasError">
        {{ $t("settings.integrations.common.loginDialog.loginFailed") }}
      </AppErrorAlert>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    integrationName: String,
    forgotPasswordUrl: String,
    signUpUrl: String,
    completeMethod: Function,
  },
  computed: {
    integrationNameTitle() {
      return this.$t(`integration.common.${this.integrationName}`);
    },
  },
  data() {
    return {
      login: {
        valid: false,
        username: {
          value: "",
        },
        password: {
          value: "",
        },
      },
      isLoggingIn: false,
      hasError: false,
      showPassword: false,
    };
  },
  methods: {
    onEnterPress() {
      this.onNormalLoginSubmit();
    },
    onNormalLoginSubmit() {
      const valid = this.$refs.loginForm.validate();
      if (valid) {
        this.isLoggingIn = true;
        this.hasError = false;
        const jsonLogin = JSON.stringify({
          username: this.login.username.value,
          password: this.login.password.value,
        });
        const code = window.btoa(jsonLogin);
        this.completeMethod({ integrationName: this.integrationName, code })
          .then(() => {
            this.getActiveIntegrations();
            this.cancel();
            this.isLoggingIn = false;
            this.$emit("complete");
          })
          .catch((err) => {
            this.isLoggingIn = false;
            this.hasError = true;
          });
      }
    },
    async getActiveIntegrations() {
      await this.$store.dispatch("activeIntegrations/getActiveIntegrations");
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>
