<template>
  <div>
    <custom-slide-y-reverse-transition>
      <v-toolbar
        v-show="hasSelectedFilesToMerge"
        color="ui-background-black"
        id="mergeActionBar"
        flat
        class="merge-action-bar"
        height="42"
        width="300"
      >
        <span class="white--text">
          {{ $tc("fileManagement.merge.documentsAdded", selectedFilesToMerge.length) }}
        </span>
        <v-spacer />
        <AppDefaultTooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              color="white"
              icon
              @click="dialog.merge.active = true"
            >
              <v-icon>{{ $icon.LIGHT.FILE.FILE_PDF }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("fileManagement.merge.actionMenu.mergeTooltip") }}</span>
        </AppDefaultTooltip>
        <AppDefaultTooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" n color="white" icon @click="onClearMerge">
              <v-icon>{{ $icon.LIGHT.ACTION.CLOSE }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("fileManagement.merge.actionMenu.removeTooltip") }}</span>
        </AppDefaultTooltip>
      </v-toolbar>
    </custom-slide-y-reverse-transition>

    <AppMergeToPdfDialog
      ref="mergeToPdfDialog"
      v-model="dialog.merge.active"
      :parentId="parentId"
      :items="selectedFilesToMerge"
      @items:change="onSelectedFilesToMergeUpdate"
      @item:remove="onSelectedFilesRemove"
    />

    <AppDeleteConfirmationDialog
      v-model="dialog.mergeClear.active"
      :title="$t('fileManagement.merge.clear.title')"
      :subtitle="$t('fileManagement.merge.clear.subTitle')"
      skip-validation
      :deleteBtnText="$t('fileManagement.merge.clear.validator')"
      @delete="clearMerge"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    parentId: String,
  },
  data() {
    return {
      dialog: {
        merge: {
          active: false,
        },
        mergeClear: {
          active: false,
        },
      },
    };
  },
  computed: {
    ...mapState("storageMergeToPdf", {
      selectedFilesToMerge: (state) => state.selectedFilesToMerge,
    }),
    hasSelectedFilesToMerge() {
      return this.selectedFilesToMerge.length > 0;
    },
  },
  methods: {
    onClearMerge() {
      this.dialog.mergeClear.active = true;
    },
    clearMerge() {
      this.$refs?.mergeToPdfDialog?.resetData();
    },
    onSelectedFilesToMergeUpdate(files) {
      this.$store.commit("storageMergeToPdf/setSelectedFiles", files);
    },
    onSelectedFilesRemove(file) {
      this.$store.commit("storageMergeToPdf/removeSelectedFile", file);
    },
    positionMergeActionBar() {
      const child = document.getElementById("mergeActionBar");

      if (!child) return;

      const pageWidth = window.innerWidth;
      const mergeActionBarWidth = 300; // Width of the merge action bar

      // Calculate the left position for centering
      const leftPos = (pageWidth - mergeActionBarWidth / 2) / 2;

      child.style.left = `${leftPos}px`;
      child.style.bottom = "30px";
    },
  },
  created() {
    addEventListener("resize", this.positionMergeActionBar);
  },
  destroyed() {
    removeEventListener("resize", this.positionMergeActionBar);
  },
  mounted() {
    this.positionMergeActionBar();
  },
};
</script>
