<template>
  <div>
    <AppNavigationList>
      <AppNavigationItem
        :to="{ name: $routeNames.CMS.TEMPLATES }"
        :prependIcon="$icon.REGULAR.ROUTE.DOCUMENT_TEMPLATES"
        :title="$t('drawer.route.documentTemplates')"
        prepend-icon-small
        replace
      />
      <AppNavigationItem
        :to="{ name: $routeNames.CMS.FORM_TEMPLATES }"
        :prependIcon="$icon.REGULAR.ROUTE.FORM"
        :title="$t('drawer.route.formTemplates')"
        prepend-icon-small
        replace
      />
      <AppNavigationItem
        :to="{ name: $routeNames.CMS.PROJECT_TEMPLATES }"
        :prependIcon="$icon.REGULAR.ROUTE.PROJECT"
        :title="$t('drawer.route.projectTemplates')"
        prepend-icon-small
        replace
      />
    </AppNavigationList>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("auth", {
      activeDomainId: "activeDomainId",
    }),
    ...mapState("auth", {
      user: (state) => state.user,
    }),
  },
};
</script>
