<template>
  <tr
    class="list_tr user-select-none"
    :class="highlightedClass"
    @contextmenu="onMenuClick"
    @click="select(!isSelected)"
  >
    <td>
      <v-simple-checkbox color="primary" :value="isSelected" @input="select($event)" />
    </td>
    <td class="p-relative">
      <div class="d-flex align-center">
        <div class="d-flex align-center">
          <AppStorageThumbnail :storage="storage" />
          <div class="ml-4 table-cell-content__breakable-long-text">
            <span class="cursor-pointer underline-on-hover" @click.stop="onNameClick">
              {{ storage.name }}
              <span v-if="storage.type === $constant.FOLDER" class="font-weight-bold">
                ({{ storageMixins_getStorageCount(storage) }})
              </span>
            </span>
            <div class="d-flex">
              <AppStorageItemInfoRow :storage="storage" />
            </div>
          </div>
        </div>
        <v-spacer />
      </div>
    </td>
    <td>
      <template v-if="owner">
        <AppUserAvatar :user="owner" show-name :truncate="20" />
      </template>
    </td>
    <td>{{ lastModified }}</td>
    <td>
      <v-btn icon @click.stop="onMenuClick">
        <v-icon>{{ $icon.REGULAR.ACTION.MENU }}</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import moment from "moment";
import { storageMixins } from "@/helpers/mixins";

export default {
  mixins: [storageMixins],
  props: {
    storage: Object,
    isSelected: Boolean,
  },
  computed: {
    highlightedClass() {
      return this.isSelected ? "list_tr--selected" : "";
    },
    lastModified() {
      if (!this.storage.updatedAt) return "";
      return moment(this.storage.updatedAt).format("ll");
    },
    ownerName() {
      const owner = this.storage?.storageOwner;
      if (!owner) return "";
      let name = owner.email;
      if (owner.firstName && owner.lastName) {
        name = `${owner.firstName} ${owner.lastName}`;
      } else if (owner.firstName) {
        name = `${owner.firstName}`;
      } else if (owner.lastName) {
        name = `${owner.lastName}`;
      }

      return name;
    },
    owner() {
      const owner = this.storage?.storageOwner;
      if (!owner) return null;
      return { ...owner, name: this.ownerName };
    },
    isSentToBoligmappa() {
      return this.getStorageLookupByIntegrationId(this.$constant.BOLIGMAPPA);
    },
  },
  methods: {
    onNameClick() {
      this.$emit("name:click", { storage: this.storage });
    },
    onMenuClick(event) {
      this.$emit("menu:click", { storage: this.storage, event });
    },
    select(isSelected) {
      this.$emit("isSelected:change", isSelected);
    },
    getStorageLookupByIntegrationId(integrationId) {
      if (!Array.isArray(this.storage.lookup)) return null;
      const lookup = this.storage.lookup.find((i) => (i.integrationId = integrationId));
      if (!lookup || !lookup?.integrationId) return null;
      return lookup.integrationId;
    },
  },
};
</script>
