<template>
  <v-icon :size="size" :color="color"> {{ $icon.SOLID.COMMON.STAR }}</v-icon>
</template>

<script>
export default {
  props: {
    xSmall: Boolean,
    small: Boolean,
    medium: Boolean,
    large: Boolean,
    xLarge: Boolean,

    planCode: String,
  },
  computed: {
    color() {
      if (this.isPro) {
        return "pro-plan";
      }
    },
    size() {
      if (this.xSmall) {
        return 10;
      }
      if (this.small) {
        return 16;
      }
      if (this.large) {
        return 45;
      }
    },
    isPro() {
      return this.isPlan(this.$constant.PLAN_CODE.GRIPR_PRO);
    },
  },
  methods: {
    isPlan(planCode) {
      return this.planCode === planCode;
    },
  },
};
</script>
