<template>
  <tr class="list_tr" :class="highlightedClass" @contextmenu="onMenuClick">
    <td class="text-start">
      <div class="table-cell-content__breakable-long-text">
        {{ client.name }}
      </div>
    </td>
    <td>{{ client.organizationNumber }}</td>
    <td class="text-start">
      <template v-if="client.owner">
        <AppUserAvatar :user="client.owner" show-name :truncate="15" />
      </template>
    </td>
    <td class="text-start">
      <div class="table-cell-content__breakable--margin">
        <template v-if="client.type">
          {{ $t(`contact.client.type.${client.type.toLowerCase()}`) }}
          <template v-if="client.isPrivate">
            ({{ $t("contact.client.customerType.private") }})
          </template>
        </template>
      </div>
    </td>
    <td class="text-start">
      <AppPhoneLink :phone="client?.phone" />
    </td>
    <td class="text-start">
      <AppLinkText :link="`mailto:${client.email}`" underline-on-hover>
        {{ client.email }}
      </AppLinkText>
    </td>
    <td class="text-start">
      <div class="d-flex align-center">
        <v-checkbox
          :disabled="!!projectId"
          :inputValue="client.active"
          @change="updateClient({ id: client.id, body: { active: $event }, snack: true })"
        />
      </div>
    </td>
    <td class="text-end">
      <v-btn icon @click="onMenuClick">
        <v-icon>
          {{ $icon.REGULAR.ACTION.MENU }}
        </v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    client: Object,
    isHighlighted: Boolean,
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    highlightedClass() {
      return this.isHighlighted ? "list_tr--selected" : "";
    },
  },
  methods: {
    onMenuClick(event) {
      this.$emit("menu:click", { client: this.client, event });
    },
    updateClient(e) {
      this.$emit("client:update", e);
    },
  },
};
</script>
