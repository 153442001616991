<template>
  <v-dialog v-model="localDialog" max-width="1000px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $t("formManagement.signatureSession.sendToSignDialog.title") }}
        </span>
      </v-card-title>
      <v-card-text>
        <div class="d-flex flex-column overflow-y-auto" style="max-height: 600px">
          <v-form ref="signersForm" v-model="isValid" @submit="onSubmit">
            <div class="d-flex pt-1" v-for="(recipient, index) in signers" :key="index">
              <AppUserAndContactGroupedUserAndContactCombobox
                class="pr-3"
                :loading="isLoading"
                :users="users"
                :contacts="contacts"
                :label="
                  $t(
                    'formManagement.signatureSession.sendToSignDialog.addFromListOrEnterFreeText',
                  )
                "
                :value="recipient"
                @change="changeRecipientNameAndEmail($event, recipient)"
              />
              <AppDefaultTextField
                style="width: 200px"
                :rules="[...$rules.EMAIL_RULES, ...$rules.REQUIRED]"
                class="pr-3"
                :label="
                  $t('formManagement.signatureSession.sendToSignDialog.recipientEmail')
                "
                v-model="recipient.email"
              />

              <AppDefaultSelect
                style="width: 50px"
                :items="languages"
                v-model="recipient.language"
                :clearable="false"
              >
                <template v-slot:item="{ item }">
                  <div>{{ item.text }} ({{ item.value }})</div>
                </template>
              </AppDefaultSelect>

              <v-btn icon class="mx-3" @click="onRecipientDelete(recipient)">
                <v-icon>{{ $icon.LIGHT.ACTION.DELETE }}</v-icon>
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-card-text>
      <v-card-actions>
        <div class="d-flex flex-column gap-2 w-100">
          <div>
            <v-btn outlined color="primary" @click="addRecipient">
              <v-icon class="pr-3">{{ $icon.REGULAR.ACTION.ADD }}</v-icon>
              {{
                $t("formManagement.signatureSession.sendToSignDialog.addRecipientButton")
              }}
            </v-btn>
          </div>
          <AppInfoAlert v-if="!isFormStatusCategoryDone">
            {{
              $t(
                "formManagement.signatureSession.sendToSignDialog.infoMessages.youCantFillOutAfterSigning",
              )
            }}
          </AppInfoAlert>
          <AppErrorAlert v-if="showNeedsAtLeastOneSignerError">
            {{
              $t(
                "formManagement.signatureSession.sendToSignDialog.errorMessages.needAtLeastOneSigner",
              )
            }}
          </AppErrorAlert>
          <AppErrorAlert v-if="showNoDuplicateEmailsError">
            {{
              $t(
                "formManagement.signatureSession.sendToSignDialog.errorMessages.noDuplicateEmails",
              )
            }}
          </AppErrorAlert>
          <AppErrorAlert v-if="showApiError">
            {{
              $t(
                "formManagement.signatureSession.sendToSignDialog.errorMessages.apiError",
              )
            }}
          </AppErrorAlert>

          <AppDialogActionBtnPair
            @confirm="onSubmit"
            @cancel="closeDialog"
            :loading="isSendingToSign"
            :confirmText="
              $t('formManagement.signatureSession.sendToSignDialog.completeButton')
            "
          />
        </div>
      </v-card-actions>
    </v-card>
    <AppDeleteConfirmationDialog
      v-model="dialogs.deleteRecipient.active"
      :title="
        $t('formManagement.signatureSession.sendToSignDialog.confirmDeleteDialog.title')
      "
      :subtitle="
        $t(
          'formManagement.signatureSession.sendToSignDialog.confirmDeleteDialog.subTitle',
        )
      "
      skip-validation
      :item="dialogs.deleteRecipient.item"
      @delete="deleteRecipient"
    />
  </v-dialog>
</template>

<script>
import { getSearchUsers } from "@/services/search/users";
import { sendFormToSign } from "@/services/form/formSignSession";
import { getSearchContacts } from "@/services/search/contact";
import { mapGetters } from "vuex";

const genEmptySigner = () => ({ name: "", email: "", language: "nb", userId: null });

export default {
  data() {
    return {
      isValid: false,
      dialogs: {
        deleteRecipient: {
          item: null,
          active: false,
        },
      },
      signers: [genEmptySigner()],
      languages: [
        {
          value: "nb",
          text: this.$t("formManagement.signatureSession.sendToSignDialog.language.no"),
        },
        {
          value: "en",
          text: this.$t("formManagement.signatureSession.sendToSignDialog.language.en"),
        },
      ],
      users: [],
      isLoadingUsers: false,
      contacts: [],
      isLoadingContacts: false,
      showNeedsAtLeastOneSignerError: false,
      showNoDuplicateEmailsError: false,
      showApiError: false,
      isSendingToSign: false,
    };
  },
  props: {
    dialog: Boolean,
    formId: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  watch: {
    dialog: {
      handler(val) {
        if (val) {
          this.getUserNameList();
          this.getContacts();
        }
      },
      immediate: true,
    },
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    isLoading() {
      return this.isLoadingContacts || this.isLoadingUsers;
    },
    ...mapGetters("form", {
      isFormStatusCategoryDone: "isFormStatusCategoryDone",
    }),
  },
  methods: {
    changeRecipientNameAndEmail(event, recipient) {
      if (!event) {
        recipient.userId = null;
        return;
      }
      if (typeof event === "string") {
        recipient.name = event;
      } else {
        recipient.name = event.name;
        recipient.userId = event.id;
        recipient.email = event.email;
      }
    },
    isEmpty(recipient) {
      const isUndefined = !recipient.name && !recipient.email;
      const isEmpty = !recipient?.name?.trim() && !recipient?.email?.trim();
      return isUndefined || isEmpty;
    },
    onRecipientDelete(recipient) {
      let isEmpty = this.isEmpty(recipient);

      if (isEmpty) {
        this.deleteRecipient({ item: recipient });
        return;
      }

      this.dialogs.deleteRecipient.item = recipient;
      this.dialogs.deleteRecipient.active = true;
    },
    deleteRecipient({ item }) {
      const index = this.signers.indexOf(item);
      if (index === -1) return;
      this.signers.splice(index, 1);
    },
    addRecipient() {
      this.signers.push(genEmptySigner());
    },
    hasAtLeastOneSigner() {
      return this.signers.length > 0;
    },
    hasNoDuplicateEmails() {
      const emails = this.signers.map((recipient) => recipient.email);
      return new Set(emails).size === emails.length;
    },
    validate() {
      this.showApiError = false;
      this.isValid = this.$refs.signersForm.validate();
      const isValidNoDuplicateEmails = this.hasNoDuplicateEmails();
      this.showNoDuplicateEmailsError = !isValidNoDuplicateEmails;
      const isValidHasAtLeastOneSigner = this.hasAtLeastOneSigner();
      this.showNeedsAtLeastOneSignerError = !isValidHasAtLeastOneSigner;
      return this.isValid && isValidNoDuplicateEmails && isValidHasAtLeastOneSigner;
    },
    async getUserNameList() {
      this.isLoadingUsers = true;
      const params = {
        alwaysIncludeIds: this.signers?.map((signer) => signer?.userId) ?? [],
      };
      this.users = await getSearchUsers({ params });
      this.isLoadingUsers = false;
    },
    async getContacts() {
      this.isLoadingContacts = true;
      getSearchContacts()
        .then((contacts) => {
          this.contacts = contacts;
        })
        .finally(() => (this.isLoadingContacts = false));
    },
    getBody() {
      return {
        formId: this.formId,
        signers: this.signers.map((signer) => ({
          name: signer.name,
          email: signer.email,
          language: signer.language,
        })),
      };
    },
    onSubmit() {
      const isValid = this.validate();
      if (!isValid) return;
      const body = this.getBody();
      this.isSendingToSign = true;
      sendFormToSign({ body })
        .then((data) => {
          this.$emit("submit");
          this.resetAndCloseDialog();
        })
        .catch((err) => {
          this.showApiError = true;
          throw new Error(err);
        })
        .finally(() => {
          this.isSendingToSign = false;
        });
    },
    resetAndCloseDialog() {
      this.signers = [genEmptySigner()];
      this.closeDialog();
    },
    closeDialog() {
      this.localDialog = false;
      this.showNeedsAtLeastOneSignerError = false;
      this.showNoDuplicateEmailsError = false;
      this.showApiError = false;
      this.$refs.signersForm.resetValidation();
    },
  },
};
</script>
