<template>
  <AppMenuItem
    :text="$t(newTabInfo.text)"
    :disabled="disabled"
    :icon="$icon.REGULAR.ACTION.POP_OUT"
    @click="click"
  />
</template>

<script>
import { getOpenInNewTabInfo } from "@/helpers/util/router";

export default {
  props: {
    disabled: Boolean,
    type: String,
    id: String,
  },
  computed: {
    newTabInfo() {
      return getOpenInNewTabInfo(this.type, this.id);
    },
  },
  methods: {
    click() {
      const { route, params } = this.newTabInfo;
      const url = this.$router.resolve({ name: route, params }).href;
      window.open(url, "_blank");
    },
  },
};
</script>
