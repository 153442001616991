<template>
  <v-list class="pa-0">
    <v-list-item
      v-for="(file, index) in files"
      :key="index"
      :class="{ 'drag-over': isDraggingOver }"
      class="file-list-item"
    >
      <AppStorageThumbnail :file="file" class="mr-3" />
      <v-list-item-content>
        <v-list-item-title>{{ file.name }}</v-list-item-title>
        <v-list-item-subtitle>{{ formatFileSize(file.size) }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-btn icon @click="$emit('removeFile', file)">
        <v-icon>{{ $icon.LIGHT.ACTION.CLOSE }}</v-icon>
      </v-btn>
    </v-list-item>
  </v-list>
</template>

<script>
import { storageMixins } from "@/helpers/mixins";

export default {
  mixins: [storageMixins],
  props: {
    files: {
      type: Array,
      required: true,
    },
    isDraggingOver: Boolean,
  },
  methods: {
    formatFileSize(size) {
      const units = ["B", "KB", "MB", "GB", "TB"];
      let i = 0;
      while (size >= 1024 && i < units.length - 1) {
        size /= 1024;
        i++;
      }
      return `${size.toFixed(2)} ${units[i]}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/common/background_colors";
@import "../../../styles/common/common";

.file-list-item {
  @extend .drag-over-transition;
}

.drag-over {
  @extend .success-super-light--background;
}
</style>
