<template>
  <v-dialog
    v-model="localDialog"
    transition="dialog-bottom-transition"
    fullscreen
    persistent
    hide-overlay
    no-click-animation
  >
    <v-card class="background">
      <div class="dialog__close-btn">
        <v-btn icon dark @click="close">
          <v-icon>{{ $icon.LIGHT.ACTION.CLOSE }}</v-icon>
        </v-btn>
      </div>
      <v-container class="d-flex flex-column justify-center h-100vh mw-650px px-4">
        <div class="text-h4 font-weight-black primary--text">
          {{ $t("signUp.profile.userProfile") }}
        </div>
        <AppProfileAvatarSelector
          :avatarPath="avatarPath"
          style="max-width: 124px"
          class="pt-10 pb-5"
          @avatar:upload="$emit('avatar:upload')"
          @avatar:delete="$emit('avatar:delete')"
        >
        </AppProfileAvatarSelector>
        <template v-if="profile">
          <div>
            <v-row>
              <v-col>
                <v-form v-model="firstName.isValid" @submit.prevent>
                  <div class="w-100">
                    <div class="label--small font-weight-bold">
                      {{ $t("signUp.profile.firstName") }}
                    </div>
                    <AppDefaultTextField
                      :value="profile.firstName"
                      :rules="firstName.rules"
                      @blur="updateProfileField($event, 'firstName')"
                      :placeholder="$t('signUp.profile.firstName')"
                    />
                  </div>
                </v-form>
              </v-col>
              <v-col>
                <v-form v-model="lastName.isValid" @submit.prevent>
                  <div class="w-100">
                    <div class="label--small font-weight-bold">
                      {{ $t("signUp.profile.lastName") }}
                    </div>
                    <AppDefaultTextField
                      :value="profile.lastName"
                      :rules="lastName.rules"
                      @blur="updateProfileField($event, 'lastName')"
                      :placeholder="$t('signUp.profile.lastName')"
                    >
                    </AppDefaultTextField>
                  </div>
                </v-form>
              </v-col>
            </v-row>
          </div>
          <v-form v-model="phone.isValid" @submit.prevent>
            <div>
              <div class="label--small font-weight-bold">
                {{ $t("signUp.profile.phoneNumber") }}
              </div>
              <AppPhoneInput
                v-model="phone.value"
                @blur="updateProfileField($event, 'phone')"
                required
              />
            </div>
          </v-form>
          <div>
            <div class="label--small font-weight-bold">
              {{ $t("signUp.profile.yourEmail") }}
            </div>
            <AppDefaultTextField
              placeholder="example.com"
              :value="profile.email"
              disabled
            />
          </div>
          <div>
            <v-btn
              color="error"
              depressed
              :href="`mailto:${$constant.CONTACT_EMAIL}`"
              target="_blank"
              >{{ $t("signUp.profile.deleteUser") }}
            </v-btn>
          </div>
        </template>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      avatarPath: null,
      firstName: {
        isValid: false,
        rules: this.$rules.REQUIRED,
      },
      lastName: {
        isValid: false,
        rules: this.$rules.REQUIRED,
      },
      phone: {
        isValid: false,
        value: "",
        rules: this.$rules.REQUIRED,
      },
    };
  },
  props: {
    dialog: Boolean,
    profile: Object,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    ...mapGetters("profile", {
      metaValueByField: "metaValueByField",
    }),
  },
  watch: {
    dialog: {
      handler(profile) {
        this.fillProfileForm();
      },
      immediate: true,
    },
  },
  methods: {
    close() {
      this.localDialog = false;
    },
    async updateProfileField(e, field) {
      const value = typeof e === "object" && e !== null ? e?.target?.value : e;
      if (!this[field].isValid) return;
      this.updateProfile({ [field]: value });
    },
    updateProfile(data) {
      this.$emit("profile:update", data);
    },
    fillProfileForm() {
      if (this.profile) {
        this.phone.value = this.metaValueByField("PHONE");

        this.avatarPath = "";
        setTimeout(() => {
          this.avatarPath = this.metaValueByField("AVATAR");
        }, 10);
      }
    },
  },
};
</script>
