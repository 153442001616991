<template>
  <ProjectHeader :activeProject="project" @project:change="onProjectChange" />
</template>

<script>
import { mapState } from "vuex";
import ProjectHeader from "./ProjectHeader.vue";

export default {
  computed: {
    ...mapState("project", {
      project: (state) => state.project,
      isProjectLoading: (state) => state.isLoading,
    }),
    projectId() {
      return this.$route.params.projectId;
    },
  },
  methods: {
    async getProject(id) {
      await this.$store.dispatch("project/getProject", id);
    },
    onProjectChange({ project }) {
      if (project) {
        this.$router.replace({
          params: { projectId: project.id },
        });
      }
    },
  },
  components: { ProjectHeader },
};
</script>
