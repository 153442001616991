<template>
  <div class="d-flex justify-center" v-if="isTrial">
    <AppRectangleIcon
      v-if="isMini"
      @click="onClick"
      :color="color"
      :icon="hourGlassIcon"
      :tooltipText="daysLeftText"
      clickable
    />
    <AppColorChip
      v-else
      @click="onClick"
      :color="textColor"
      class="font-weight-regular px-6"
      style="border-radius: 20px"
      large
      slot-class="text-caption d-flex w-100"
      clickable
    >
      <div class="text-center w-100 py-1" v-html="daysLeftText"></div>
    </AppColorChip>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("auth", {
      subscriptionPlan: "subscriptionPlan",
    }),
    ...mapGetters("appDrawer", {
      isMini: "isDrawerMiniVariant",
    }),
    isTrial() {
      return this.subscriptionPlan?.planCode === this.$constant.PLAN_CODE.GRIPR_TRIAL;
    },
    formattedDaysLeft() {
      const daysLeft = this.subscriptionPlan?.remainingSubscriptionDays + 1;
      return daysLeft < 0 ? 0 : daysLeft;
    },
    daysLeftText() {
      return this.$tc("trialDaysLeftLabel", this.formattedDaysLeft);
    },
    hourGlassIcon() {
      if (this.formattedDaysLeft > 5) return this.$icon.REGULAR.COMMON.HOURGLASS_START;

      if (this.formattedDaysLeft > 0 && this.formattedDaysLeft <= 5) {
        return this.$icon.REGULAR.COMMON.HOURGLASS_HALF;
      }
      return this.$icon.REGULAR.COMMON.HOURGLASS_END;
    },
    textColor() {
      if (this.formattedDaysLeft <= 5) return "error--text";
      return "info--text";
    },
    color() {
      if (this.formattedDaysLeft <= 5) return "error";
      return "info";
    },
  },
  methods: {
    onClick() {
      this.openUpgradePlanDialog();
    },
    openUpgradePlanDialog() {
      this.$store.dispatch("upgradePlanDialog/setUpgradePlanDialog", {
        active: true,
        planCode: this.$constant.PLAN_CODE.GRIPR_PRO,
      });
    },
  },
};
</script>
