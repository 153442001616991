<template>
  <v-btn
    depressed
    :class="`text-none text-subtitle-2 justify-start ${disabledClass} ${backgroundColorIfSelected}`"
    :block="block"
    @click="onClick"
  >
    <div class="d-flex align-center w-100">
      <slot name="prepend-inner"></slot>
      <i v-if="emoji" :class="['mr-2', 'em', emoji]"></i>
      <template v-if="icon">
        <v-icon
          style="width: 20px !important; margin-right: 17.7777777778px"
          class="mr-4 w-100"
          :small="!largeIcon"
          :color="iconColorComputed"
        >
          {{ icon }}
        </v-icon>
      </template>
      <div class="d-flex flex-column align-start">
        <span :class="`${color}--text${disabledText}`">
          <AppTextTruncateValue :text="text" :truncate="40" />
        </span>
        <div v-if="subText" class="text-caption">{{ subText }}</div>
      </div>
      <v-spacer />
      <div class="pl-2">
        <slot name="append-inner"></slot>
      </div>
      <AppSubscriptionPlanStar class="pl-2" v-if="showStar" :planCode="planCode" small />
    </div>
  </v-btn>
</template>

<script>
export default {
  props: {
    text: String,
    subText: String,
    icon: String,
    color: String,
    iconColor: String,
    disabled: Boolean,
    value: undefined,
    isSelected: Boolean,
    showStar: Boolean,
    planCode: String,
    emoji: String,
    block: Boolean,
    largeIcon: Boolean,
  },
  computed: {
    iconColorComputed() {
      return this.disabled ? "" : this.iconColor ?? this.color;
    },
    disabledText() {
      return this.disabled ? "--disabled" : "";
    },
    backgroundColorIfSelected() {
      return this.isSelected ? "action-button" : "";
    },
    disabledClass() {
      return this.disabled ? "menu-item-disabled" : "";
    },
  },
  methods: {
    onClick(e) {
      this.$emit("click", { value: this.value, pointerEvent: e });
    },
  },
};
</script>
