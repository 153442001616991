<template>
  <div>
    <AppToggleMenuBtn
      :loading="isCreateNewLoading"
      :text="$t('fileManagement.header.createNew')"
      :icon="$icon.SOLID.ACTION.DROPDOWN"
      color="primary"
    >
      <template v-slot:activator="{ on, attrs }" v-if="iconButton">
        <AppDefaultTooltip bottom>
          <template v-slot:activator="{ on: ttOn, attrs: ttAttrs }">
            <v-btn
              icon
              v-bind="{ ...ttAttrs, ...attrs }"
              v-on="{ ...ttOn, ...on }"
              :loading="isCreateNewLoading"
            >
              <v-icon>{{ $icon.REGULAR.ACTION.ADD }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("fileManagement.header.createNew") }}</span>
        </AppDefaultTooltip>
      </template>

      <AppMenuItem
        :text="$t('fileManagement.header.createNewFolder')"
        :icon="$icon.REGULAR.FILE.FOLDER_PLUS"
        :disabled="!canUpload"
        @click="onFolderCreate"
      />

      <v-divider class="my-2" />

      <AppMenuItem
        :text="$t('fileManagement.header.uploadFile')"
        :showStar="!planRestrictionsMixins_canUploadToStorage"
        :planCode="$constant.PLAN_CODE.GRIPR_PRO"
        :icon="$icon.REGULAR.ACTION.UPLOAD_FILE"
        :disabled="!canUpload"
        @click="onFileUpload"
      />

      <AppMenuItem
        :text="$t('fileManagement.header.createNewWord')"
        :showStar="!planRestrictionsMixins_canUseOfficeOnline"
        :planCode="$constant.PLAN_CODE.GRIPR_PRO"
        icon="$docx"
        :disabled="!canUpload"
        @click="onOfficeFileCreate('DOCX')"
      />

      <AppMenuItem
        :text="$t('fileManagement.header.createNewExcel')"
        :showStar="!planRestrictionsMixins_canUseOfficeOnline"
        :planCode="$constant.PLAN_CODE.GRIPR_PRO"
        icon="$xlsx"
        :disabled="!canUpload"
        @click="onOfficeFileCreate('XLSX')"
      />

      <AppMenuItem
        :text="$t('fileManagement.header.createNewPowerPoint')"
        :showStar="!planRestrictionsMixins_canUseOfficeOnline"
        :planCode="$constant.PLAN_CODE.GRIPR_PRO"
        icon="$pptx"
        :disabled="!canUpload"
        @click="onOfficeFileCreate('PPTX')"
      />

      <template v-if="!hideDocumentTemplateActions">
        <v-divider class="my-2" v-if="isHseFeatureEnabled || isQaFeatureEnabled" />

        <AppMenuItem
          v-if="isHseFeatureEnabled"
          :text="$t('fileManagement.header.getHseTemplatesFromGripr')"
          :showStar="!planRestrictionsMixins_canAddDocumentTemplates"
          :planCode="$constant.PLAN_CODE.GRIPR_PRO"
          :icon="$icon.REGULAR.ACTION.GET_DOCUMENT_TEMPLATE"
          @click="onAddTemplatesClick('HSE')"
        />

        <AppMenuItem
          v-if="isQaFeatureEnabled"
          :text="$t('fileManagement.header.getQaTemplatesFromGripr')"
          :showStar="!planRestrictionsMixins_canAddDocumentTemplates"
          :planCode="$constant.PLAN_CODE.GRIPR_PRO"
          :icon="$icon.REGULAR.ACTION.GET_DOCUMENT_TEMPLATE"
          @click="onAddTemplatesClick('QA')"
        />
      </template>
    </AppToggleMenuBtn>

    <AppNewFolderDialog
      permissionLevel
      v-model="dialog.folderCreate.active"
      :data="dialog.folderCreate.data"
      @submit:form="afterFolderCreate"
    />

    <input
      ref="uploader"
      class="d-none"
      type="file"
      :accept="fileAccepts"
      @change="afterFileUpload"
      multiple
    />

    <template v-if="projectId">
      <AppDocumentTemplatePickerDialog
        v-model="dialog.documentTemplatePicker.active"
        :group="dialog.documentTemplatePicker.group"
        :projectId="projectId"
        @dialog:change="onCmsDocumentTemplatePickerDialogChange"
      />
    </template>
    <template v-else>
      <AppCmsDocumentTemplatePickerDialog
        v-model="dialog.documentTemplatePicker.active"
        :group="dialog.documentTemplatePicker.group"
        @dialog:change="onCmsDocumentTemplatePickerDialogChange"
      />
    </template>

    <AppNewOfficeFileDialog
      v-model="dialog.newOfficeFile.active"
      :type="dialog.newOfficeFile.type"
      @submit="officeFileCreate"
    />
  </div>
</template>

<script>
import { planRestrictionsMixins, storageMixins } from "@/helpers/mixins";
import { mapGetters, mapState } from "vuex";

export default {
  mixins: [planRestrictionsMixins, storageMixins],
  props: {
    folderId: String,
    iconButton: Boolean,
    hideDocumentTemplateActions: Boolean,
  },
  data() {
    return {
      dialog: {
        folderCreate: {
          active: false,
          data: null,
        },
        documentTemplatePicker: {
          active: false,
          group: null,
        },
        newOfficeFile: {
          active: false,
          type: null,
        },
      },
    };
  },
  computed: {
    ...mapGetters("auth", {
      isHseFeatureEnabled: "isHseFeatureEnabled",
      isQaFeatureEnabled: "isQaFeatureEnabled",
    }),
    ...mapState("storage", {
      acceptedTypes: (state) => state.file.acceptedTypes,
      isFileUploading: (state) => state.file.isUploading,
      isFolderUploading: (state) => state.folder.isUploading,
      parent: (state) => state.parent,
    }),
    ...mapGetters("storage", {
      canUpload: "canUpload",
    }),
    ...mapGetters("wopiDiscovery", {
      getWopiApplicationActions: "getApplicationActions",
    }),
    isCreateNewLoading() {
      return this.isFileUploading || this.isFolderUploading;
    },
    fileAccepts() {
      let accepted = this.acceptedTypes.join(",") || "";
      accepted = accepted + `${accepted ? "," : ""}.wopitest,.wopitestx`;
      return accepted;
    },
    projectId() {
      return this.$route.params.projectId;
    },
  },
  methods: {
    onFolderCreate() {
      this.dialog.folderCreate.active = true;
      this.dialog.folderCreate.data = {
        parent: {
          isRoot: this.parent.isRoot,
          item: this.parent.item,
        },
      };
    },
    afterFolderCreate({ name, permissionLevel }) {
      const data = { name, permissionLevel, parentId: this.folderId };
      this.$store.dispatch("storage/createFolder", data);
    },
    openUpgradePlanDialog() {
      this.$store.dispatch("upgradePlanDialog/setUpgradePlanDialog", {
        active: true,
        planCode: this.$constant.PLAN_CODE.GRIPR_PRO,
      });
    },
    onOfficeFileCreate(type) {
      if (!this.planRestrictionsMixins_canUseOfficeOnline) {
        this.openUpgradePlanDialog();
      } else {
        if (type !== "WOPITEST") {
          this.dialog.newOfficeFile.active = true;
          this.dialog.newOfficeFile.type = type;
        } else {
          this.officeFileCreate({ name: "test.wopitest" });
        }
      }
    },

    async officeFileCreate(data) {
      const body = {
        ...data,
        parentId: this.folderId,
      };

      const storage = await this.$store.dispatch("storage/createEmptyFile", { body });
      const extension = storage.extension.replace(".", "");
      const actions = this.getWopiApplicationActions(extension, true);
      let action = actions.find((action) => action.name === "editnew");
      if (!action) {
        action = actions.find((action) => action.name === "edit");
      }

      const route = this.$router.resolve({
        name: this.$routeNames.OFFICE_ONLINE.ROOT,
        params: { fileId: storage.id },
        query: { actionUrl: action.url, faviconUrl: action.faviconUrl },
      });

      window.open(route.href, "_blank");
    },
    onFileUpload() {
      if (!this.planRestrictionsMixins_canUploadToStorage) {
        this.openUpgradePlanDialog();
      } else {
        this.$refs.uploader.click();
      }
    },
    async afterFileUpload(e) {
      const formData = await this.handleGetFileFormData(e, this.folderId);
      this.$store.dispatch("storage/uploadFiles", {
        parentId: this.folderId,
        formData,
      });
    },
    async handleGetFileFormData(e, parentId) {
      if (!this.canUpload) return;
      const files = this.storageMixins_getFiles(e);
      this.$refs.uploader.value = null;
      const { formData } = await this.storageMixins_getFileFormData(files, parentId);
      return formData;
    },
    onAddTemplatesClick(group) {
      if (!this.planRestrictionsMixins_canAddDocumentTemplates) {
        this.openUpgradePlanDialog();
      } else {
        this.dialog.documentTemplatePicker.active = true;
        this.dialog.documentTemplatePicker.group = group;
      }
    },
    onCmsDocumentTemplatePickerDialogChange(isOpen) {
      if (isOpen) return;
      this.$store.dispatch("storage/getStorage", this.folderId);
    },
    autoOpenDialog() {
      const autoOpen = this.$route?.query?.autoOpen;

      if (autoOpen) {
        if (autoOpen === "HSE") {
          this.onAddTemplatesClick("HSE");
        } else if (autoOpen === "QA") {
          this.onAddTemplatesClick("QA");
        }
      }
    },
  },
  mounted() {
    this.autoOpenDialog();
  },
};
</script>
