<template>
  <div>
    <AppDefaultTooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on" :small="small" :x-small="xSmall">
          {{ $icon.REGULAR.ACTION.REPEAT }}
        </v-icon>
      </template>
      <span>
        {{ $t("repeatOption.repeating") }}
      </span>
    </AppDefaultTooltip>
  </div>
</template>

<script>
export default {
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
