<template>
  <tr class="list_tr" :class="highlightedClass" @contextmenu="onMenuClick">
    <td v-if="groupByMixins_showColumn(filtersMixins_dataFields.STATUS)">
      <AppCustomStatusIcon
        @click="(e) => $emit('status:click', { ...e, selectedItem: project })"
        model="project"
        :statusId="statusId"
        :loading="project?.isUpdatingStatus"
        :disabled="isDisabledStatus"
      />
    </td>
    <td v-if="hasProjectImportIntegrationEnabled">
      <div class="text-no-wrap">
        {{ project.projectNumber }}
      </div>
    </td>
    <td>
      <div class="table-cell-content__breakable-long-text">
        <span class="underline-on-hover cursor-pointer" @click="onGoToProjectClick">
          {{ project?.displayName }}
        </span>
      </div>
    </td>
    <td v-if="groupByMixins_showColumn(filtersMixins_dataFields.CLIENT)">
      <div v-if="project.client" class="table-cell-content__breakable-long-text">
        {{ project.client.name }}
      </div>
    </td>
    <td v-if="groupByMixins_showColumn(filtersMixins_dataFields.OWNER)">
      <template v-if="project.owner">
        <AppUserAvatar :user="project.owner" show-name :truncate="20" />
      </template>
    </td>
    <td>
      {{
        $tc(
          "project.list.memberAmount",
          Array.isArray(project.members) ? project.members.length : 0,
        )
      }}
    </td>
    <td v-if="groupByMixins_showColumn(filtersMixins_dataFields.START_DATE)">
      {{ startDate }}
    </td>
    <td class="text-end">
      <v-btn icon @click="onMenuClick">
        <v-icon>{{ $icon.REGULAR.ACTION.MENU }}</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import { project } from "@/store/modules";
import { groupByMixins } from "@/helpers/mixins";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  mixins: [groupByMixins],
  props: {
    project: Object,
    isHighlighted: Boolean,
    tableModel: String,
    headers: Array,
  },
  computed: {
    isDisabledStatus() {
      if (this.project?.isUpdatingStatus) return true;
      if (!this.project.permissions?.canEdit) return true;
    },
    startDate() {
      const date = this.project?.startDate;
      if (!date) return;
      return moment(date).format("DD.MM.YYYY");
    },
    highlightedClass() {
      return this.isHighlighted ? "list_tr--selected" : "";
    },
    statusId() {
      return this.project?.statusId;
    },
    ...mapGetters("activeIntegrations", {
      hasProjectImportIntegrationEnabled: "hasProjectImportIntegrationEnabled",
    }),
  },
  methods: {
    onStatusGearClick() {
      this.$emit("gear:click");
    },
    onGoToProjectClick() {
      this.$router.push({
        name: this.$routeNames.PROJECT.VIEW,
        params: { projectId: this.project.id },
      });
    },
    onMenuClick(event) {
      this.$emit("menu:click", { project: this.project, event });
    },
    onProjectUpdate(body) {
      this.$emit("project:update", { project: this.project, body });
    },
  },
};
</script>
