import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  stats: [],
  isLoading: false,
});

const getters = {
  countByStatus: (state) => (status) => {
    return state.stats.find((task) => task.status === status)?.count || 0;
  },
  statsCountNotDeletedOrInactive: (state, getters) => {
    const activeCount = getters.countByStatus("ACTIVE");
    const invitedCount = getters.countByStatus("INVITED");
    const importedCount = getters.countByStatus("IMPORTED");

    const total = activeCount + invitedCount + importedCount;
    return total;
  },
};

const actions = {
  async getStats({ commit }) {
    commit("loading", true);

    return axios
      .get(`${apiUrl}statistics/user`)
      .then(({ data }) => {
        const stats = data?.statistics || [];
        commit("setStats", stats);
        commit("loading", false);
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
};

const mutations = {
  setStats(state, stats) {
    state.stats = stats;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const userStats = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
