<template>
  <div class="p-relative">
    <AppDefaultTooltip v-if="showBtn" top :disabled="!tooltip">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="p-absolute"
          style="right: 0; bottom: 2px"
          v-bind="{ ...attrs, ...$attrs }"
          v-on="{ ...on, ...$listeners }"
          icon
          small
        >
          <v-icon>{{ $icon.LIGHT.ACTION.CONFIGURE }}</v-icon>
        </v-btn>
      </template>
      <span class="break-word">{{ tooltip }}</span>
    </AppDefaultTooltip>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { statusMixins } from "@/helpers/mixins";
export default {
  mixins: [statusMixins],
  props: {
    tooltip: String,
  },
  computed: {
    ...mapGetters("auth", {
      isDomainOwner: "isDomainOwner",
      isDomainAdmin: "isDomainAdmin",
    }),
    showBtn() {
      return this.isDomainOwner || this.isDomainAdmin;
    },
  },
};
</script>
