<template>
  <tr class="list_tr" :class="highlightedClass" @contextmenu="onMenuClick">
    <td v-if="groupByMixins_showColumn(filtersMixins_dataFields.STATUS)">
      <AppCustomStatusIcon
        @click="(e) => $emit('status:click', { ...e, selectedItem: form })"
        model="form"
        :statusId="statusId"
        :loading="form?.isUpdatingStatus"
        :disabled="form?.isUpdatingStatus"
      />
    </td>
    <td>
      <div
        class="underline-on-hover cursor-pointer table-cell-content__breakable-long-text"
        @click="onFormFillOutClick()"
      >
        {{ form.name }}
      </div>
    </td>
    <td v-if="groupByMixins_showColumn(filtersMixins_dataFields.SIGNATURE_STATUS)">
      <AppFormSignatureSessionStatusChip
        :signatureSession="form.signatureSession"
        @click="$emit('signature:click', { form })"
      />
    </td>
    <td v-if="groupByMixins_showColumn(filtersMixins_dataFields.PROGRESS)">
      <AppFormProgressionChip :progress="form.progress" />
    </td>
    <td v-if="groupByMixins_showColumn(filtersMixins_dataFields.RESPONSIBLE)">
      <template v-if="form.member">
        <AppUserAvatar :user="form.member" show-name :truncate="20" />
      </template>
    </td>
    <td
      v-if="
        !hideProjectColumn && groupByMixins_showColumn(filtersMixins_dataFields.PROJECT)
      "
    >
      <div v-if="projectId" class="table-cell-content__breakable-long-text">
        <AppActionText
          :to="{
            name: $routeNames.PROJECT.VIEW,
            params: { projectId },
          }"
        >
          {{ form.project.name }}
        </AppActionText>
      </div>
    </td>
    <td v-if="groupByMixins_showColumn(filtersMixins_dataFields.TASK)">
      <div v-if="taskId" class="table-cell-content__breakable-long-text">
        <AppActionText
          :to="{
            name: $routeNames.TASK_VIEW.ROOT,
            params: { taskId },
          }"
        >
          {{ taskName }}
        </AppActionText>
      </div>
    </td>
    <td v-if="groupByMixins_showColumn(filtersMixins_dataFields.CATEGORY)">
      <AppColorChip
        v-if="form.category"
        :text="
          $te(`formManagement.category.${form.category.name}`)
            ? $t(`formManagement.category.${form.category.name}`)
            : form.category.name
        "
        color="info--text"
      />
    </td>
    <td v-if="groupByMixins_showColumn(filtersMixins_dataFields.DUE_DATE)">
      <template v-if="form.dueDate">
        <div>{{ formatDueDate(form.dueDate) }}</div>
      </template>
    </td>
    <td>
      <v-btn icon @click="onMenuClick">
        <v-icon>{{ $icon.REGULAR.ACTION.MENU }}</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import moment from "moment";
import { groupByMixins } from "@/helpers/mixins";

export default {
  mixins: [groupByMixins],
  props: {
    form: Object,
    selectedFormId: String,
    hideProjectColumn: Boolean,
    isHighlighted: Boolean,
    tableModel: String,
    headers: Array,
  },
  computed: {
    taskId() {
      return this.form?.task?.id;
    },
    projectId() {
      return this.form?.project?.id;
    },
    taskName() {
      return this.form?.task?.name;
    },
    highlightedClass() {
      return this.isHighlighted ? "list_tr--selected" : "";
    },
    statusId() {
      return this.form?.statusId;
    },
  },
  methods: {
    onMenuClick(event) {
      this.$emit("menu:click", { form: this.form, event });
    },
    formatDueDate(date) {
      return date ? moment(date).format("DD.MM.YYYY") : "";
    },
    onFormUpdate(body) {
      this.$emit("form:update", { form: this.form, body });
    },
    onFormNameChange(e) {
      const name = e.target.value ? e.target.value.trim() : null;
      this.onFormUpdate({ name });
    },
    onFormFillOutClick() {
      this.$emit("name:click", { form: this.form });
    },
    onFormDelete() {
      this.$emit("form:delete", { form: this.form });
    },
  },
};
</script>
