<template>
  <AppGroupByBtn :tableModel="tableModel" :menuItems="menuItems" />
</template>

<script>
import { groupByMixins } from "@/helpers/mixins";
export default {
  mixins: [groupByMixins],
  props: {
    tableModel: String,
  },
  computed: {
    menuItems() {
      return [
        {
          text: this.$options.filters.capitalize(this.$t("common.noGroupText")),
          icon: this.$icon.REGULAR.ACTION.EMPTY,
          isSelected: this.groupByMixins_isGroupSelected(undefined),
          click: () => this.groupByMixins_setGroupBy(undefined),
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.status")),
          icon: this.$icon.REGULAR.COMMON.SQUARE,
          isSelected: this.groupByMixins_isGroupSelected(
            this.filtersMixins_dataFields.STATUS,
          ),
          click: () =>
            this.groupByMixins_setGroupBy(this.filtersMixins_dataFields.STATUS),
        },
        {
          text: this.$options.filters.capitalize(this.$t("project.list.customer")),
          icon: this.$icon.REGULAR.ACTION.CUSTOMER_SUPPLIER,
          isSelected: this.groupByMixins_isGroupSelected(
            this.filtersMixins_dataFields.CLIENT,
          ),
          click: () =>
            this.groupByMixins_setGroupBy(this.filtersMixins_dataFields.CLIENT),
        },
        {
          text: this.$options.filters.capitalize(this.$t("project.list.startDate")),
          icon: this.$icon.REGULAR.ACTION.START_DATE,
          isSelected: this.groupByMixins_isGroupSelected(
            this.filtersMixins_dataFields.START_DATE,
          ),
          click: () =>
            this.groupByMixins_setGroupBy(this.filtersMixins_dataFields.START_DATE),
        },
      ];
    },
  },
};
</script>
