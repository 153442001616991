<template>
  <v-dialog v-model="localDialog" max-width="600px" scrollable>
    <v-card>
      <v-card-title>
        <div class="d-flex flex-column w-100">
          <div class="d-flex flex-row align-center">
            <span>{{ title }}</span>
            <v-spacer></v-spacer>
            <v-btn @click="localDialog = false" icon>
              <v-icon>
                {{ $icon.REGULAR.ACTION.CLOSE }}
              </v-icon>
            </v-btn>
          </div>
          <div class="d-flex flex-row align-center">
            <AppDefaultTextField
              class="pt-2 pr-6"
              hide-details
              autofocus
              v-model="search"
              :append-icon="$icon.LIGHT.ACTION.SEARCH"
              :label="$t('common.search')"
              clearable
            />
            <AppDefaultSwitch
              class="pa-0 ma-0"
              v-model="showItemContent"
              :label="$t('defaultAnswer.showAnswer')"
            />
          </div>
        </div>
      </v-card-title>
      <v-card-text class="primary-text--text" ref="cardText">
        <div style="height: 600px">
          <draggable
            v-model="localDefaultValueTemplates"
            draggable=".draggable-item"
            filter=".block-draggable"
            :forceFallback="true"
            :ghostClass="$constant.DRAG_SETTINGS.GHOST_CLASS_DASHED_OUTLINE"
            :dragClass="$constant.DRAG_SETTINGS.DRAG_CLASS"
            :animation="$constant.DRAG_SETTINGS.ANIMATION_DURATION"
            :emptyInsertThreshold="20"
            :preventOnFilter="false"
            :disabled="hasSearched"
            class="pt-1"
            :fallbackTolerance="4"
            :class="`
              ${
                showItemContent
                  ? 'draggable-container-gap-8'
                  : 'draggable-container-gap-4'
              }
              ${showItemContent ? 'mb-2' : 'mb-1'}
            `"
          >
            <div
              v-for="(defaultValueTemplate, index) in filteredDefaultValueTemplates"
              :key="defaultValueTemplate.id"
              class="draggable-item"
            >
              <AppEditDefaultValueTemplateItem
                :ref="`draggableEditableItem-${index}`"
                :item="defaultValueTemplate"
                :name="defaultValueTemplate.name"
                :labelName="defaultValueTemplate.labelName"
                :labelColor="defaultValueTemplate.labelColor"
                :value="defaultValueTemplate.value"
                :predefinedColors="predefinedColors"
                @delete:click="onDeleteClick({ defaultValueTemplate })"
                @data:update="
                  (updatedField) =>
                    updateDefaultValueTemplate({
                      defaultValueTemplate: { ...defaultValueTemplate, ...updatedField },
                    })
                "
                :hasSearched="hasSearched"
                :showItemContent="showItemContent"
                @showItemContent:change="showItemContent = $event"
              />
            </div>
          </draggable>
          <AppDefaultTextField
            ref="createDefaultValueTemplateInput"
            v-if="isCreateActive"
            @blur="onCreateDefaultValueTemplate"
            @keypress.enter="$refs.createDefaultValueTemplateInput.blur()"
            backgroundColor="ui-background"
            v-model="newDefaultValueTemplateName"
            autofocus
            hide-details
          />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn block depressed color="primary" @click="isCreateActive = true">{{
          $t("common.create")
        }}</v-btn>
      </v-card-actions>
    </v-card>
    <AppDeleteConfirmationDialog
      v-model="deleteConfirmationDialog.active"
      :title="$t('answerTemplates.delete.title')"
      :subtitle="$t('answerTemplates.delete.subTitle')"
      :validator="$t('common.delete').toLowerCase()"
      :validatorText="
        $t('answerTemplates.delete.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      @delete="deleteDefaultValueTemplate"
    />
  </v-dialog>
</template>
<script>
import Draggable from "vuedraggable";
import { mapGetters } from "vuex";

export default {
  props: {
    title: String,
    dialog: Boolean,
    isCreating: Boolean,
    model: String,
    categoryId: String,
  },
  components: {
    Draggable,
  },
  watch: {
    dialog: {
      handler(val) {
        if (!val) return;
        if (!this.hasFetched) {
          this.fetchDefaultValueTemplates();
        }
      },
    },
  },
  data() {
    return {
      search: "",
      isCreateActive: false,
      newDefaultValueTemplateName: "",
      showColorMenu: false,
      deleteConfirmationDialog: {
        active: false,
        value: null,
      },
      showItemContent: true,
      predefinedColors: [
        "#5F55EE",
        "#4466FF",
        "#1090E9",
        "#0F9D9F",
        "#3DB88B",
        "#008844",
        "#F8AE00",
        "#E16B16",
        "#D33D44",
        "#EE5E99",
        "#B660E0",
        "#AA8D80",
        "#656F7D",
        "#87909E",
      ],
    };
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    localDefaultValueTemplates: {
      get() {
        return this.defaultValueTemplates;
      },
      set(value) {
        this.onDefaultValueTemplatesChange(value);
      },
    },
    hasSearched() {
      return this.search?.trim() !== "";
    },
    filteredDefaultValueTemplates() {
      return this.localDefaultValueTemplates.filter((defaultValueTemplate) => {
        const searchLower = this.search?.toLowerCase() ?? "";
        return (
          defaultValueTemplate?.name?.toLowerCase().includes(searchLower) ||
          defaultValueTemplate?.labelName?.toLowerCase().includes(searchLower) ||
          defaultValueTemplate?.value?.toLowerCase().includes(searchLower)
        );
      });
    },
    defaultValueTemplates() {
      return this.getDefaultValueTemplates({ ...this.queryParams });
    },
    hasFetched() {
      return this.hasFetchedDefaultValueTemplates({
        ...this.queryParams,
      });
    },
    queryParams() {
      return {
        model: this.model,
        categoryId: this.categoryId,
      };
    },
    ...mapGetters("defaultValueTemplates", {
      getDefaultValueTemplates: "getDefaultValueTemplates",
      hasFetchedDefaultValueTemplates: "hasFetchedDefaultValueTemplates",
    }),
  },

  methods: {
    onDeleteClick({ defaultValueTemplate }) {
      this.deleteConfirmationDialog.value = defaultValueTemplate;
      this.deleteConfirmationDialog.active = true;
    },
    onDefaultValueTemplatesCreateEnd() {
      this.newDefaultValueTemplateName = "";
      this.isCreateActive = false;
    },
    randomColor() {
      const randomIndex = Math.floor(Math.random() * this.predefinedColors.length);
      return this.predefinedColors[randomIndex];
    },
    onCreateDefaultValueTemplate() {
      if (this.newDefaultValueTemplateName.trim()) {
        this.createDefaultValueTemplate();
        this.$nextTick(() => {
          const cardTextElement = this.$refs.cardText;
          if (cardTextElement) {
            cardTextElement.scrollTo({
              top: cardTextElement.scrollHeight,
              behavior: "smooth",
            });
          }
          this.focusLabelInput();
        });
      }
      this.onDefaultValueTemplatesCreateEnd();
    },
    onDefaultValueTemplatesChange(event) {
      this.$store
        .dispatch("defaultValueTemplates/updateDefaultValueTemplates", {
          ...this.queryParams,
          defaultValueTemplates: event,
        })
        .then(() => {});
    },
    updateDefaultValueTemplate({ defaultValueTemplate }) {
      this.$store
        .dispatch("defaultValueTemplates/updateDefaultValueTemplate", {
          ...this.queryParams,
          defaultValueTemplate,
        })
        .then(() => {});
    },
    createDefaultValueTemplate() {
      this.$store
        .dispatch("defaultValueTemplates/createDefaultValueTemplate", {
          defaultValueTemplate: {
            name: this.newDefaultValueTemplateName,
            labelColor: this.randomColor(),
            ...this.queryParams,
          },
        })
        .then(() => {
          this.focusLabelInput();
        });
    },
    focusLabelInput() {
      this.$nextTick(() => {
        const newComponent =
          this.$refs[
            `draggableEditableItem-${this.filteredDefaultValueTemplates.length - 1}`
          ];
        newComponent[0].focusLabelInput();
      });
    },
    deleteDefaultValueTemplate() {
      this.$store.dispatch("defaultValueTemplates/deleteDefaultValueTemplate", {
        defaultValueTemplate: this.deleteConfirmationDialog.value,
        ...this.queryParams,
      });
    },
    fetchDefaultValueTemplates() {
      this.$store
        .dispatch("defaultValueTemplates/fetchDefaultValueTemplates", {
          ...this.queryParams,
        })
        .then(() => {});
    },
  },
};
</script>
