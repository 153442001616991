<template>
  <div class="d-flex align-center justify-center rounded background" :style="iconStyle">
    <AppCircularLoader v-if="loading" :size="loaderSize" />
    <slot v-else-if="$scopedSlots.content" name="content"></slot>
    <StorageIcon
      v-else-if="!isImage"
      :storage="storageRepresentation"
      :large="large"
      :small="small"
      :disabled="disabled"
      :loading="loading"
    />
    <v-img
      v-else-if="isImage"
      :src="imagePreview"
      class="rounded"
      :max-height="iconSize"
      :max-width="iconSize"
      contain
    />
    <v-icon v-else>{{ $icon.LIGHT.COMMON.QUESTION }}</v-icon>
  </div>
</template>

<script>
import { getFileContent } from "@/services/storage/storageService";
import StorageIcon from "./StorageIcon.vue";

export default {
  components: {
    StorageIcon,
  },
  props: {
    file: {
      type: File,
      default: null,
    },
    storage: {
      type: Object,
      default: null,
    },
    disabled: Boolean,
    loading: Boolean,
    large: Boolean,
    small: Boolean,
  },
  data() {
    return {
      thumbnailUrl: null,
    };
  },
  computed: {
    isFile() {
      return this.file instanceof File;
    },
    isStorage() {
      return this.storage && typeof this.storage === "object";
    },
    storageRepresentation() {
      return this.isFile ? { mime: this.file.type } : this.storage;
    },
    isImage() {
      const mimeType = this.isFile ? this.file.type : this.storage?.mime;
      return mimeType?.startsWith("image/") || false;
    },
    imagePreview() {
      if (this.isFile) {
        return this.isImage ? URL.createObjectURL(this.file) : null;
      } else if (this.isStorage) {
        return this.thumbnailUrl;
      }
      return null;
    },
    iconSize() {
      if (this.small) return 30;
      if (this.large) return 60;
      return 40;
    },
    loaderSize() {
      if (this.small) return 18;
      if (this.large) return 32;
      return 24;
    },
    iconStyle() {
      return {
        width: `${this.iconSize}px`,
        height: `${this.iconSize}px`,
        minWidth: `${this.iconSize}px`,
      };
    },
  },
  watch: {
    storage: {
      immediate: true,
      handler(newStorage) {
        if (this.isStorage && this.isImage) {
          this.fetchThumbnail();
        }
      },
    },
  },
  methods: {
    async fetchThumbnail() {
      const url = await getFileContent(this.storage.id, { height: this.iconSize * 2 });
      this.thumbnailUrl = url;
    },
  },
  beforeDestroy() {
    if (this.isFile && this.imagePreview) {
      URL.revokeObjectURL(this.imagePreview);
    }
    if (this.isStorage && this.thumbnailUrl) {
      URL.revokeObjectURL(this.thumbnailUrl);
    }
  },
};
</script>
