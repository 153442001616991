<template>
  <AppMenuItem
    v-bind="$attrs"
    v-on="$listeners"
    :text="$t('common.sendToAccounting')"
    :icon="$icon.REGULAR.FILE.FILE_INVOICE"
  />
</template>

<script>
export default {};
</script>
