<template>
  <div>
    <div class="mb-4">
      <v-simple-table class="border-a" fixed-header height="400px">
        <template v-slot:default>
          <tbody class="border-radius-0">
            <template v-if="isLoading">
              <v-progress-linear indeterminate rounded height="6" />
            </template>
            <template v-else-if="hasError">
              Something went wrong, please try again
            </template>
            <template v-else>
              <tr
                v-for="(address, i) in addresses"
                :key="i"
                class="cursor-pointer"
                :class="
                  address.id
                    ? address.id === selectedAddressId
                      ? 'list_tr--selected'
                      : ''
                    : ''
                "
                @click="selectAddress(address)"
              >
                <td class="border-radius-0">
                  {{ addressText(address) }}
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <v-btn color="primary" @click="onNextStep" :disabled="!selectedAddressId">
      {{ $t("integration.boligmappa.plantWizard.nextStep") }}
    </v-btn>
    <v-btn text @click="goBack">
      {{ $t("common.back") }}
    </v-btn>
    <v-btn text @click="onCancel">
      {{ $t("common.cancel") }}
    </v-btn>
  </div>
</template>

<script>
import { getAddresses } from "../../../../../../services/integration/boligmappa/BoligmappaPropertyWizardService";

export default {
  props: {
    streetId: String,
  },
  data() {
    return {
      addresses: [],
      isLoading: false,
      hasError: false,

      selectedAddressId: null,
    };
  },
  watch: {
    streetId: {
      handler(streetId) {
        this.selectedAddressId = null;
        if (streetId) {
          this.getAddresses(streetId);
        }
      },
    },
    immediate: true,
  },
  methods: {
    getAddresses(id) {
      this.hasError = false;
      this.isLoading = true;
      getAddresses(id)
        .then(({ response }) => {
          this.addresses = response;
        })
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
    addressText(address) {
      const streetName = address.streetName ? address.streetName : "";
      const houseNumber = address.houseNumber ? address.houseNumber : "";
      const houseSubNumber = address.houseSubNumber ? address.houseSubNumber : "";
      const postalCode = address.postalCode ? address.postalCode : "";
      const postalPlace = address.postalPlace ? address.postalPlace : "";
      return `${streetName} ${houseNumber} ${houseSubNumber}, ${postalCode} ${postalPlace}`;
    },
    selectAddress(address) {
      this.selectedAddressId = address.id;
    },
    onNextStep() {
      this.$emit("select", { selectedAddressId: this.selectedAddressId });
    },
    goBack() {
      this.$emit("back");
    },
    onCancel() {
      this.$emit("cancel");
    },
  },
  created() {},
};
</script>
