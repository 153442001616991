<script>
import { VBtn, VIcon, VImg, VSkeletonLoader } from "vuetify/lib";

export default {
  props: {
    item: Object,
  },
  data() {
    return {
      images: [],
    };
  },
  methods: {
    genSkeletonLoader() {
      const elms = [];
      if (Array.isArray(this.item.images)) {
        for (let img of this.item.images) {
          elms.push(
            this.$createElement(
              "div",
              { class: "form-item__image_skeleton_container__completion" },
              [
                this.$createElement(VSkeletonLoader, {
                  props: {
                    type: "image",
                  },
                }),
              ],
            ),
          );
        }
      }
      return elms;
    },
    genImages() {
      if (this.item.imagesLoading) return this.genSkeletonLoader();
      const elms = [];
      if (Array.isArray(this.item.images)) {
        for (let image of this.item.images) {
          elms.push(
            this.$createElement(
              "div",
              {
                class:
                  "form-item__image_container__completion border-a d-flex align-center justify-center cursor-pointer",
                on: {
                  click: (e) =>
                    this.$emit("item:imagePreview", {
                      item: this.item,
                      imageId: image.id,
                      imageIds: this.item.images.map((image) => {
                        return { id: image.id };
                      }),
                    }),
                },
              },
              [
                this.$createElement("AppImageDeleteBtn", {
                  on: {
                    click: (e) => {
                      e.stopPropagation();
                      this.$emit("item:imageDelete", {
                        item: this.item,
                        id: image.id,
                      });
                    },
                  },
                }),
                this.$createElement(VImg, {
                  props: { src: image.url, contain: true },
                  class: "form-item__image",
                }),
              ],
            ),
          );
        }
      }
      return elms;
    },
  },
  render(h) {
    return h("div", { class: "py-2" }, [
      h(
        "div",
        { class: "text-body-2 font-weight-bold pb-1" },
        this.$t("formManagement.preview.images"),
      ),
      h(
        "div",
        { class: "form-item__images d-flex flex-wrap w-100", style: { gap: "8px" } },
        [this.genImages()],
      ),
    ]);
  },
};
</script>
