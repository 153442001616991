<template>
  <v-menu
    ref="menu"
    v-model="menu.value"
    :position-x="menu.x"
    :position-y="menu.y"
    absolute
    offset-y
    :closeOnContentClick="closeOnContentClick"
    transition="custom-menu-transition"
    v-bind="$attrs"
  >
    <div
      class="d-flex flex-column"
      :class="`${hideDefaultPadding ? '' : 'pa-2'} ${backgroundColor}`"
      style="min-width: 70px"
    >
      <slot></slot>
    </div>
  </v-menu>
</template>

<script>
export default {
  props: {
    hideDefaultPadding: Boolean,
    backgroundColor: {
      type: String,
      default: "ui-background",
    },
    closeOnContentClick: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      menu: {
        value: false,
        x: 0,
        y: 0,
      },
    };
  },
  watch: {
    "menu.value"(newValue) {
      this.$emit("input", newValue);
    },
  },
  methods: {
    open({ pointerEvent, x, y }) {
      pointerEvent.preventDefault();

      const { x: pointerX, y: pointerY, clientX, clientY } = pointerEvent;
      this.menu.x = x ?? pointerX ?? clientX;
      this.menu.y = y ?? pointerY ?? clientY;

      if (this.menu.value) {
        this.menu.value = false;
        setTimeout(() => {
          this.menu.value = true;
        }, 10);
      } else {
        this.menu.value = true;
      }
    },
    close() {
      this.menu.value = false;
      return { x: this.menu.x, y: this.menu.y };
    },
    updateDimensions() {
      this.$refs.menu.updateDimensions();
    },
  },
};
</script>
