<template>
  <AppContextMenu
    ref="statusMenu"
    :disabled="disabled"
    :closeOnClick="!editStatusDialog"
    max-height="600px"
    min-width="200px"
  >
    <div class="pa-1 ui-background d-flex flex-column" style="min-width: 70px">
      <AppChangeCustomStatusMenuItems
        :model="model"
        :statusId="statusId"
        @status:change="onStatusChange"
        @gear:click="onGearClick"
      />
      <AppEditCustomStatusDialog v-model="editStatusDialog" :model="model" />
    </div>
  </AppContextMenu>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  props: {
    model: String,
    disabled: Boolean,
  },
  data() {
    return {
      editStatusDialog: false,
      statusId: null,
      selectedItem: null,
    };
  },
  watch: {
    statusesCount: {
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.$refs.statusMenu.updateDimensions();
        }
      },
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    ...mapGetters("statuses", {
      getStatusByModelAndId: "getStatusByModelAndId",
      getStatusesByModel: "getStatusesByModel",
      hasFetchedStatuses: "hasFetchedStatuses",
    }),
    statuses() {
      return this.getStatusesByModel(this.model);
    },
    status() {
      return this.getStatusByModelAndId({ model: this.model, id: this.statusId });
    },
    statusesCount() {
      if (!this.statuses) return;
      return Object.values(this.statuses).reduce(
        (total, statusArray) => total + statusArray.length,
        0,
      );
    },
  },
  methods: {
    onStatusChange(statusId) {
      this.$emit("status:change", { statusId, selectedItem: this.selectedItem });
    },
    onGearClick() {
      this.editStatusDialog = true;
    },
    getStatuses() {
      this.$store.dispatch("statuses/getStatuses", { model: this.model });
    },
    open({ pointerEvent, x, y, data }) {
      this.setData(data);
      this._open({ pointerEvent, x, y });
    },
    _open({ pointerEvent, x, y }) {
      this.$refs.statusMenu.open({ pointerEvent, x, y });
    },
    setData(data) {
      const { statusId, selectedItem } = data;
      this.statusId = statusId;
      this.selectedItem = selectedItem;
    },
  },
  mounted() {
    if (!this.hasFetchedStatuses(this.model)) {
      this.getStatuses();
    }
  },
};
</script>
