<template>
  <div>
    <div class="pb-4 d-flex align-center">
      <div class="d-flex align-center">
        <AppDefaultTextField
          class="mw-250px mr-4"
          :label="$t('common.search')"
          :append-icon="$icon.LIGHT.ACTION.SEARCH"
          :value="filtersMixins_filterByKey('search')"
          @input="filtersMixins_debounceSearch"
          hide-details
          autofocus
          background-color="white"
          clearable
        />
        <AppDefaultSwitch
          class="pa-0 ma-0 mr-2"
          :label="$t('dataTable.enableStickyColumns')"
          v-model="filtersMixins_stickyCols"
        />
      </div>
    </div>
    <AppContactTable
      :contacts="contacts"
      :isLoading="isLoading || filtersMixins_isLoadingFilters"
      :tableModel="tableModel"
      :serverItemsLength="serverItemsLength"
      @contact:delete="onContactDelete"
      @contact:update="onContactUpdate"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { filtersMixins } from "@/helpers/mixins";

export default {
  mixins: [filtersMixins],
  props: {
    tableModel: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showDrawer: false,
      searchDebounce: null,
    };
  },
  computed: {
    ...mapState("contacts", {
      contacts: (state) => state.contacts,
      serverItemsLength: (state) => state.count,
      isLoading: (state) => state.isLoading,
    }),
  },
  watch: {
    filtersMixins_filters: {
      handler() {
        this.getContacts();
      },
      deep: true,
    },
  },
  methods: {
    getContacts() {
      this.$emit("data:get");
    },
    onContactUpdate({ id, body, snack = false }) {
      this.$store.dispatch("contacts/updateContact", { id, body }).then(() => {
        if (snack) {
          this.$store.dispatch("snackbar/snackbar", {
            show: true,
            text: this.$t("common.updateSuccessful"),
            color: "success",
          });
        }
      });
    },
    onContactDelete({ item }) {
      this.$store.dispatch("contacts/deleteContact", { ids: [item.id] });
    },
  },
  mounted() {
    this.filtersMixins_getFilters();
  },
};
</script>
