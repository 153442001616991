<template>
  <div class="information_drawer">
    <AppConfirmationDialog
      v-model="dialog"
      :title="$t('fileManagement.preview.navMenu.details.confirmationDialog.title')"
      :description="
        $t('fileManagement.preview.navMenu.details.confirmationDialog.description')
      "
      @dialog:confirm="createShareLink"
    />
    <v-toolbar class="border-b" dense flat fixed height="68">
      <v-toolbar-title class="font-weight-bold">{{
        $t("common.overview")
      }}</v-toolbar-title>
    </v-toolbar>
    <AppCenterLoader
      v-model="isLoading"
      :title="$t('common.loading')"
      :height="'calc(100% - 68px)'"
    />
    <div class="pa-5 pb-0 overflow-y-auto secondary-height" v-if="!isLoading && item">
      <v-row v-if="item.user">
        <AppStorageDetailsDrawerItem :title="item.user.name">
          <template #icon>
            <AppUserAvatar :user="item.user" :truncate="27" />
          </template>
        </AppStorageDetailsDrawerItem>
      </v-row>
      <v-row>
        <AppStorageDetailsDrawerItem
          :icon="$icon.LIGHT.COMMON.CLOCK"
          :title="new Date(item.createdAt) | dateFormat('DD.MM.YYYY HH:mm')"
        />
      </v-row>
      <v-row v-if="item.byteSize">
        <AppStorageDetailsDrawerItem
          :icon="storageMixins_getStorageIconName(item)"
          :title="item.byteSize"
        />
      </v-row>
      <v-row v-if="item.type === $constant.FILE_RELATION">
        <AppStorageDetailsDrawerItem
          :icon="$icon.LIGHT.FILE.FILE_LINKED"
          :title="$t('fileManagement.linkedFile.helper')"
        >
          <template #append-item>
            <AppDefaultTooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small class="pl-2 cursor-pointer">
                  {{ $icon.LIGHT.COMMON.CIRCLE_QUESTION }}
                </v-icon>
              </template>
              <span
                style="font-size: 13px"
                v-html="$t('fileManagement.linkedFile.tooltipShort')"
              ></span>
            </AppDefaultTooltip>
          </template>
        </AppStorageDetailsDrawerItem>
        <AppStorageDetailsDrawerItem
          v-if="item.originalStorage"
          :to="{
            name: 'storageAllFiles',
            query: {
              fileId: item.originalStorage.id,
            },
            params: {
              folderId: item.originalStorage.parentId,
              rootId: activeDomainId,
            },
          }"
          :icon="$icon.LIGHT.ACTION.POP_OUT"
          color="primary-link"
          :title="$t('fileManagement.linkedFile.goToOriginalFile')"
        />
      </v-row>

      <template v-for="entityKey in relatedEntityKeys">
        <v-row :key="entityKey" v-if="storageRelatedEntities[entityKey]">
          <AppStorageDetailsDrawerItem
            :icon="getRelatedEntityIcon(entityKey)"
            :title="getRelatedEntityText(entityKey)"
            :to="getReleatedEntityTo(entityKey)"
            color="primary-link"
          />
        </v-row>
      </template>

      <v-row>
        <v-col class="d-flex flex-column">
          <span>{{ $t("fileManagement.preview.navMenu.details.fileType") }}</span>
          <span class="font-weight-bold">{{ item.extension }}</span>
        </v-col>
        <v-col class="d-flex flex-column">
          <span>{{ $t("fileManagement.preview.navMenu.details.updated") }}</span>
          <span class="font-weight-bold">{{
            new Date(item.updatedAt) | dateFormat("DD MMM YYYY")
          }}</span>
        </v-col>
      </v-row>

      <v-row v-if="address">
        <v-col class="d-flex flex-column">
          <span>
            {{ $t("fileManagement.preview.navMenu.details.imageLocation") }}
          </span>
          <AppLinkText :link="googleMapsAddressLink" underline-on-hover>
            {{ address }}
          </AppLinkText>
        </v-col>
      </v-row>

      <v-row class="pb-5" v-if="item.number">
        <v-col class="d-flex flex-column">
          <span>{{ $t("fileManagement.preview.navMenu.details.fileId") }}</span>
          <span>{{ item.number }}</span>
        </v-col>
      </v-row>
      <template v-if="item.type === 'FILE'">
        <div class="pb-2 p-relative">
          <div class="label--small">
            {{ $t("fileManagement.preview.navMenu.details.permalink") }}
          </div>
          <AppDefaultTextField
            readonly
            hide-details
            v-model="share.link"
            :append-icon="
              share.hasCopied ? $icon.LIGHT.ACTION.ACCEPT : $icon.LIGHT.FILE.COPY
            "
            @click:append="copyShareLink"
            :loading="share.isLoading"
          />
        </div>
        <div class="pb-5 d-flex justify-end">
          <v-btn
            class="text-transform-none text-body-1"
            text
            color="primary"
            @click="onCreateShareLink"
            :loading="share.isCreating"
          >
            {{ $t("fileManagement.preview.navMenu.details.createNewLink") }}
          </v-btn>
        </div>
      </template>

      <v-row>
        <v-col cols="6">
          <v-btn
            color="primary"
            :loading="isDownloading"
            @click="downloadFile(item)"
            depressed
          >
            {{ $t("common.downloadFile") }}
          </v-btn>
        </v-col>
        <v-col cols="6" v-if="item.type === 'FILE'">
          <v-btn
            text
            :loading="isUpdatingFileVersion"
            @click="onFileUpload"
            :disabled="!canUpload"
          >
            {{ $t("fileManagement.preview.navMenu.details.uploadNew") }}
          </v-btn>
          <input
            ref="fileReplaceUploader"
            class="d-none"
            type="file"
            :accept="item.mime"
            @change="replaceFile"
            multiple
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { storageMixins } from "@/helpers/mixins";
import { getGoogleMapsUrl } from "@/helpers/util";
import { getDisplayData } from "@/helpers/util/moduleItem";

export default {
  mixins: [storageMixins],
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    fileId: String,
  },
  computed: {
    ...mapGetters("auth", {
      activeDomainId: "activeDomainId",
    }),
    ...mapGetters("storageDetails", {
      hasShareLink: "hasShareLink",
      canUpload: "canUpload",
    }),
    ...mapState("storageDetails", {
      item: (state) => state.item,
      storageRelatedEntities: (state) => state.storageRelatedEntities,
      parent: (state) => state.parent.item,
      share: (state) => state.share,
      isLoading: (state) => state.isLoading,
      isParentLoading: (state) => state.parent.isLoading,
      isDownloading: (state) => state.isDownloading,
      isUpdatingFileVersion: (state) => state.isUpdatingFileVersion,
    }),
    address() {
      return (this.item?.meta || []).find(
        (meta) => meta.field === "GPS" && meta.name === "ADDRESS",
      )?.value;
    },
    googleMapsAddressLink() {
      return getGoogleMapsUrl(this.address);
    },
    relatedEntityKeys() {
      const order = ["formItem", "form", "task", "project", "parentFolder"];

      const filteredKeys = Array.from(this.$constant.MODULE_ITEM_CONFIG.keys()).filter(
        (key) => this.storageRelatedEntities && this.storageRelatedEntities[key],
      );

      return filteredKeys.sort((a, b) => order.indexOf(a) - order.indexOf(b));
    },
  },
  watch: {
    fileId: {
      handler(fileId) {
        if (fileId) {
          this.getDetails(fileId);
        }
      },
    },
  },
  methods: {
    getDetails(id) {
      this.$store.dispatch("storageDetails/getDetails", id);
      this.$store.dispatch("storageDetails/getShareLink", id);
      this.$store.dispatch("storageDetails/getStorageRelatedEntities", {
        storageId: id,
      });
    },
    downloadFile(file) {
      this.$store.dispatch("storageDetails/download", [file.id]);
    },
    copyShareLink() {
      this.$store.commit("storageDetails/saredHasCopied", true);
      navigator.clipboard.writeText(this.share.link);
      setTimeout(() => {
        this.$store.commit("storageDetails/saredHasCopied", false);
      }, 1000);
    },
    onCreateShareLink() {
      if (this.hasShareLink) {
        this.dialog = true;
      } else {
        this.createShareLink();
      }
    },
    onFileUpload() {
      this.$refs.fileReplaceUploader.click();
    },
    async replaceFile(e) {
      if (!this.canUpload) return;
      const file = e.target.files[0] ? e.target.files[0] : e.dataTransfer.files[0];
      if (this.item.mime !== file.type) {
        this.$store.dispatch("snackbar/snackbar", {
          show: true,
          text: "Invalid file type",
          color: "error",
        });
      }

      const formData = new FormData();
      formData.append("file", file);

      await this.$store.dispatch("storageDetails/replaceFile", {
        id: this.fileId,
        formData,
      });
      this.$refs.fileReplaceUploader.value = null;
      this.getDetails(this.fileId);
      this.$emit("preview:update", { fileId: this.fileId });
    },
    createShareLink() {
      this.$store.dispatch("storageDetails/createShareLink", this.fileId);
    },
    getPreview(id) {
      this.$store.dispatch("storagePreview/getPreview", { id });
      this.getDetails(this.fileId);
    },
    getRelatedEntityIcon(key) {
      return this.getRelatedEntityData(key).icon;
    },
    getRelatedEntityText(key) {
      return this.getRelatedEntityData(key).text;
    },
    getReleatedEntityTo(key) {
      return this.getRelatedEntityData(key).to;
    },
    getRelatedEntityData(key) {
      const config = {
        dataKey: key,
        pathToModule: "",
      };
      return getDisplayData(this.storageRelatedEntities[key], config);
    },
  },
  mounted() {
    this.getDetails(this.fileId);
  },
};
</script>
