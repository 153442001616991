<template>
  <v-card flat :disabled="isUpdating" :loading="isLoading" ref="calendarContainer">
    <v-card class="app-calendar_header" flat>
      <div class="d-flex justify-center align-center">
        <v-btn icon class="ma-2" @click="goPrevious" ref="prevMonthButton">
          <v-icon>
            {{ $icon.LIGHT.COMMON.ANGLE_LEFT }}
          </v-icon>
        </v-btn>
        <v-spacer />
        <div class="text-h5 font-weight-bold" v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </div>
        <v-spacer />
        <div class="d-flex align-center p-relative">
          <v-btn icon class="ma-2" @click="goNext">
            <v-icon>
              {{ $icon.LIGHT.COMMON.ANGLE_RIGHT }}
            </v-icon>
          </v-btn>
        </div>
      </div>
    </v-card>
    <v-sheet :height="totalHeight">
      <v-calendar
        class="app-calendar"
        show-week
        locale-first-day-of-year="4"
        v-model="focus"
        ref="calendar"
        :type="calendarType"
        :events="events"
        :locale="$i18n.locale"
        :weekdays="weekdays"
        color="primary--text"
        :start="start"
        :end="end"
        @click:more="openMoreMenu"
        @click:date="onDateClick"
        @change="onChange"
        @click:event="onEventClick($event.event)"
        @mousedown:event="onStartDrag"
        @mousemove:day="onDrag"
        @mouseup:day="onEndDrag"
        @keydown="goNext"
      >
        <template v-slot:event="{ event }">
          <AppCalendarEvent
            :event="event"
            :onlyHasEndDateAndIsBoth="onlyHasEndDateAndIsBoth(event.item)"
          />
        </template>
      </v-calendar>
    </v-sheet>
    <AppShowAllEventsMenu
      v-model="menu.isOpen"
      :events="menu.events"
      :position="menu.position"
      :size="menu.size"
      :isUpdating="isUpdating"
      @event:click="onEventClick"
      @startDrag="onShowAllStartDrag"
      @endDrag="onEndDrag"
    />
  </v-card>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  props: {
    isLoading: Boolean,
    isUpdating: Boolean,
    items: Array,
    startDateKey: String,
    endDateKey: String,
    userKey: String,
    model: String,
    calendarType: String,
    basedOn: String,
    eventName: Function,
  },
  data() {
    return {
      focus: "",
      firstDayOfMonth: "",
      weekdays: [1, 2, 3, 4, 5, 6, 0],
      dragEvent: null,
      totalHeight: "",
      reziseTimeoutId: null,
      events: [],
      menu: {
        selectedDate: null,
        events: [],
        isOpen: false,
        size: {
          width: null,
          height: null,
        },
        position: {
          x: null,
          y: null,
        },
      },
    };
  },
  watch: {
    isLoading: {
      handler() {
        this.updateDataTableHeight();
      },
    },
    items: {
      handler() {
        this.setEvents();
        this.getMoreMenuEvents();
      },
      deep: true,
    },
    basedOn: {
      handler() {
        this.setEvents();
      },
    },
  },
  computed: {
    ...mapGetters("statuses", {
      getStatusByModelAndId: "getStatusByModelAndId",
      hasFetchedStatuses: "hasFetchedStatuses",
    }),
    ...mapGetters("auth", {
      isHighestPriorityRoleContact: "isHighestPriorityRoleContact",
    }),
    start() {
      const date = this.$route.query.start;
      const formattedStart = this.formatDate(date);
      return formattedStart;
    },
    end() {
      const date = this.$route.query.end;
      const formattedEnd = this.formatDate(date);
      return formattedEnd;
    },
  },
  methods: {
    setEvents() {
      this.events = this.getEvents(this.items);
    },
    onlyHasEndDateAndIsBoth(item) {
      return (
        this.basedOn === this.$constant.BASED_ON_TYPES.BOTH &&
        !!item[this.endDateKey] &&
        !item[this.startDateKey]
      );
    },
    getEventDates(item) {
      const startDate = this.getDate(item, this.startDateKey);
      const endDate = this.getDate(item, this.endDateKey);
      let start;
      let end;

      if (this.basedOn === this.$constant.BASED_ON_TYPES.START) {
        start = startDate;
      } else if (this.basedOn === this.$constant.BASED_ON_TYPES.END) {
        start = endDate;
      } else if (this.basedOn === this.$constant.BASED_ON_TYPES.BOTH) {
        if (startDate && endDate) {
          start = startDate;
          end = endDate;
        } else if (startDate) {
          start = startDate;
        }
        //Show on start if only end date and based on both?
        else if (endDate) {
          start = endDate;
        }
      }
      return { start, end };
    },
    getItemsWithDate(items) {
      return items.filter((item) => {
        const { start, end } = this.getEventDates(item);
        return !!start || !!end;
      });
    },
    getEvents(items) {
      const events = this.getItemsWithDate(items).map((item) => {
        const color = this.getColor(item);
        const user = item[this.userKey] || null;
        const name = this.eventName(item);

        const { start, end } = this.getEventDates(item);

        const event = {
          item,
          ...(start ? { start: this.formatDate(start) } : {}),
          ...(end && start ? { end: this.formatDate(end) } : {}),
          name,
          color,
          user,
        };

        return event;
      });

      return events;
    },
    getColor(item) {
      if (!item?.statusId) {
        return "primary-text";
      } else {
        return this.getStatusByModelAndId({ model: this.model, id: item?.statusId })
          .color;
      }
    },
    getDate(item, dateKey) {
      return item[dateKey];
    },
    onChange(e) {
      this.firstDayOfMonth = e.start.date;
      const dateRange = this.dateRange(e);
      this.$emit("calendar:change", dateRange);
    },
    dateRange({ start, end }) {
      return {
        startDate: start.date,
        endDate: end.date,
      };
    },
    onShowAllStartDrag({ event }) {
      if (this.isHighestPriorityRoleContact) {
        return;
      }
      this.events.push(event);
      this.onStartDrag({ event: event, day: { date: event.start } });
    },
    onDateClick(e) {
      if (this.isHighestPriorityRoleContact) {
        return;
      }

      this.focus = this.firstDayOfMonth;
      this.$emit("calendar:dateClick", e);
    },
    openMoreMenu(e) {
      const selectedDate = e.date;
      const events = this.getEventsOnDate(selectedDate);
      const moreMenuProps = this.getMoreMenuProps(e);

      setTimeout(() => {
        this.menu.selectedDate = selectedDate;
        this.menu.events = events;
        this.menu.isOpen = true;
        this.menu.position.x = moreMenuProps.x;
        this.menu.position.y = moreMenuProps.y;
        this.menu.size.width = moreMenuProps.width;
        this.menu.size.height = moreMenuProps.height;
      }, 100);
    },
    getItemsOnDate(date) {
      const itemsOnDate = this.items.filter((item) => {
        const { start, end } = this.getEventDates(item);
        if (this.basedOn === this.$constant.BASED_ON_TYPES.START) {
          return this.formatDate(start) === date;
        } else if (this.basedOn === this.$constant.BASED_ON_TYPES.END) {
          return this.formatDate(end) === date;
        } else if (this.basedOn === this.$constant.BASED_ON_TYPES.BOTH) {
          return this.formatDate(start) === date || this.formatDate(end) === date;
        }
      });
      return itemsOnDate;
    },
    getEventsOnDate(date) {
      const itemsOnDate = this.getItemsOnDate(date);
      const events = this.getEvents(itemsOnDate);
      return events;
    },
    getMoreMenuEvents() {
      const events = this.getEventsOnDate(this.menu.selectedDate);
      this.menu.events = events;
    },
    getMoreMenuProps(e) {
      const moreBtn = e.nativeEvent.target;
      const calendarDay = moreBtn.closest(".v-calendar-weekly__day");
      let { x, y } = calendarDay.getBoundingClientRect();
      const width = calendarDay.offsetWidth;
      const height = calendarDay.offsetHeight * 1.1;

      const offsetYAxisHeight = height * 0.23;
      y += offsetYAxisHeight;

      const position = { x, y, width, height };
      return position;
    },
    formatDate(date) {
      if (!date || !moment(date).isValid()) return;
      return moment(date).format("YYYY-MM-DD");
    },
    onStartDrag(e) {
      if (this.isHighestPriorityRoleContact) {
        return;
      }
      const event = e.event;
      if (event) {
        this.dragEvent = event;
        this.onDragStartDay = e.day.date;
      }
    },
    onDrag(tms) {
      if (!this.dragEvent) return;

      const mouseDate = tms.date;

      if (
        this.basedOn === this.$constant.BASED_ON_TYPES.START ||
        this.basedOn === this.$constant.BASED_ON_TYPES.END
      ) {
        this.dragEvent.start = this.formatDate(mouseDate);
      } else {
        if (this.dragEvent.end && this.dragEvent.start) {
          const duration = moment(this.dragEvent.end).diff(
            moment(this.dragEvent.start),
            "days",
          );

          this.dragEvent.start = this.formatDate(mouseDate);
          this.dragEvent.end = this.formatDate(moment(mouseDate).add(duration, "days"));
        } else if (this.dragEvent.start || this.dragEvent.end) {
          this.dragEvent.start = this.formatDate(mouseDate);
        }
      }
    },
    onEndDrag() {
      if (this.dragEvent) {
        const body = this.formatData();
        const data = {
          ...body,
        };

        this.onItemUpdate(data);
        this.dragEvent = null;
      }
    },
    formatData() {
      const { start, end, item } = this.dragEvent;
      const data = { item };

      let itemStart = item[this.startDateKey];
      let itemEnd = item[this.endDateKey];

      if (itemStart && itemEnd) {
        data[this.startDateKey] = start;
        data[this.endDateKey] = end;
      } else if (itemEnd) {
        data[this.endDateKey] = start;
      } else if (itemStart) {
        data[this.startDateKey] = start;
      }

      return data;
    },
    toTime(tms) {
      return new Date(tms.date).getTime();
    },
    onEventClick(event) {
      this.$emit("item:click", { event });
    },
    onItemUpdate(data) {
      this.$emit("item:update", data);
    },
    goNext() {
      this.$refs.calendar.next();
      this.$emit("month:change");
    },
    goPrevious() {
      this.$refs.calendar.prev();
      this.$emit("month:change");
    },
    prevOrNextMonthKeydown(e) {
      if (e.key == "ArrowLeft") {
        this.goPrevious();
      }
      if (e.key == "ArrowRight") {
        this.goNext();
      }
    },
    calcDataTableMaxHeight() {
      const viewportHeight = window.innerHeight;
      if (viewportHeight < 757) return "600"; //small screen
      const pixelsFromTopOfPageToTable =
        this.$refs.calendarContainer.$el.getBoundingClientRect().top;
      const extraPixelsToAccountFor = 120;
      const heightToRemove = pixelsFromTopOfPageToTable + extraPixelsToAccountFor;
      const maxHeightStyle = `calc(100vh - ${heightToRemove}px)`;
      return maxHeightStyle;
    },
    updateDataTableHeight() {
      const dataTableMaxHeight = this.calcDataTableMaxHeight();
      this.totalHeight = dataTableMaxHeight;
    },
    debounceRezise() {
      clearTimeout(this.reziseTimeoutId);
      this.reziseTimeoutId = setTimeout(this.updateDataTableHeight, 200);
    },
    async getStatuses() {
      await this.$store.dispatch("statuses/getStatuses", { model: this.model });
    },
  },
  mounted() {
    this.setEvents();
    this.getMoreMenuEvents();
    this.updateDataTableHeight();
    if (!this.hasFetchedStatuses(this.model)) {
      this.getStatuses();
    }
    window.addEventListener("keydown", this.prevOrNextMonthKeydown);
    window.addEventListener("resize", this.debounceRezise);
    window.addEventListener("mouseup", this.onEndDrag);
  },
  destroyed() {
    window.removeEventListener("keydown", this.prevOrNextMonthKeydown);
    window.removeEventListener("resize", this.debounceRezise);
    window.removeEventListener("mouseup", this.onEndDrag);
  },
};
</script>
