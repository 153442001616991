<template>
  <v-dialog
    v-model="localDialog"
    transition="dialog-bottom-transition"
    fullscreen
    persistent
    no-click-animation
  >
    <v-card class="background">
      <v-toolbar class="border-b" dense flat fixed height="80">
        <v-toolbar-title>
          {{ $t("taskManagement.taskView.title") }}
        </v-toolbar-title>
        <v-spacer />
        <span v-if="isUpdating" class="success--text p-absolute" style="right: 300px">
          {{ $t("common.updating") }}
          <v-icon color="success" class=""
            >{{ $icon.LIGHT.ACTION.REFRESH }} fa-spin</v-icon
          >
        </span>
        <v-btn icon dark @click="close">
          <v-icon>{{ $icon.LIGHT.ACTION.CLOSE }}</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="d-flex main-height">
        <div class="w-100 main-height d-flex justify-center">
          <div class="main-height pt-10 task-view__container">
            <AppTaskCard
              :task="task"
              :isUpdating="isUpdating"
              height="100%"
              @task:update="$emit('task:update', $event)"
            />
          </div>
        </div>
        <div class="d-flex main-height">
          <AppCommentDrawer
            v-if="activeMenu === 'COMMENTS'"
            type="task"
            :parentId="task.id"
          />

          <AppTaskFormDrawer
            v-if="activeMenu === 'FORMS'"
            :taskId="task.id"
            @form:update="$emit('form:update', $event)"
            @form:create="$emit('form:create', $event)"
          />

          <AppStorageDrawer
            v-if="activeMenu === 'STORAGE'"
            :rootFolderId="task.folderId"
          />

          <AppTaskToDoDrawer
            v-if="activeMenu === 'TO_TO'"
            :canClose="false"
            :taskId="task.id"
          />

          <AppHistoryDrawer v-if="activeMenu === 'HISTORY'" :itemId="task.id" />

          <AppProductUsageDrawer
            v-if="activeMenu === 'PRODUCT'"
            :modelId="task.id"
            model="task"
          />

          <AppVerticalNavBar
            class="border-t-none"
            :items="menus"
            :activeKey="activeMenu"
            :route="false"
            @item:click="onNavItemClick"
          />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      activeMenu: "",
    };
  },
  props: {
    dialog: Boolean,
    task: Object,
    isUpdating: Boolean,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    ...mapGetters("auth", {
      isCommentFeatureEnabled: "isCommentFeatureEnabled",
      isFileManagementFeatureEnabled: "isFileManagementFeatureEnabled",
      isFormManagementFeatureEnabled: "isFormManagementFeatureEnabled",
    }),
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    menus() {
      const menus = [];

      if (this.isCommentFeatureEnabled) {
        menus.push({
          key: "COMMENTS",
          tooltip: this.$options.filters.capitalize(this.$t("common.comments")),
          tooltipDirection: "left",
          icon: this.$icon.LIGHT.ROUTE.COMMENT,
        });
      }

      if (this.isFileManagementFeatureEnabled && this.task?.folderId) {
        menus.push({
          key: "STORAGE",
          tooltip: this.$t("fileManagement.fileExplorer.mainToolbar.attachments"),
          tooltipDirection: "left",
          icon: this.$icon.LIGHT.ROUTE.ATTACHMENT,
        });
      }

      if (this.isFormManagementFeatureEnabled) {
        menus.push({
          key: "FORMS",
          tooltip: this.$options.filters.capitalize(this.$t("common.forms")),
          tooltipDirection: "left",
          icon: this.$icon.LIGHT.ROUTE.FORM,
        });
      }
      menus.push({
        key: "PRODUCT",
        tooltip: this.$options.filters.capitalize(this.$t("common.products")),
        tooltipDirection: "left",
        icon: this.$icon.LIGHT.ROUTE.PRODUCT,
      });

      menus.push(
        {
          key: "TO_TO",
          tooltip: this.$t("common.toDoList"),
          tooltipDirection: "left",
          icon: this.$icon.LIGHT.ROUTE.TO_DO_LIST,
        },
        {
          key: "HISTORY",
          tooltip: this.$t("fileManagement.preview.navMenu.historicalLog.title"),
          tooltipDirection: "left",
          icon: this.$icon.LIGHT.ROUTE.EVENT_LOG,
        },
      );
      return menus;
    },
  },
  methods: {
    close() {
      this.localDialog = false;
    },
    onNavItemClick({ item }) {
      this.activeMenu = this.activeMenu !== item.key ? item.key : null;
    },
  },
  mounted() {},
};
</script>
