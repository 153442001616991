<template>
  <div
    class="h-100 d-flex justify-center align-center"
    @click="showMarkerArea"
    :class="canEditFile ? 'cursor-pointer' : ''"
  >
    <img
      ref="imgRef"
      :src="item.content"
      style="max-width: 100%; max-height: 100%; object-fit: contain"
    />
  </div>
</template>

<script>
import { MarkerArea } from "markerjs2";
export default {
  props: {
    item: Object,
    canEditFile: Boolean,
    isLinkedFile: Boolean,
  },
  methods: {
    showMarkerArea() {
      if (!this.canEditFile) return;
      if (this.isLinkedFile) {
        this.$store.dispatch("snackbar/snackbar", {
          show: true,
          text: this.$t("fileManagement.linkedFile.cantEditImageWarning"),
          color: "info",
          timeout: 7000,
        });
        return;
      }
      const contentType = this.item?.contentType || "image/jpeg";
      const markerArea = new MarkerArea(this.$refs.imgRef);
      markerArea.addEventListener("render", (event) => {
        this.$refs.imgRef.src = event.dataUrl;
        this.replaceFile(event.dataUrl);
      });
      markerArea.settings.displayMode = "popup";
      markerArea.renderImageType = contentType;
      markerArea.renderAtNaturalSize = true;
      markerArea.show();
    },
    async replaceFile(dataUrl) {
      const file = await this.url2File(dataUrl, "name");
      const formData = new FormData();
      formData.append("file", file);

      await this.$store.dispatch("storageDetails/replaceFile", {
        id: this.item.id,
        formData,
      });
      this.$emit("preview:update", { fileId: this.item.id });
    },

    async url2File(url, fileName) {
      const blob = await (await fetch(url)).blob();
      return new File([blob], fileName);
    },
  },
};
</script>
