<template>
  <v-list-item :class="`w-100 align-center rounded widget_list_item`">
    <v-list-item-avatar>
      <AppCustomStatusIcon
        @click="(e) => $emit('status:click', { ...e, selectedItem: item })"
        :model="statusModel"
        :statusId="statusId"
        :loading="item?.isUpdatingStatus"
        :disabled="item?.isUpdatingStatus"
      />
    </v-list-item-avatar>
    <v-list-item-content>
      <div>
        <v-list-item-title>
          <span
            @click="$emit('title:click')"
            class="underline-on-hover cursor-pointer break-word"
          >
            {{ title }}
          </span>
        </v-list-item-title>

        <span
          @click="$emit('subTitle:click')"
          class="subtitle--text text-caption underline-on-hover cursor-pointer break-word"
        >
          {{ subTitle }}
        </span>
        <div class="subtitle--text text-caption" v-html="underTextVHtml" />
      </div>
    </v-list-item-content>
    <v-list-item-avatar>
      <AppUserAvatar :user="user" class="mr-3" />
    </v-list-item-avatar>
  </v-list-item>
</template>

<style>
.break-word {
  word-break: break-word;
  white-space: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
</style>

<script>
export default {
  props: {
    item: Object,
    title: String,
    subTitle: String,
    statusId: String,
    statusModel: String,
    user: Object,
    underTextVHtml: String,
  },
};
</script>
