<template>
  <v-list-item
    target="_blank"
    :input-value="true"
    dense
    v-on="listeners"
    v-bind="params"
    class="custom-list-item"
  >
    <v-list-item-icon>
      <template v-if="$slots.icon">
        <slot name="icon"></slot>
      </template>
      <v-icon v-else-if="icon">{{ icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <slot name="title"></slot>
      <v-list-item-title class="text-wrap">{{ title }}</v-list-item-title>
    </v-list-item-content>
    <slot name="append-item"></slot>
  </v-list-item>
</template>

<script>
export default {
  props: {
    icon: String,
    color: String,
    title: String,
    to: Object,
  },
  computed: {
    params() {
      const params = { ...this.$props, ...this.$attrs };
      return params;
    },
    listeners() {
      const listeners = {
        ...this.$listeners,
      };
      return listeners;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-wrap {
  white-space: normal;
  word-break: break-word;
}

//Remove the background color if the item is on the current page
.custom-list-item {
  &.v-list-item--active {
    &::before {
      opacity: 0 !important;
    }

    &:hover::before {
      opacity: 0.04 !important;
    }
  }
}
</style>
