<template>
  <v-menu
    :offsetX="offsetX"
    :offsetY="offsetY"
    :left="left"
    :right="right"
    :top="top"
    :bottom="bottom"
    open-on-hover
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ on }">
      <AppMenuMoreItem
        :text="text"
        :buttonColor="buttonColor"
        :disabled="disabled"
        :icon="icon"
        :angleDown="angleDown"
        v-on="{ ...on, click: (e) => e.stopPropagation() }"
      />
    </template>
    <div class="pa-2 ui-background d-flex flex-column" style="min-width: 70px">
      <slot />
    </div>
  </v-menu>
</template>

<script>
export default {
  props: {
    text: String,
    buttonColor: String,
    icon: String,
    disabled: Boolean,
    left: Boolean,
    right: Boolean,
    top: Boolean,
    bottom: Boolean,
    offsetX: Boolean,
    offsetY: Boolean,
    angleDown: Boolean,
  },
};
</script>
