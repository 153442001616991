import { ROUTE_NAMES } from "@/helpers/routeNames";
import { authGuard, userGuard } from "../../auth";
import multiguard from "../multiguard";

const {
  ROOT,
  VIEW_MENU_HISTORY,
  VIEW_MENU_STORAGE,
  VIEW_MENU_FORMS,
  VIEW_MENU_PRODUCTS_USAGE,
  VIEW_MENU_TODO_LIST,
  VIEW_MENU_COMMENTS,
} = ROUTE_NAMES.TASK_VIEW;

export const taskViewRoutes = [
  {
    path: "/task-view/:taskId",
    name: ROOT,
    beforeEnter: multiguard([authGuard, userGuard()]),
    component: () =>
      import(/* webpackChunkName: "taskView" */ "../../pages/TaskManagement/TaskView"),
    children: [
      {
        path: "history",
        name: VIEW_MENU_HISTORY,
        component: () =>
          import(
            /* webpackChunkName: "taskView" */ "../../pages/TaskManagement/MenuPages/HistoryPage"
          ),
      },
      {
        path: "storage/:folderId",
        name: VIEW_MENU_STORAGE,
        component: () =>
          import(
            /* webpackChunkName: "taskView" */ "../../pages/TaskManagement/TaskView/MenuPages/StoragePage"
          ),
      },
      {
        path: "forms",
        name: VIEW_MENU_FORMS,
        component: () =>
          import(
            /* webpackChunkName: "taskView" */ "../../pages/TaskManagement/MenuPages/FormPage"
          ),
      },
      {
        path: "product-usage",
        name: VIEW_MENU_PRODUCTS_USAGE,
        component: () =>
          import(
            /* webpackChunkName: "taskView" */ "../../pages/TaskManagement/MenuPages/ProductUsagePage"
          ),
      },
      {
        path: "to-do-list",
        name: VIEW_MENU_TODO_LIST,
        component: () =>
          import(
            /* webpackChunkName: "taskView" */ "../../pages/TaskManagement/MenuPages/ToDoList"
          ),
      },
      {
        path: "comments",
        name: VIEW_MENU_COMMENTS,
        component: () =>
          import(
            /* webpackChunkName: "taskView" */ "../../pages/TaskManagement/MenuPages/CommentsPage"
          ),
      },
    ],
  },
];
