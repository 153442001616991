<template>
  <AppDefaultAutocomplete
    ref="autocomplete"
    :filter="filter || defaultFilter"
    v-on="listeners"
    v-bind="params"
    :search-input.sync="search"
  >
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"></slot>
    <template v-slot:item="{ item, on, attrs }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-action v-if="multiple">
          <v-icon :color="attrs.inputValue ? 'primary' : undefined" class="mx-1">
            {{ checkboxIcon(attrs.inputValue) }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <div class="d-flex">
            <slot name="prepend-inner" :item="item"></slot>
            <ModuleAutocompleteItem
              :item="item"
              :isSelected="attrs.inputValue"
              :search="lowerSearch"
              :titleField="titleField"
              :subTitleFields="subTitleFields"
            />
            <v-spacer></v-spacer>
            <slot name="append-inner" :item="item"></slot>
          </div>
        </v-list-item-content>
      </v-list-item>
    </template>
  </AppDefaultAutocomplete>
</template>

<script>
import ModuleAutocompleteItem from "./ModuleAutocompleteItem.vue";

export default {
  props: {
    titleField: Object,
    subTitleFields: Array,
    multiple: Boolean,
    filter: Function,
  },
  components: {
    ModuleAutocompleteItem,
  },
  data() {
    return {
      search: "",
    };
  },
  computed: {
    params() {
      const params = { ...this.$props, ...this.$attrs };
      return params;
    },
    listeners() {
      const listeners = {
        ...this.$listeners,
      };
      return listeners;
    },
    lowerSearch() {
      return this.search?.toLowerCase() || "";
    },
  },
  methods: {
    defaultFilter(item) {
      return item?.searchStr.includes(this.lowerSearch);
    },
    focus() {
      this.$refs.autocomplete.focus();
    },
    activateMenu() {
      this.$refs.autocomplete.activateMenu();
    },
    checkboxIcon(inputValue) {
      if (inputValue) return this.$icon.SOLID.COMMON.SQUARE_CHECK;
      return this.$icon.LIGHT.COMMON.SQUARE;
    },
  },
};
</script>
