<template>
  <AppMenuItem
    :text="text || $t('fileManagement.fileExplorer.menu.copy')"
    :icon="$icon.REGULAR.FILE.FILE_COPY"
    :disabled="disabled"
    @click="click"
  />
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    text: String,
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>
