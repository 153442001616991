import { ROUTE_NAMES } from "@/helpers/routeNames";
import { authGuard, userGuard } from "../../auth";
import multiguard from "../multiguard";

const {
  ROOT,
  COMPLETION,
  COMPLETION_DETAILS,
  COMPLETION_COMMENTS,
  COMPLETION_STORAGE,
  COMPLETION_HISTORY,
  COMPLETION_SIGNATURE,
  PREVIEW,
  PREVIEW_DETAILS,
  PREVIEW_COMMENTS,
  PREVIEW_STORAGE,
  PREVIEW_HISTORY,
  PREVIEW_SIGNATURE,
} = ROUTE_NAMES.FORM_VIEW;

export const formViewRoutes = [
  {
    path: "/form-view/:formId",
    name: ROOT,
    beforeEnter: multiguard([authGuard, userGuard()]),
    component: () =>
      import(/* webpackChunkName: "formView" */ "../../pages/FormManagement/FormView"),
    redirect: { name: COMPLETION_DETAILS },
    children: [
      {
        path: "edit",
        name: COMPLETION,
        component: () =>
          import(
            /* webpackChunkName: "formView" */ "../../pages/FormManagement/FormView/FormCompletionPage"
          ),
        children: [
          {
            path: "details",
            name: COMPLETION_DETAILS,
            component: () =>
              import(
                /* webpackChunkName: "formView" */ "../../pages/FormManagement/MenuPages/DetailsPage"
              ),
          },
          {
            path: "comments",
            name: COMPLETION_COMMENTS,
            component: () =>
              import(
                /* webpackChunkName: "formView" */ "../../pages/FormManagement/MenuPages/CommentsPage"
              ),
          },
          {
            path: "storage/:folderId",
            name: COMPLETION_STORAGE,
            component: () =>
              import(
                /* webpackChunkName: "formView" */ "../../pages/FormManagement/MenuPages/StoragePage"
              ),
          },
          {
            path: "history",
            name: COMPLETION_HISTORY,
            component: () =>
              import(
                /* webpackChunkName: "formView" */ "../../pages/FormManagement/MenuPages/HistoryPage"
              ),
          },
          {
            path: "signature",
            name: COMPLETION_SIGNATURE,
            component: () =>
              import(
                /* webpackChunkName: "formView" */ "../../pages/FormManagement/MenuPages/SignaturePage"
              ),
          },
        ],
      },
      {
        path: "preview",
        name: PREVIEW,
        component: () =>
          import(
            /* webpackChunkName: "formView" */ "../../pages/FormManagement/FormView/FormPreviewPage"
          ),
        children: [
          {
            path: "details",
            name: PREVIEW_DETAILS,
            component: () =>
              import(
                /* webpackChunkName: "formView" */ "../../pages/FormManagement/MenuPages/DetailsPage"
              ),
          },
          {
            path: "comments",
            name: PREVIEW_COMMENTS,
            component: () =>
              import(
                /* webpackChunkName: "formView" */ "../../pages/FormManagement/MenuPages/CommentsPage"
              ),
          },
          {
            path: "storage/:folderId",
            name: PREVIEW_STORAGE,
            component: () =>
              import(
                /* webpackChunkName: "formView" */ "../../pages/FormManagement/MenuPages/StoragePage"
              ),
          },
          {
            path: "history",
            name: PREVIEW_HISTORY,
            component: () =>
              import(
                /* webpackChunkName: "formView" */ "../../pages/FormManagement/MenuPages/HistoryPage"
              ),
          },
          {
            path: "signature",
            name: PREVIEW_SIGNATURE,
            component: () =>
              import(
                /* webpackChunkName: "formView" */ "../../pages/FormManagement/MenuPages/SignaturePage"
              ),
          },
        ],
      },
    ],
  },
];
