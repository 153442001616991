<template>
  <div @click.stop="onClick" class="cursor-pointer d-flex justify-center">
    <v-icon
      ref="icon"
      style="width: 20px !important"
      :color="
        statusMixins_statusColor(status, loading, statusMixins_timeEntriesStatusItems)
      "
    >
      {{ icon }}
    </v-icon>
  </div>
</template>

<script>
import { statusMixins } from "@/helpers/mixins";

export default {
  mixins: [statusMixins],
  props: {
    status: String,
    loading: Boolean,
  },
  computed: {
    icon() {
      return this.loading
        ? this.$icon.SOLID.ACTION.LOADING
        : this.$icon.SOLID.ACTION.STATUS;
    },
  },
  methods: {
    onClick(pointerEvent) {
      let { x, y } = this.$refs.icon.$el.getBoundingClientRect();
      y += 30;

      this.$emit("click", { x, y, pointerEvent });
    },
  },
};
</script>
