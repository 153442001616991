import { ROUTE_NAMES } from "./routeNames";

export const SERVER_SIDE_PAGINATION_TABLE__PARENT_PAGE_NAMES = [
  ROUTE_NAMES.TASK.LIST,
  ROUTE_NAMES.FORM.ROOT,
  ROUTE_NAMES.TIME_REGISTRATION.ALL_HOURS,
  ROUTE_NAMES.CONTACT.ROOT,
  ROUTE_NAMES.PROJECT.LIST,
  ROUTE_NAMES.PROJECT.TASK_LIST,
  ROUTE_NAMES.PROJECT.FORM_LIST,
  ROUTE_NAMES.PROJECT.SUB_PROJECTS_LIST,
  ROUTE_NAMES.PROJECT.VIEW_CONTACTS,
  ROUTE_NAMES.PROJECT.PRODUCTS,
  ROUTE_NAMES.SETTINGS.PRODUCTS,
];

export const CALENDAR__PARENT_PAGE_NAMES = [
  ROUTE_NAMES.TASK.CALENDAR,
  ROUTE_NAMES.PROJECT.CALENDAR,
  ROUTE_NAMES.PROJECT.TASK_CALENDAR,
  ROUTE_NAMES.PROJECT.SUB_PROJECTS_CALENDAR,
];
