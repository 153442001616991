import { normalizeTask, normalizeTasks } from "@/helpers/normalization";
import { shouldUpdateItems } from "@/helpers/util";
import { formatDataTableOptionsToQuery } from "@/helpers/util/dataTable";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
const CancelToken = axios.CancelToken;
let getTasksCancel;

const state = () => ({
  tasks: [],
  count: null,
  isLoading: false,
  isUpdating: false,
  isCreating: false,
  listId: null,
});

const getters = {
  hasUnsavedTask: (state) => {
    return state.tasks.find((task) => task.id === undefined) ? true : false;
  },
  getTaskById: (state) => (id) => {
    return state.tasks.find((task) => task.id === id);
  },
  isValidTask: (state, getters) => (id) => {
    if (state.isLoading) return true;
    return getters.getTaskById(id) ? true : false;
  },
  getTaskFolderId: (state, getters) => (id) => {
    const task = getters.getTaskById(id);
    return task?.folderId || null;
  },
};

const actions = {
  async getTask({ commit, rootState }, id) {
    const currentUser = rootState?.auth?.user;

    return axios
      .get(`${apiUrl}task/${id}`)
      .then(({ data }) => {
        const normalizedTask = normalizeTask(data.task, currentUser);
        commit("addOrUpdateTask", normalizedTask);
        commit("incrementCount");
        return normalizedTask;
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async getTasks({ commit, rootState }, { filters, storageId, dataTableOptions }) {
    if (getTasksCancel) {
      getTasksCancel();
    }
    commit("loading", true);
    const currentUser = rootState?.auth?.user;

    const optionsQuery = formatDataTableOptionsToQuery(dataTableOptions);

    const params = {
      ...(storageId && { storageId }),
      ...optionsQuery,
      ...filters,
    };

    return axios
      .get(`${apiUrl}task`, {
        params,
        cancelToken: new CancelToken(function executor(c) {
          getTasksCancel = c;
        }),
      })
      .then(async ({ data }) => {
        const { tasks, count } = data;

        const shouldUpdate = shouldUpdateItems(
          count,
          dataTableOptions?.page,
          dataTableOptions?.itemsPerPage,
        );
        if (shouldUpdate) {
          const normalizedTasks = normalizeTasks(tasks, currentUser);
          commit("setTasks", normalizedTasks);
        }
        commit("setCount", count);
        commit("loading", false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          commit("loading", false);
          throw new Error(error);
        }
      });
  },
  async createTask({ commit, state }, { data }) {
    commit("creating", true);
    return axios
      .post(`${apiUrl}task`, { ...data })
      .then(async ({ data }) => {
        const task = data?.task;
        const repeatTask = data?.repeatTask;
        if (task || repeatTask) {
          let normalizedNormalTask;
          if (task) {
            normalizedNormalTask = normalizeTask(task);
            commit("addTask", normalizedNormalTask);
            commit("setCount", state.count + 1);
          }
          if (repeatTask) {
            const normalizedRepeatTask = normalizeTask(repeatTask);
            commit("addTask", normalizedRepeatTask);
            commit("setCount", state.count + 1);
          }
          if (task) {
            return normalizedNormalTask;
          }
        }
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("creating", false);
      });
  },
  async updateTask({ commit }, { id, data }) {
    commit("updating", true);
    if (typeof data?.statusId === "string") {
      commit("toggleUpdatingStatus", {
        task: { id },
        isUpdatingStatus: true,
      });
    }

    return axios
      .put(`${apiUrl}task/${id}`, { ...data })
      .then(async ({ data }) => {
        const task = data?.task;
        const repeatTask = data?.repeatTask;

        if (repeatTask) {
          const normalizedTask = normalizeTask(repeatTask);
          commit("addTask", normalizedTask);
        }
        if (task) {
          const normalizedTask = normalizeTask(task);
          commit("updateTask", { task: normalizedTask });
          commit("toggleUpdatingStatus", {
            task: normalizedTask,
            isUpdatingStatus: false,
          });
        } else {
          commit("removeTask", { id });
        }
      })
      .catch((error) => {
        commit("toggleUpdatingStatus", {
          task: { id },
          isUpdatingStatus: false,
        });

        throw new Error(error);
      })
      .finally(() => {
        commit("updating", false);
      });
  },
  async deleteTask({ commit, state }, { id }) {
    return axios
      .delete(`${apiUrl}task/${id}`)
      .then(async (res) => {
        const task = res?.data?.task;
        if (task) {
          commit("removeTask", task);
          commit("setCount", state.count - 1);
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  resetState({ commit }) {
    commit("setTasks", []);
    commit("setCount", null);
  },
};

const mutations = {
  setTasks(state, tasks) {
    state.tasks = tasks;
  },
  setCount(state, count) {
    state.count = count;
  },
  incrementCount(state) {
    state.count++;
  },
  setListId(state, listId) {
    state.listId = listId;
  },
  updateTask(state, { task }) {
    if (!task) throw new Error("No task");
    const index = state.tasks.findIndex((x) => x.id === task.id);
    if (index !== -1) {
      state.tasks.splice(index, 1, task);
    }
  },
  addTask(state, task) {
    if (!task) throw new Error("No task");
    state.tasks.unshift(task);
  },
  removeTask(state, task) {
    if (!task) throw new Error("No task");
    const index = state.tasks.findIndex((x) => x.id === task.id);
    if (index !== -1) {
      state.tasks.splice(index, 1);
    }
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  creating(state, isCreating) {
    state.isCreating = isCreating;
  },
  updating(state, isUpdating) {
    state.isUpdating = isUpdating;
  },
  toggleUpdatingStatus(state, { task, isUpdatingStatus }) {
    if (!task) throw new Error("No task");
    const index = state.tasks.findIndex((x) => x.id === task.id);
    if (index !== -1) {
      if (state.tasks[index]) {
        state.tasks.splice(index, 1, { ...state.tasks[index], isUpdatingStatus });
      }
    }
  },
  addOrUpdateTask(state, task) {
    if (!task) throw new Error("No task");
    const taskIndex = state.tasks.findIndex((t) => t.id === task.id);
    if (taskIndex !== -1) {
      state.tasks.splice(taskIndex, 1, task);
    } else {
      state.tasks.unshift(task);
    }
  },
};

export const task = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
