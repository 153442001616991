<template>
  <div class="information_drawer">
    <v-toolbar dense flat fixed height="68" :class="isLoading ? 'border-b' : ''">
      <template v-if="!search.active">
        <v-toolbar-title class="font-weight-bold">
          {{ $t("common.products") | capitalize }}
        </v-toolbar-title>
        <v-spacer />
        <AppDefaultTooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="openProductDialog({ products: [] })"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ $icon.LIGHT.ACTION.CIRCLE_PLUS }}</v-icon>
            </v-btn>
          </template>

          <span class="break-word">
            {{ $t("product.addProducts") }}
          </span>
        </AppDefaultTooltip>
        <v-btn icon @click="openSearch">
          <v-icon>{{ $icon.LIGHT.ACTION.SEARCH }}</v-icon>
        </v-btn>
      </template>
      <template v-if="search.active">
        <AppDefaultTextField
          v-model="search.value"
          :autofocus="true"
          :label="$t('common.search')"
          :append-icon="$icon.LIGHT.ACTION.SEARCH"
          hide-details
          :outlined="false"
          :dense="false"
          clearable
        />
        <v-btn class="ml-2" icon @click="closeSearch">
          <v-icon>{{ $icon.LIGHT.ACTION.CLOSE }}</v-icon>
        </v-btn>
      </template>
    </v-toolbar>
    <AppCenterLoader
      v-model="isLoading"
      :title="$t('common.loading')"
      :height="'calc(100% - 68px)'"
    />
    <div class="overflow-y-auto secondary-height" v-if="!isLoading">
      <AppSectionList
        :sections="categorizedProductUsages"
        :isSectionsExpanded="isSectionsExpanded"
      >
        <template v-slot:item="{ item }">
          <AppProductUsageDrawerItem
            :item="item"
            @edit="openProductDialog({ products: [item] })"
            @delete="onProductDelete({ products: [item] })"
          />
        </template>
      </AppSectionList>
    </div>
    <AppProductUsageDialog
      v-model="dialog.products.active"
      :model="model"
      :modelId="modelId"
      :projectId="projectId"
      :editProducts="dialog.products.data"
    />
    <AppDeleteConfirmationDialog
      v-model="dialog.delete.active"
      :item="dialog.delete.data"
      :title="$t('settings.products.deleteProducts.title')"
      :subtitle="$t('settings.products.deleteProducts.subTitle')"
      :validator="$t('common.delete').toLowerCase()"
      :validatorText="
        $t('settings.products.deleteProducts.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      @delete="deleteProducts"
      skip-validation
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  props: {
    model: String,
    modelId: String,
    projectId: String,
  },
  data() {
    return {
      dialog: {
        products: {
          active: false,
          data: null,
        },
        delete: {
          active: false,
          data: null,
        },
      },
      search: {
        active: false,
        value: null,
      },
      isSectionsExpanded: [true, false],
    };
  },
  computed: {
    ...mapState("productUsage", {
      products: (state) => state.products,
      isLoading: (state) => state.isLoading,
    }),
    ...mapGetters("auth", {
      currentUserId: "currentUserId",
    }),
    filterProducts() {
      const search = this.search.value?.toLowerCase() || "";
      const filteredDomains = this.products.filter((product) => {
        const nameSearch = product.name?.toLowerCase().includes(search.toLowerCase());
        const userNameSearch = product.user.fullName
          ?.toLowerCase()
          .includes(search.toLowerCase());
        const unitSearch = product.unit?.label
          ?.toLowerCase()
          .includes(search.toLowerCase());
        return nameSearch || userNameSearch || unitSearch;
      });
      return filteredDomains;
    },
    categorizedProductUsages() {
      if (!this.products) return;

      const categorizedProductUsages = [
        {
          title: this.$t("product.myProducts"),
          data: this.myProductUsages,
        },
        {
          title: this.$t("product.otherProducts"),
          data: this.otherProductUsages,
        },
      ];

      return categorizedProductUsages;
    },

    myProductUsages() {
      return this.filterProducts
        .filter((productUsage) => productUsage.userId === this.currentUserId)
        .map((productUsage) => ({ ...productUsage, section: "MY_PRODUCTS" }));
    },

    otherProductUsages() {
      return this.filterProducts
        .filter((productUsage) => productUsage.userId !== this.currentUserId)
        .map((productUsage) => ({ ...productUsage, section: "OTHER_PRODUCTS" }));
    },
  },
  watch: {
    modelId: {
      handler(val) {
        this.getProducts();
      },
    },
  },
  methods: {
    openSearch() {
      this.search.active = true;
    },
    closeSearch() {
      this.search.active = false;
      this.search.value = null;
    },

    async getProducts() {
      await this.$store.dispatch("productUsage/getProducts", {
        filter: {},
        modelId: this.modelId,
        model: this.model,
      });
    },
    onProductUpdate({ products }) {
      this.$store.dispatch("productUsage/updateProducts", { products });
    },
    onProductDelete({ products }) {
      this.dialog.delete.data = products;
      this.dialog.delete.active = true;
    },
    async deleteProducts(e) {
      this.$store.dispatch("productUsage/deleteProducts", {
        ids: e.item.map((item) => item.id),
      });
    },
    openProductDialog({ products }) {
      this.dialog.products.active = true;
      this.dialog.products.data = products;
    },
  },
  async mounted() {
    await this.$store.dispatch("productUsage/resetState");
    this.getProducts();
  },
};
</script>
