<script>
import { projectTemplateBuilderMixins } from "@/helpers/mixins";
import { VIcon, VSpacer } from "vuetify/lib";
import CustomButtonTooltip from "./CustomButtonTooltip.vue";

export default {
  mixins: [projectTemplateBuilderMixins],
  props: {
    item: Object,
    isFirst: Boolean,
    isLast: Boolean,
  },
  data() {
    return {
      showNameTextField: false,
      name: {
        isValid: false,
      },
      isHovering: false,
    };
  },
  computed: {
    isDocumentDeleted() {
      const hasDocument = !!this.item?.document;
      return !hasDocument;
    },
  },
  methods: {
    genNameField() {
      const name = this.item?.document?.name || this.item?.name;
      return this.$createElement(
        "div",
        { class: `${this.isDocumentDeleted ? "error--text" : ""} text-truncate` },
        name,
      );
    },
    genDeleteButton() {
      return this.$createElement("div", { style: "width: 43px;" }, [
        this.isHovering &&
          this.$createElement(CustomButtonTooltip, {
            props: {
              text: this.$t("common.delete"),
              icon: this.$icon.LIGHT.ACTION.DELETE,
              disabled: !this.projectTemplateBuilderMixins_isItemSaved(this.item),
            },
            on: {
              "button:click": () => this.deleteDocumentTemplateItem(),
            },
          }),
      ]);
    },
    deleteDocumentTemplateItem() {
      this.$emit("item:delete", { item: this.item });
    },
    getHoverBorderColor() {
      const isDark = this.$vuetify.theme.isDark;
      const darkBorder = this.$vuetify.theme.defaults.dark["primary-border"];
      const lightBorder = this.$vuetify.theme.defaults.light["primary-border"];
      return isDark ? darkBorder : lightBorder;
    },
    getBorderOnHover() {
      const hoverBorderColor = this.getHoverBorderColor();
      const color = this.isHovering ? hoverBorderColor : "transparent";
      return `border-top: 1px solid ${color}; border-right: 1px solid ${color}; border-bottom: 1px solid ${color};border-left: 1px solid ${color};`;
    },
    genIcon() {
      return this.$createElement("div", { class: "px-5" }, [
        this.$createElement(
          VIcon,
          { props: { color: this.isDocumentDeleted ? "error" : "" } },
          this.isDocumentDeleted
            ? this.$icon.LIGHT.FILE.FILE_EXCLAMATION
            : this.$icon.LIGHT.FILE.FILE,
        ),
      ]);
    },
    genDocumentDeletedChip() {
      if (!this.isDocumentDeleted) return;
      return this.$createElement("div", { class: "px-5" }, [
        this.$createElement("AppColorChip", {
          props: {
            color: "error--text",
            text: this.$t("templateManagement.project.documentDeleted"),
          },
        }),
      ]);
    },
    genDocumentItemRow() {
      return this.$createElement(
        "div",
        {
          style: "height: 65px; border-radius: 0px; overflow: hidden",
          class: `pa-2 d-flex align-center`,
        },
        [
          this.genIcon(),
          this.genNameField(),
          this.genDocumentDeletedChip(),
          this.$createElement(VSpacer),
          this.$createElement("div", {}, [this.genDeleteButton()]),
        ],
      );
    },
  },
  render(h) {
    return this.$createElement(
      "div",
      {
        style: `${this.getBorderOnHover()}`,
        class: "w-100",
        on: {
          mouseover: (e) => (this.isHovering = true),
          mouseleave: (e) => (this.isHovering = false),
        },
      },

      [this.genDocumentItemRow()],
    );
  },
};
</script>
