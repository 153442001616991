export const storageDrawerMixins = {
  methods: {
    storageDrawerMixins_getStorage(id) {
      this.$store.dispatch(`${this.storeKey}/getStorage`, id);
      this.$store.dispatch(`${this.storeKey}/getParent`, id);
      this.$store.dispatch(`${this.storeKey}/getStoragePath`, {
        id,
        rootId: this.rootFolderId,
      });
      this.$store.dispatch(`${this.storeKey}/getAcceptedFileTypes`);
    },
    storageDrawerMixins_onItemClick({ item, type }) {
      if (type === this.$constant.FOLDER) {
        if (this.folderId !== item.id) {
          this.folderId = item.id;
          this.activeFileId = null;
        }
      } else if (type.includes(this.$constant.FILE)) {
        this.activeFileId = item.id;
      }
    },
    storageDrawerMixins_onPreviewDialogClose() {
      this.activeFileId = null;
    },
    storageDrawerMixins_onSearchItemClick({ item, type }) {
      if (type === this.$constant.FOLDER) {
        if (this.folderId !== item.id) {
          this.folderId = item.id;
          this.activeFileId = null;
        }
      } else if (type.includes(this.$constant.FILE)) {
        this.folderId = item.parentId;
        this.activeFileId = item.id;
      }
    },
    storageDrawerMixins_onPathItemClick({ path }) {
      if (this.folderId !== path.id) {
        this.folderId = path.id;
        this.activeFileId = null;
      } else {
        this.storageDrawerMixins_getStorage(path.id);
      }
    },
    storageDrawerMixins_onItemEdit({ item, type }) {
      switch (type) {
        case this.$constant.FOLDER:
          this.$store.dispatch(`${this.storeKey}/updateFolder`, item);
          break;
        case this.$constant.FILE:
          this.$store.dispatch(`${this.storeKey}/updateFile`, item);
          break;
        case this.$constant.FILE_RELATION:
          this.$store.dispatch(`${this.storeKey}/updateFile`, item);
          break;
      }
    },
    storageDrawerMixins_onItemCreate({ data, type }) {
      switch (type) {
        case this.$constant.FOLDER:
          this.$store.dispatch(`${this.storeKey}/createFolder`, data);
          break;
        case this.$constant.FILE:
          this.$store.dispatch(`${this.storeKey}/uploadFiles`, {
            parentId: this.folderId,
            formData: data,
          });
          break;
      }
    },
    storageDrawerMixins_onItemDownload({ selectedItems }) {
      const ids = selectedItems.map((i) => i.id);
      if (ids.length > 0) {
        this.$store.dispatch(`${this.storeKey}/download`, ids);
      }
    },
    storageDrawerMixins_onItemDelete({ selectedItems }) {
      this.$store.dispatch(`${this.storeKey}/deleteStorageItems`, {
        parentId: this.folderId,
        items: selectedItems,
      });
    },
    storageDrawerMixins_onItemSelectToggle({ item, type }) {
      this.$store.commit(`${this.storeKey}/toggleStorageSelected`, {
        isSelected: !item.isSelected,
        items: [item],
      });
    },
    storageDrawerMixins_onSelectAllToggle({ isSelected }) {
      if (isSelected) {
        this.$store.commit(`${this.storeKey}/toggleStorageSelected`, {
          isSelected: true,
          items: [...this.items.filter((x) => x.isSelected === false)],
        });
      } else {
        this.$store.commit(`${this.storeKey}/toggleStorageSelected`, {
          isSelected: false,
          items: [...this.items],
        });
      }
    },
    storageDrawerMixins_storageInit() {
      this.$store.commit(`${this.storeKey}/storageLoading`, true);
      if (this.rootFolderId) {
        this.folderId = this.rootFolderId;
        this.storageDrawerMixins_getStorage(this.folderId);
      } else {
        this.initTimeout = setTimeout(this.storageDrawerMixins_storageInit, 100);
      }
    },
  },
};
