import { normalizeFormItem, normalizeFormItems } from "@/helpers/normalization";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  items: [],
  isLoading: false,
  isCreating: false,
  isUpdatingTask: false,
});

const getters = {
  sortedItems: (state) => {
    return [...state.items].sort((a, b) => a.index - b.index);
  },
};

const actions = {
  async getItems({ commit }, { formId, load = true }) {
    if (load) {
      commit("loading", true);
    }
    return axios
      .get(`${apiUrl}form-item`, {
        params: { formId },
      })
      .then(({ data }) => {
        const items = data?.items;
        const normalizedItems = normalizeFormItems(items);
        commit("setItems", normalizedItems);
        commit("loading", false);
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
  async createItem({ commit }, { body }) {
    return axios
      .post(`${apiUrl}form-item`, { ...body })
      .then(({ data }) => {
        const item = data?.item;
        const normalizedItem = normalizeFormItem(item);
        commit("addItem", normalizedItem);
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async updateItem({ commit, state }, { id, body }) {
    const currentItemIndex = state.items.findIndex((item) => item.id === id);
    const currentItemCopy = { ...state.items[currentItemIndex] };

    const updatedItem = { ...currentItemCopy, ...body };
    const normalizedUpdatedItem = normalizeFormItem(updatedItem);
    commit("updateItem", normalizedUpdatedItem);

    return axios
      .put(`${apiUrl}form-item/${id}`, body)
      .then(({ data }) => {
        const item = data?.item;
        if (item) {
          const normalizedItem = normalizeFormItem(item);
          // Confirm the update with the server response
          commit("updateItem", normalizedItem);
        }
      })
      .catch((error) => {
        commit("updateItem", currentItemCopy);
        throw new Error(error);
      });
  },
  async deleteItem({ commit }, { id }) {
    return axios
      .delete(`${apiUrl}form-item/${id}`)
      .then(() => {
        commit("removeItem", id);
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async getItemImages({ commit }, { item, loading = true, height = 240 }) {
    if (loading) {
      commit("toggleImagesLoading", { item, isLoading: true });
    }
    for (let image of item.images) {
      if (image.url) continue;
      await axios
        .get(`${apiUrl}storage/file/${image.id}/content`, {
          responseType: "blob",
          params: { height },
          skipErrorHandling: true,
        })
        .then(({ data }) => {
          const imageUrl = URL.createObjectURL(data);
          commit("addImage", { item, imageUrl, imageId: image.id });
        })
        .catch((error) => {
          commit("addImage", { item, imageUrl: null, imageId: image.id });
        });
    }
    commit("toggleImagesLoading", { item, isLoading: false });
  },
  async getImage({ commit }, { item, id }) {
    await axios
      .get(`${apiUrl}storage/file/${id}/content`, {
        responseType: "blob",
        params: { height: "90" },
      })
      .then(({ data }) => {
        const imageUrl = URL.createObjectURL(data);
        commit("addImage", { item, imageUrl, imageId: id });
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async uploadImages({ dispatch, commit }, { item, formData }) {
    commit("toggleImagesUploading", { item, isUploading: true });
    return axios
      .post(`${apiUrl}form-item/${item.id}/files`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(({ data }) => {
        const imagesToAdd = (data.storages || []).map((i) => i.id);
        for (let image of imagesToAdd) {
          dispatch("getImage", { item, id: image });
        }
        commit("toggleImagesUploading", { item, isUploading: false });
      })
      .catch((error) => {
        commit("toggleImagesUploading", { item, isUploading: false });
        throw new Error(error);
      });
  },
  async deleteImages({ dispatch, commit }, { item, ids }) {
    return axios
      .delete(`${apiUrl}storage`, { data: { ids } })
      .then(({ data }) => {
        const imagesToRemove = (data.deleted || []).map((i) => i.id);
        commit("removeImages", { item, imageIds: imagesToRemove });
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async updateItemTask({ commit }, { item, id, body }) {
    commit("updatingTask", true);
    if (typeof body?.statusId === "string") {
      commit("toggleTaskUpdatingStatus", {
        item,
        taskId: id,
        isUpdatingStatus: true,
      });
    }
    return axios
      .put(`${apiUrl}task/${id}`, { ...body })
      .then(async ({ data }) => {
        const task = data?.task;
        if (task) {
          task.member = task?.members[0] || null;
          commit("updateTask", { item, task });
        }
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("toggleTaskUpdatingStatus", {
          item,
          taskId: id,
          isUpdatingStatus: false,
        });
        commit("updatingTask", false);
      });
  },
  async deleteItemTask({ commit }, { item, id }) {
    return axios
      .delete(`${apiUrl}task/${id}`)
      .then(async ({ data }) => {
        const task = data?.task;
        if (task) {
          commit("removeTask", { item, task });
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
};

const mutations = {
  setItems(state, items) {
    state.items = items;
  },
  addItem(state, item) {
    state.items.push(item);
  },
  removeItem(state, id) {
    const index = state.items.findIndex((x) => x.id === id);
    if (index !== -1) {
      state.items.splice(index, 1);
    }
  },
  updateItem(state, item) {
    const index = state.items.findIndex((x) => x.id === item.id);
    if (index !== -1) {
      item.images = state.items[index].images;
      state.items.splice(index, 1, { ...state.items[index], ...item });
    }
  },
  addImage(state, { item, imageUrl, imageId }) {
    const index = state.items.findIndex((x) => x.id === item.id);
    if (index !== -1 && Array.isArray(state.items[index].images)) {
      const imageIndex = state.items[index].images.findIndex((x) => x.id === imageId);
      if (imageIndex !== -1) {
        state.items[index].images.splice(imageIndex, 1, {
          ...state.items[index].images[imageIndex],
          url: imageUrl,
        });
      } else {
        state.items[index].images.push({ id: imageId, url: imageUrl });
      }
    }
  },
  removeImages(state, { item, imageIds }) {
    const index = state.items.findIndex((x) => x.id === item.id);
    if (index !== -1 && Array.isArray(state.items[index].images)) {
      for (let imageId of imageIds) {
        const imageIndex = state.items[index].images.findIndex((x) => x.id === imageId);
        if (imageIndex !== -1) {
          state.items[index].images.splice(imageIndex, 1);
        }
      }
    }
  },
  addTask(state, { item, task }) {
    const index = state.items.findIndex((x) => x.id === item.id);
    if (index !== -1) {
      state.items[index].tasks.push(task);
    }
  },
  removeTask(state, { item, task }) {
    const index = state.items.findIndex((x) => x.id === item.id);
    if (index !== -1) {
      const taskIndex = state.items[index].tasks.findIndex((x) => x.id === task.id);
      if (taskIndex !== -1) {
        state.items[index].tasks.splice(taskIndex, 1);
      }
    }
  },
  updatingTask(state, isUpdating) {
    state.isUpdatingTask = isUpdating;
  },
  updateTask(state, { item, task }) {
    const index = state.items.findIndex((x) => x.id === item.id);
    if (index !== -1) {
      const taskIndex = state.items[index].tasks.findIndex((x) => x.id === task.id);
      state.items[index].tasks.splice(taskIndex, 1, task);
    }
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  toggleImagesLoading(state, { item, isLoading }) {
    const index = state.items.findIndex((x) => x.id === item.id);
    if (index !== -1) {
      state.items[index].imagesLoading = isLoading;
    }
  },
  toggleImagesUploading(state, { item, isUploading }) {
    const index = state.items.findIndex((x) => x.id === item.id);
    if (index !== -1) {
      state.items[index].imagesUploading = isUploading;
    }
  },
  toggleTaskUpdatingStatus(state, { item, taskId, isUpdatingStatus }) {
    const index = state.items.findIndex((x) => x.id === item.id);
    if (index !== -1) {
      const taskIndex = state.items[index].tasks.findIndex((x) => x.id === taskId);
      if (taskIndex !== -1) {
        state.items[index].tasks.splice(taskIndex, 1, {
          ...state.items[index].tasks[taskIndex],
          isUpdatingStatus,
        });
      }
    }
  },
  creating(state, isCreating) {
    state.isCreating = isCreating;
  },
};

export const formItems = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
