<template>
  <tr class="list_tr" :class="highlightedClass" @contextmenu="onMenuClick">
    <td class="text-start">
      <div class="table-cell-content__breakable-long-text">
        {{ contact.fullName }}
      </div>
    </td>
    <td class="text-start">
      <div class="table-cell-content__breakable-long-text">
        {{ clientName }}
      </div>
    </td>
    <td class="text-start">
      <AppLabelBtn
        @click="(e) => $emit('jobTitleLabel:click', { pointerEvent: e, item: contact })"
        :title="jobTitle"
        :clickable="canEditLabels && !projectId"
        :isUpdating="contact?.isUpdatingLabel"
      />
    </td>
    <td class="text-start" v-if="!!projectId">
      <AppLabelBtn
        @click="
          (e) => $emit('responsibilityLabel:click', { pointerEvent: e, item: contact })
        "
        :title="responsibility"
        :clickable="canEditLabels"
        :isUpdating="contact?.isUpdatingRelationLabel"
      />
    </td>
    <td class="text-start">
      <AppPhoneLink :phone="contact.phone" />
    </td>

    <td class="text-start">
      <AppLinkText :link="`mailto:${contact.email}`" underline-on-hover>
        {{ contact.email }}
      </AppLinkText>
    </td>
    <td class="text-start">
      <div class="d-flex align-center">
        <v-checkbox
          :disabled="!!projectId"
          :inputValue="contact.isActive"
          @change="
            updateContact({ id: contact.id, body: { isActive: $event }, snack: true })
          "
        />
      </div>
    </td>
    <td class="text-end" v-if="!projectId">
      <v-btn icon @click="onMenuClick">
        <v-icon>{{ $icon.REGULAR.ACTION.MENU }}</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    contact: Object,
    jobTitleLabel: Object,
    responsibilityLabel: Object,
    isHighlighted: Boolean,
    canEditLabels: Boolean,
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    highlightedClass() {
      return this.isHighlighted ? "list_tr--selected" : "";
    },
    clientName() {
      return this.contact?.client?.name;
    },
    jobTitle() {
      return this.jobTitleLabel?.name;
    },
    responsibility() {
      return this.responsibilityLabel?.name;
    },
  },
  methods: {
    onMenuClick(event) {
      this.$emit("menu:click", { contact: this.contact, event });
    },
    updateContact(e) {
      this.$emit("contact:update", e);
    },
  },
};
</script>
