<script>
import { VCol, VRow, VIcon, VBtn, VSpacer } from "vuetify/lib";
import SigningDateField from "./SigningDateField.vue";

export default {
  props: {
    signatures: Array,
    isCreatingSignature: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    isNoSignatures() {
      return this.signatures.length === 0;
    },
  },
  methods: {
    updateSignature({ id, field, value }) {
      this.$emit("signature:update", { id, body: { [field]: value } });
    },
    createSignature() {
      this.$emit("signature:create");
    },
    deleteSignature(signature) {
      this.$emit("signature:delete", signature.id);
    },
    genSignatures() {
      return this.$createElement("div", { class: "d-flex flex-column" }, [
        this.$createElement(
          "div",
          { class: "text-h5 font-weight-bold pb-5" },
          this.$t("formManagement.signature.completionTitle"),
        ),
        this.genSignatureRows(),
        this.isNoSignatures && this.genAddSignatureBtn(),
      ]);
    },
    genSignatureRows() {
      return this.signatures.map((signature) => this.genSignatureRow(signature));
    },
    genSignatureRow(item) {
      return this.$createElement("div", [
        this.genInputRow(item),
        this.genSignaureField(item),
        this.genActionRow(item),
      ]);
    },
    genInputRow(item) {
      return this.$createElement(VRow, { class: "pb-3" }, [
        this.$createElement(VCol, { props: { cols: 8 } }, [
          this.$createElement(
            "div",
            { class: "font-weight-bold pb-1" },
            this.$t("formManagement.signature.fullName"),
          ),
          this.$createElement("AppDefaultTextField", {
            props: { value: item.name, dense: false, hideDetails: true },
            on: {
              blur: (e) => {
                this.updateSignature({
                  id: item.id,
                  field: "name",
                  value: e?.target?.value,
                });
              },
              input: (e) => {
                this.$emit("signature:input", { signature: item, value: e });
              },
            },
          }),
        ]),
        this.$createElement(VCol, [
          this.$createElement(
            "div",
            { class: "font-weight-bold pb-1" },
            this.$t("formManagement.signature.date"),
          ),
          this.$createElement(SigningDateField, {
            props: { date: item.date },
            on: {
              "date:change": (e) =>
                this.updateSignature({ id: item.id, field: "date", value: e }),
            },
          }),
        ]),
      ]);
    },
    genSignaureField(item) {
      switch (item.type) {
        case "TEXT": {
          return this.genTextSignatureField(item);
        }
        case "SIGNATURE": {
          return this.genb64SignatureField(item);
        }
      }
    },
    genTextSignatureField(item) {
      return this.$createElement(
        "div",
        {
          style: "min-height: 140px;",
          class: "border-a rounded",
        },
        [
          this.$createElement(
            "div",
            {
              style: "min-height: 140px;",
              class: "w-100 input-background d-flex justify-center align-center rounded",
            },
            [
              this.$createElement(
                "span",
                {
                  class: `text-center ${item.name ? "signature-text" : "text-body-1"}`,
                },
                item.name ||
                  this.$t("formManagement.signature.signatureFieldPlaceholderText"),
              ),
            ],
          ),
        ],
      );
    },
    genb64SignatureField(item) {
      return this.genTextSignatureField(item);
    },
    genActionRow(item) {
      const isLast = this.signatures.indexOf(item) === this.signatures.length - 1;
      return this.$createElement("div", { class: "d-flex py-3" }, [
        isLast && this.genAddSignatureBtn(item),
        this.$createElement(VSpacer),
        this.$createElement("div", [
          this.genIconButton({
            onPress: () => this.deleteSignature(item),
            icon: this.$icon.LIGHT.ACTION.DELETE,
            color: "error",
            text: this.$t("formManagement.signature.deleteSignature"),
          }),
        ]),
      ]);
    },
    genAddSignatureBtn() {
      return this.$createElement("div", [
        this.genIconButton({
          onPress: () => this.createSignature(),
          loading: this.isCreatingSignature,
          icon: this.$icon.LIGHT.ACTION.CIRCLE_PLUS,
          text: this.$t("formManagement.signature.addSignature"),
        }),
      ]);
    },
    genIconButton({ onPress, loading, icon, color, text }) {
      return this.$createElement("div", [
        this.$createElement(
          VBtn,
          {
            props: { text: true, loading, color },
            on: {
              click: (e) => onPress(),
            },
          },
          [
            this.$createElement(VIcon, { class: "pr-3" }, icon),
            this.$createElement("span", text),
          ],
        ),
      ]);
    },
  },
  render(h) {
    return this.genSignatures();
  },
};
</script>
