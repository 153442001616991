<template>
  <div class="d-flex flex-column">
    <AppMenuItem
      v-for="(status, index) in statuses"
      :key="index"
      :text="status.text"
      :icon="$icon.SOLID.ACTION.STATUS"
      :iconColor="statusMixins_statusColor(status.value, isLoading, statuses)"
      :isSelected="isSelected(status)"
      :disabled="isItemDisabled(status.value)"
      @click="selectItem(status)"
      large-icon
    />
  </div>
</template>

<script>
import { statusMixins } from "@/helpers/mixins";
export default {
  mixins: [statusMixins],
  props: {
    statuses: Array,
    isLoading: Boolean,
    itemsDisabled: Object,
    status: {
      type: String,
      default: null,
    },
  },
  methods: {
    isItemDisabled(key) {
      if (!this.itemsDisabled) return;
      return this.itemsDisabled[key];
    },
    selectItem(status) {
      if (!this.isSelected(status)) {
        this.$emit("change", status.value);
      }
    },
    isSelected(status) {
      return this.status === status.value;
    },
  },
};
</script>
