<template>
  <v-menu offset-y :transition="transition">
    <template v-slot:activator="{ on, attrs }">
      <v-btn depressed text class="ml-1" v-bind="attrs" v-on="on">
        {{ $t("common.more") }}
        <v-icon class="ml-2">{{ $icon.SOLID.ACTION.DROPDOWN }}</v-icon>
      </v-btn>
    </template>
    <div class="pa-3 ui-background d-flex flex-column" style="min-width: 70px">
      <slot />
    </div>
  </v-menu>
</template>

<script>
export default {
  props: {
    transition: {
      default: "slide-y-transition",
      type: String,
    },
  },
};
</script>
