<template>
  <div ref="previewContainer">
    <AppCenterLoader
      v-model="isLoading"
      :title="$t('common.loading')"
      :height="'calc(100% - 68px)'"
    />
    <template v-if="!isLoading">
      <template v-if="showPreview">
        <AppPdfPreview
          v-if="isPdf"
          :name="name"
          :content="item.content"
          :canEditFile="canEditFile"
          :isLinkedFile="isLinkedFile"
        />
        <AppImagePreview
          v-else-if="isImage"
          :item="item"
          @preview:update="$emit('preview:update', $event)"
          :canEditFile="canEditFile"
          :isLinkedFile="isLinkedFile"
        />
      </template>
      <template v-else-if="invalidPreview">
        <v-row class="fill-height" align-content="center" justify="center">
          <v-col class="subtitle-1 text-center" cols="12">
            <h2>{{ $t("fileManagement.preview.notSupported.title") }}</h2>
          </v-col>
          <v-col v-if="canDownloadFile" class="subtitle-1 text-center" cols="12">
            {{ $t("fileManagement.preview.notSupported.subTitle") }}
          </v-col>
          <v-col v-if="canDownloadFile" class="subtitle-1 text-center" cols="12">
            <v-btn color="primary" :loading="isDownloading" @click="onDownload" depressed>
              {{ $t("common.downloadFile") }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    isValid: Boolean,
    isLoading: Boolean,
    isDownloading: Boolean,
    height: String,
    canEditFile: Boolean,
    canDownloadFile: Boolean,
    isLinkedFile: Boolean,
    name: String,
  },
  data() {
    return {};
  },
  computed: {
    isPdf() {
      return !!this.item?.contentType?.match("application.pdf");
    },
    isImage() {
      return !!this.item?.contentType?.match("image/*");
    },
    showPreview() {
      return this.item ? (this.item.content ? true : false) : false && this.isValid;
    },
    invalidPreview() {
      return this.item && !this.isValid && !this.isLoading;
    },
  },
  methods: {
    onDownload() {
      if (this.item) {
        this.$emit("item:download", { item: this.item });
      }
    },
  },
};
</script>
