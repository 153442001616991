<template>
  <div>
    <AppClientDialog
      v-model="dialog.updateClient"
      :data="selectedItem"
      :title="$t('contact.client.updateClient.title')"
      @confirm="afterClientUpdate"
      :confirmText="$t('common.save')"
    />
    <AppDeleteConfirmationDialog
      v-model="dialog.deleteClient"
      :title="$t('contact.client.deleteClient.title')"
      :subtitle="$t('contact.client.deleteClient.subTitle')"
      :validator="$t('common.delete').toLowerCase()"
      :validatorText="
        $t('contact.client.deleteClient.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      :item="selectedItem"
      @delete="deleteClient"
    />
    <AppDataTableServerSidePagination
      :loading="isLoading"
      :headers="_headers"
      :items="clients"
      :serverItemsLength="serverItemsLength"
      :tableModel="tableModel"
      disable-multi-select
      :noDataText="$t('common.noClients')"
      :columnsToFreeze="1"
    >
      <template v-slot:item="{ item }">
        <AppClientTableItem
          :client="item"
          :isHighlighted="isHighlighted(item)"
          @client:update="updateClient"
          @menu:click="onMenuClick"
        />
      </template>
    </AppDataTableServerSidePagination>
    <AppContextMenu ref="contextMenu" @input="isContextMenuOpen = $event">
      <AppMenuEditBtn @click="onClientEdit" :disabled="!!projectId" />
      <AppMenuDeleteBtn
        :disabled="!canDeleteSelectedItem || !!projectId"
        @click="onClientDelete"
      />
    </AppContextMenu>
  </div>
</template>

<script>
export default {
  props: {
    clients: Array,
    isLoading: Boolean,
    serverItemsLength: Number,
    tableModel: String,
  },
  data() {
    return {
      dialog: {
        updateClient: false,
        deleteClient: false,
      },
      selectedItem: null,
      isContextMenuOpen: false,
    };
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    canDeleteSelectedItem() {
      return this.selectedItem?.canDelete;
    },
    dataFields() {
      return this.$constant.TABLE_HEADER[this.tableModel];
    },
    _headers() {
      return this.headers.filter((header) => header.show);
    },
    headers() {
      return [
        {
          text: this.$t("contact.client.list.clientName"),
          value: this.dataFields.NAME,
          show: true,
        },
        {
          text: this.$t("common.orgnr"),
          value: this.dataFields.ORGANIZATION_NUMBER,
          show: true,
          sortable: false,
        },
        {
          text: this.$t("contact.client.list.responsible"),
          value: this.dataFields.OWNER,
          show: true,
        },
        {
          text: this.$t("common.type"),
          value: this.dataFields.TYPE,
          show: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("contact.client.list.phone")),
          value: this.dataFields.PHONE,
          show: true,
        },
        {
          text: this.$t("contact.client.list.email"),
          value: this.dataFields.EMAIL,
          show: true,
        },
        {
          text: this.$t("common.active"),
          value: this.dataFields.ACTIVE,
          show: true,
        },
        {
          text: "",
          sortable: false,
          show: true,
        },
      ];
    },
  },
  methods: {
    isHighlighted(item) {
      const isCurrentItem = this.selectedItem === item;
      if (!isCurrentItem) return false;

      const isAnyDialogsOpen = Object.values(this.dialog).some((value) => value === true);

      return this.isContextMenuOpen || isAnyDialogsOpen;
    },
    onMenuClick({ event, client }) {
      this.selectedItem = client;
      this.$refs.contextMenu.open({ pointerEvent: event });
    },
    onClientDelete() {
      this.dialog.deleteClient = true;
    },
    deleteClient(e) {
      this.$emit("client:delete", e);
    },
    onClientEdit() {
      this.dialog.updateClient = true;
    },
    updateClient(e) {
      this.$emit("client:update", e);
    },
    afterClientUpdate() {
      this.$emit("client:afterUpdate");
    },
  },
};
</script>
