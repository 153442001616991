<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-layout v-on="on" v-bind="attrs">
        <AppDefaultTextField
          class="date-field-cursor-pointer"
          :value="inputValue"
          :append-icon="$icon.LIGHT.COMMON.CALENDAR_DAY"
          :dense="false"
          hide-details
          readonly
        />
      </v-layout>
    </template>
    <v-date-picker
      show-week
      locale-first-day-of-year="4"
      v-model="localDate"
      scrollable
      no-title
      :first-day-of-week="dateMixins_firstDayOfWeek"
      @change="onSaveDate"
    />
  </v-menu>
</template>

<script>
import moment from "moment";
import { dateMixins } from "@/helpers/mixins";

export default {
  mixins: [dateMixins],
  props: {
    date: String,
  },
  data() {
    return {
      localDate: null,
      menu: false,
    };
  },
  watch: {
    date: {
      handler(date) {
        this.localDate = date;
      },
      immediate: true,
    },
  },
  computed: {
    inputValue() {
      return this.date ? moment(this.date).format("DD.MM.YYYY") : "";
    },
  },
  methods: {
    onSaveDate() {
      this.menu = false;
      this.$emit("date:change", this.localDate);
    },
  },
};
</script>
