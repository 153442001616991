import { withPrefix } from "../util/icon";

export const SOLID = withPrefix("solid", {
  //if only action. icons closely tied to actions
  ROUTE: {
    SETTINGS: "cog",
    TEMPLATE: "clone",
    CMS: "person-hiking",
    PROJECT: "lightbulb-on",
  },
  ACTION: {
    DROPDOWN: "caret-down",
    GO_NEXT_LEVEL: "circle-arrow-right",
    GO_PREV_LEVEL: "circle-arrow-left",
    LOADING: "spinner fa-spin",
    MINUS: "minus",
    PLUS: "plus",
    CLOSE: "xmark",
    INFO: "circle-info",
    WARNING: "triangle-exclamation",
    ERROR: "octagon-exclamation",
    DRAG: "grip-dots-vertical",
    STATUS: "square",
    NO_STATUS: "$solidSquareSlash",
    CUSTOM_COLOR: "eye-dropper",
    ADD: "plus",
  },
  //should correspond with fontawesome icon name
  COMMON: {
    STAR: "star",
    CIRCLE_CHECK: "circle-check",
    SQUARE: "square",
    SQUARE_CHECK: "square-check",
    SQUARE_MINUS: "square-minus",
    CIRCLE_DOT: "circle-dot",
    PAPER_PLANE: "paper-plane",
    CIRCLE: "circle",
    STAR: "star",
    CLOCK: "clock",
    LOCK: "lock",
    USER: "user",
    CIRCLE: "circle",
    DICE: "dice",
  },
});
