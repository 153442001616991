<template>
  <v-data-table
    ref="dataTable"
    :class="`elevation-0 overflow-hidden border-a fix-loader-visibility-on-data-table ${stickyColsClass}`"
    hide-default-footer
    fixed-header
    v-bind="$attrs"
    v-on="$listeners"
    :items="hideItems ? [] : items"
    :options="localOptions"
    :server-items-length="serverItemsLength"
    :loadingText="$t('common.loading')"
    @update:options="onOptionsUpdate"
    @page-count="pageCount = $event"
  >
    <template
      v-slot:[`body.prepend`]="{ headers }"
      v-if="!disableMultiSelect && isAllItemsOnPageSelected"
    >
      <td
        :colspan="headers.length"
        style="position: sticky; left: 0; top: 48px; z-index: 2"
      >
        <div
          style="height: 48px"
          class="w-100 d-flex align-center justify-center select-all--background text-body-2 border-b--table"
        >
          {{ $tc("dataTable.allRowsHasBeenSelected", items.length) }}
          <v-btn
            @click="selectAllClick"
            depressed
            small
            text
            color="primary-link"
            class="text-transform-none"
            :loading="isSelectingAll"
            :disabled="$attrs.loading"
          >
            {{ $tc("dataTable.selectAll", serverItemsLength) | capitalize }}
          </v-btn>
        </div>
      </td>
    </template>
    <template
      v-if="!disableMultiSelect"
      v-slot:[`header.data-table-select`]="{ props, on }"
    >
      <v-simple-checkbox color="primary" v-ripple v-bind="props" v-on="on" />
    </template>
    <template v-slot:footer>
      <div
        ref="dataTableFooter"
        class="py-1 border-t p-relative d-flex align-center justify-center"
      >
        <v-pagination
          v-if="localOptions?.page"
          class="pagination-items-remove-box-shadow"
          v-model="localOptions.page"
          :length="pageCount"
          total-visible="7"
        />
        <div class="p-absolute" style="right: 0; min-width: 65px">
          <AppMinimalisticSelect
            :value="localOptions?.itemsPerPage"
            :items="itemsPerPageItems"
            @change="onItemsPerPageChange"
          />
        </div>
      </div>
    </template>
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="item">
      <slot :name="slot" v-bind="item"></slot>
    </template>
  </v-data-table>
</template>

<script>
import { filtersMixins } from "@/helpers/mixins";
import _ from "lodash";

export default {
  mixins: [filtersMixins],
  props: {
    items: Array,
    serverItemsLength: Number,
    isSelectingAll: Boolean,
    tableModel: String,
    disableMultiSelect: Boolean,
    columnsToFreeze: Number,
    groupHeaderColumnsToFreeze: Number,
  },
  data() {
    return {
      localOptions: null,
      pageCount: 0,
      hideItems: false,
      resizeObserver: null,
      isInitialSetup: true, //for preventing excessive requests during component setup
    };
  },
  watch: {
    filtersMixins_dataTableOptions: {
      handler(newDataTableOptions, oldDataTableOptions) {
        this.localOptions = JSON.parse(JSON.stringify(newDataTableOptions));

        if (!_.isEqual(newDataTableOptions?.groupBy, oldDataTableOptions?.groupBy)) {
          this.hideItems = true;
        }
      },
      deep: true,
    },
    ["$attrs.loading"]: {
      handler(val) {
        if (!val) {
          this.hideItems = false;
        }
      },
    },
    serverItemsLength: {
      handler() {
        if (
          this.localOptions?.page > this.lastPageWithItems &&
          this.serverItemsLength !== null
        ) {
          this.filtersMixins_updateFilters({
            dataTableOptions: { ...this.localOptions, page: this.lastPageWithItems },
          });
        }
      },
    },
    ["filtersMixins_stickyCols"]: {
      handler(val) {
        if (val) {
          this.calculateColWidthVariables();
        }
      },
    },
    ["localOptions.page"]: {
      handler(newVal) {
        if (newVal !== this.currentPage) {
          this.$router.replace({ query: { ...this.$route.query, page: newVal } });
        }
      },
    },
  },
  computed: {
    currentPage() {
      return parseInt(this.$route.query.page);
    },
    stickyColsClass() {
      if (!this.filtersMixins_stickyCols || !this.items?.length || this.hideItems)
        return "";

      let stickyClasses = [];

      for (let i = 0; i < this.columnsToFreeze; i++) {
        stickyClasses.push(`column-${i + 1}-sticky`);
      }

      stickyClasses.push(`data-table-sticky-column-${this.columnsToFreeze}-border`);

      if (this.hasGroupBy && this.groupHeaderColumnsToFreeze) {
        for (let i = 0; i < this.groupHeaderColumnsToFreeze; i++) {
          stickyClasses.push(`group-header-column-${i + 1}-sticky`);
        }
        stickyClasses.push("group-header-z-index");
      }

      return stickyClasses.join(" ");
    },
    hasGroupBy() {
      return !!this.filtersMixins_dataTableOptions.groupBy?.length;
    },

    itemsPerPageItems() {
      return Array.from(
        new Set([this.localOptions?.itemsPerPage, ...this.$constant.TABLE_ITEM_PER_PAGE]),
      ).sort((a, b) => a - b);
    },
    lastPageWithItems() {
      return Math.ceil(this.serverItemsLength / this.localOptions?.itemsPerPage) || 1;
    },
    isAllItemsOnPageSelected() {
      const selectedItems = this.$attrs.value;
      //no items or no selected items
      if (selectedItems.length === 0 || this.items.length === 0) {
        return false;
      }

      for (let i = 0; i < this.items.length; i++) {
        if (!selectedItems.some((item) => item.id === this.items[i].id)) {
          return false;
        }
      }
      if (this.serverItemsLength === selectedItems.length) return false;
      return true;
    },
    hasItems() {
      return this.itemsLength > 0;
    },
    itemsLength() {
      return this.items?.length;
    },
  },
  methods: {
    onItemsPerPageChange(event) {
      this.localOptions = { ...this.localOptions, itemsPerPage: event };
      if (this.localOptions?.page > this.lastPageWithItems) {
        this.filtersMixins_updateFilters({
          dataTableOptions: {
            ...this.localOptions,
            page: this.lastPageWithItems,
          },
        });
      }
    },
    selectAllClick() {
      this.$emit("selectAll:click");
    },
    onOptionsUpdate(event) {
      if (this.isInitialSetup) {
        // Allow the initial setup to complete without triggering updates
        return;
      }

      if (_.isEqual(event, this.filtersMixins_dataTableOptions)) return;

      this.filtersMixins_updateFilters({ dataTableOptions: event });
    },
    calculateLayout() {
      this.$nextTick(() => {
        this.updateDataTableHeight();
        this.calculateColWidthVariables();
      });
    },
    updateDataTableHeight() {
      const dataTableWrapper = this.$refs.dataTable?.$el.querySelector(
        ".v-data-table__wrapper",
      );
      if (dataTableWrapper) {
        dataTableWrapper.style.maxHeight = this.calcDataTableMaxHeight();
      }
    },
    calcDataTableMaxHeight() {
      if (this.height) return;
      const pixelsFromTopOfPageToTable =
        this.$refs.dataTable?.$el.getBoundingClientRect().top;
      const tableFooterHeight = this.$refs.dataTableFooter?.offsetHeight || 0;
      const extraPixelsToAccountFor = 30;
      const heightToRemove =
        pixelsFromTopOfPageToTable + tableFooterHeight + extraPixelsToAccountFor;

      const maxHeightStyle = `calc(100vh - ${heightToRemove}px)`;
      return maxHeightStyle;
    },
    calculateColWidthVariables() {
      if (!this.items?.length || !this.filtersMixins_stickyCols) return;

      setTimeout(() => {
        const dataTable = this.$refs.dataTable?.$el;
        if (!dataTable) return;

        const getColWidth = (trSelector, colNum) => {
          const col = dataTable.querySelector(
            `table > tbody > tr.${trSelector} > td:nth-child(${colNum})`,
          );
          return col?.offsetWidth;
        };

        const setVariable = (variable, amount) => {
          dataTable.style.setProperty(variable, `${amount}px`);
        };

        const calculateAndSetWidths = (selector, variableName) => {
          const firstColWidth = getColWidth(selector, 1);
          setVariable(`--${variableName}-first-column-width`, firstColWidth);

          const firstTwoColsWidth = getColWidth(selector, 2) + firstColWidth;
          setVariable(`--${variableName}-first-two-columns-width`, firstTwoColsWidth);

          const firstThreeColsWidth = getColWidth(selector, 3) + firstTwoColsWidth;
          setVariable(`--${variableName}-first-three-columns-width`, firstThreeColsWidth);
        };

        calculateAndSetWidths("list_tr", "list");
        if (!this.hasGroupBy) return;
        calculateAndSetWidths("v-row-group__header", "group-header");
      }, 50);
    },
    initResizeObserver() {
      const tableElement = this.$refs.dataTable?.$el; // Assuming you have a ref="table" on your <table>
      if (tableElement) {
        // debounce the calculation of the layout to avoid performance issues
        this.calculateLayout();
        const debouncedCalculateLayout = _.debounce(() => {
          this.calculateLayout();
        }, 100);

        this.resizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            debouncedCalculateLayout();
          }
        });
        this.resizeObserver.observe(tableElement);
      }
    },
    destroyResizeObserver() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
        this.resizeObserver = null;
      }
    },
    onInit() {
      this.initResizeObserver();
      if (
        this.filtersMixins_dataTableOptions &&
        Object.keys(this.filtersMixins_dataTableOptions).length
      ) {
        this.localOptions = JSON.parse(
          JSON.stringify(this.filtersMixins_dataTableOptions),
        );
      }
      this.finishInitialSetup();
    },
    finishInitialSetup() {
      this.$nextTick(() => {
        this.isInitialSetup = false;
      });
    },
  },
  mounted() {
    this.onInit();
  },
  beforeDestroy() {
    this.destroyResizeObserver();
  },
  destroyed() {
    this.destroyResizeObserver();
  },
};
</script>

<style>
.z-index-0 * > .v-row-group__header {
  z-index: 0 !important;
}
</style>
