<template>
  <div>
    <AppContextMenu
      ref="menu"
      min-width="200px"
      max-width="400px"
      max-height="400px"
      :closeOnClick="!addLabelDialog.active && !updateLabelDialog.active"
    >
      <v-list-item
        dense
        class="ui-background"
        @click="onLabelChange({ id: null })"
        :class="!selectedLabel?.id ? 'v-list-item--active' : ''"
        :readOnly="!selectedLabel?.id"
      >
        <v-list-item-title class="text-body-2">
          {{ $t(!selectedLabel?.id ? "common.noneSelected" : "common.clearSelection") }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        dense
        class="ui-background d-flex justify-center"
        v-if="isLoading && !labels.length"
      >
        <v-icon>{{ $icon.SOLID.ACTION.LOADING }}</v-icon>
      </v-list-item>
      <div v-else>
        <v-list-item
          dense
          v-for="label of labels"
          :key="label.id"
          class="ui-background"
          :class="label.id === selectedLabel?.id ? 'v-list-item--active' : ''"
          @click.stop="onLabelChange(label)"
        >
          <v-list-item-content class="text-body-2">
            {{ label.name }}
          </v-list-item-content>
          <v-btn small icon @click.stop="onEditLabel(label)">
            <v-icon small>{{ $icon.LIGHT.ACTION.EDIT }}</v-icon>
          </v-btn>
        </v-list-item>
      </div>
      <AppSelectAppendCreateBtn dense @click.stop="onAddLabel">
        {{ $t(`${translationPath}.createLabel`) }}
      </AppSelectAppendCreateBtn>
    </AppContextMenu>

    <AppLabelDialog
      v-model="addLabelDialog.active"
      :type="labelType"
      :title="$t(`${translationPath}.addLabelDialog.title`)"
      :confirmText="$t('common.create')"
    />
    <AppLabelDialog
      v-model="updateLabelDialog.active"
      :data="updateLabelDialog.data"
      :type="labelType"
      :title="$t(`${translationPath}.updateLabelDialog.title`)"
      :confirmText="$t('common.save')"
      :deleteTitle="$t(`${translationPath}.updateLabelDialog.deleteLabelDialog.title`)"
      :deleteSubtitle="
        $t(`${translationPath}.updateLabelDialog.deleteLabelDialog.subTitle`)
      "
    />
  </div>
</template>

<script>
import { labelMixins } from "@/helpers/mixins";
import { mapGetters } from "vuex";

export default {
  props: {
    labelType: String,
    selectedItemLabelPath: String,
  },
  mixins: [labelMixins],
  data() {
    return {
      addLabelDialog: {
        active: false,
      },
      updateLabelDialog: {
        active: false,
        data: null,
      },
      selectedItem: null,
    };
  },
  computed: {
    ...mapGetters("label", {
      labelsByType: "labelsByType",
      isLoadingByType: "isLoadingByType",
    }),
    labels() {
      return this.labelsByType(this.labelType);
    },
    isLoading() {
      return this.isLoadingByType(this.labelType);
    },
    selectedLabel() {
      if (!this.selectedItem) return null;
      const selectedLabel = this.selectedItemLabelPath
        .split(".")
        .reduce((accumulator, key) => {
          return accumulator?.[key];
        }, this.selectedItem);

      return selectedLabel;
    },
    translationPath() {
      return this.labelMixins_translationPath(this.labelType);
    },
  },
  methods: {
    open({ pointerEvent, x, y, data }) {
      this.getLabels();
      this.setData(data);
      this._open({ pointerEvent, x, y });
    },
    setData(data) {
      const { selectedItem } = data;
      this.selectedItem = selectedItem;
    },
    _open({ pointerEvent, x, y }) {
      this.$refs.menu.open({ pointerEvent, x, y });
    },
    close() {
      this.$refs.menu.close();
    },
    onLabelChange(label) {
      this.$emit("label:change", {
        selectedItem: this.selectedItem,
        label: label,
      });
      this.close();
    },
    getLabels() {
      if (!this.labels) {
        this.$store
          .dispatch("label/getLabels", {
            labelType: this.labelType,
          })
          .finally(() => {
            this.afterGet();
          });
      }
    },
    afterGet() {
      this.$refs.menu.updateDimensions();
    },
    onAddLabel() {
      this.addLabelDialog.active = true;
    },
    onEditLabel(label) {
      this.updateLabelDialog.active = true;
      this.updateLabelDialog.data = label;
    },
  },
};
</script>
