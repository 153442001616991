<template>
  <AppWidgetLibraryItemTemplate
    :title="$t('widgetLibrary.addHseWidget.title')"
    :subTitle="$t('widgetLibrary.addHseWidget.subTitle')"
    :planCode="planCode"
    :showStar="showStar"
    icon="book-open-reader"
    :icon="$icon.LIGHT.COMMON.BOOK_OPEN_READER"
    @add="onAdd"
  />
</template>

<script>
export default {
  props: {
    planCode: String,
    showStar: Boolean,
  },
  methods: {
    onAdd() {
      this.$emit("add");
    },
  },
};
</script>
