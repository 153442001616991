<template>
  <!-- Truncates text based on x number of characters -->
  <!-- word-no-wrap by default but can take a parameter to break-word instead -->
  <div>
    <template v-if="shouldTruncate">
      <AppDefaultTooltip top :disabled="disabled">
        <template v-slot:activator="{ on, attrs }">
          <span :class="breakOrNoBreak" v-bind="attrs" v-on="on">
            {{ text | truncate(truncate) }}
          </span>
        </template>
        <span class="break-word">{{ text }}</span>
      </AppDefaultTooltip>
    </template>
    <span :class="breakOrNoBreak" v-else>
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    truncate: {
      type: [Number, String],
      default: 22,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    breakWord: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    shouldTruncate() {
      return this.truncate != 0 && this.truncate < this.text?.length;
    },
    breakOrNoBreak() {
      if (this.breakWord) return "break-word";
      return "text-no-wrap";
    },
  },
};
</script>
