<template>
  <v-dialog v-model="localDialog" scrollable max-width="600px">
    <v-card>
      <v-card-title>{{
        $tc("settings.products.bulkEditProducts.title", products.length)
      }}</v-card-title>
      <v-card-text>
        <div class="p-relative">
          <div class="label--small">
            {{ $t("settings.products.bulkEditProducts.selectProperty") }}
          </div>
          <AppDefaultSelect
            v-model="selectedProperty"
            :items="selectableProductProperties"
            item-text="label"
            item-value="value"
          />
          <div v-if="hasSelectedStatus">
            <div class="label--small">
              {{ $t("common.productFields.status") }}
            </div>
            <AppDefaultSelect
              :items="selectableStatuses"
              v-model="selectedStatus"
              item-text="label"
              item-value="value"
            />
          </div>
          <div v-if="hasSelectedUnit">
            <div class="label--small">
              {{ $t("common.productFields.unit") }}
            </div>
            <AppDefaultSelect
              :items="units"
              :loading="isUnitsLoading"
              v-model="selectedUnit"
              item-text="label"
              item-value="value"
            />
          </div>
          <div v-if="hasSelectedSupplier">
            <div class="label--small">
              {{ $t("common.productFields.supplier") }}
            </div>
            <AppClientAutocomplete
              v-model="selectedClientId"
              class="pb-5"
              hide-details
              isReadyToInitialFetch
              :type="$constant.SUPPLIER"
              :queryParams="{
                active: true,
                alwaysIncludeIds: [selectedClientId],
              }"
              @created="onClientCreated"
            />
          </div>
          <div v-if="hasSelectedUnitPrice">
            <div class="label--small">
              {{ $t("common.productFields.unitPrice") }}
            </div>
            <AppCurrencyTextField v-model="selectedUnitPrice" />
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onSubmit"
          @cancel="onCancel"
          :disabled="isDisabledConfirmButton"
          :loading="isUpdatingProducts"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    dialog: Boolean,
    products: Array,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  watch: {
    selectedProperty: {
      handler() {
        this.resetProperties();
      },
    },
    hasSelectedUnit: {
      handler(val) {
        if (val) {
          this.getUnits();
        }
      },
    },
  },
  data() {
    return {
      selectableProductProperties: [
        {
          label: this.$t("common.productFields.supplier"),
          value: "supplier",
        },
        {
          label: this.$t("common.productFields.unit"),
          value: "unit",
        },
        {
          label: this.$t("common.productFields.unitPrice"),
          value: "unitPrice",
        },
        {
          label: this.$t("common.productFields.status"),
          value: "isActive",
        },
      ],
      selectedProperty: null,
      selectableStatuses: [
        {
          label: this.$t("common.active"),
          value: true,
        },
        {
          label: this.$t("common.inactive"),
          value: false,
        },
      ],
      selectedStatus: null,
      selectedUnit: null,
      selectedUnitPrice: null,
      selectedClientId: null,
      isUpdatingProducts: false,
    };
  },
  computed: {
    ...mapState("productUnits", {
      units: (state) => state.units,
      isUnitsLoading: (state) => state.isLoading,
    }),
    isDisabledConfirmButton() {
      return !(
        this.selectedStatus !== null ||
        this.selectedStatus !== undefined ||
        !!this.selectedUnit ||
        !!this.selectedUnitPrice ||
        !!this.selectedClientId
      );
    },
    hasSelectedStatus() {
      return this.selectedProperty === "isActive";
    },
    hasSelectedUnit() {
      return this.selectedProperty === "unit";
    },
    hasSelectedUnitPrice() {
      return this.selectedProperty === "unitPrice";
    },
    hasSelectedSupplier() {
      return this.selectedProperty === "supplier";
    },
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  methods: {
    async getUnits() {
      await this.$store.dispatch("productUnits/getProductUnits");
    },
    closeDialog() {
      this.localDialog = false;
    },
    onClientCreated({ client }) {
      this.selectedClientId = client.id;
    },
    resetProperties() {
      this.selectedStatus = null;
      this.selectedUnit = null;
      this.selectedUnitPrice = null;
      this.selectedClientId = null;
    },
    makeBody() {
      const ids = this.products.map((product) => product.id);
      let body;
      if (this.hasSelectedStatus) {
        body = { isActive: this.selectedStatus };
      } else if (this.hasSelectedSupplier) {
        body = { clientId: this.selectedClientId };
      } else if (this.hasSelectedUnitPrice) {
        body = { unitPrice: parseInt(this.selectedUnitPrice) };
      } else if (this.hasSelectedUnit) {
        body = { unit: this.selectedUnit };
      }
      return { ...body, ids };
    },
    onSubmit() {
      const body = this.makeBody();
      this.updateProducts(body);
    },
    updateProducts(body) {
      this.isUpdatingProducts = true;
      this.$store
        .dispatch("products/updateProducts", { body })
        .then(() => {
          this.closeDialog();
          this.selectedProperty = null;
          this.resetProperties();
        })
        .finally(() => {
          this.isUpdatingProducts = false;
        });
    },
    onCancel() {
      this.closeDialog();
    },
  },
};
</script>
