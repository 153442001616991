<template>
  <tr class="list_tr" :class="highlightedClass" @contextmenu="onMenuClick">
    <td class="text-start">
      <div
        class="underline-on-hover cursor-pointer table-cell-content__breakable-long-text"
        @click="onTemplateEditClick"
      >
        {{ template.name }}
      </div>
    </td>
    <td>
      <AppColorChip
        :text="$t(template.isPublished ? 'common.published' : 'common.unpublished')"
        :color="template.isPublished ? 'success--text' : 'error--text'"
      />
    </td>
    <td v-if="!hideCategory">
      <AppColorChip
        v-if="template.category"
        :text="
          $te(`formManagement.category.${template.category.name}`)
            ? $t(`formManagement.category.${template.category.name}`)
            : template.category.name
        "
        color="info--text"
      />
    </td>
    <td class="text-start text-no-wrap">
      {{ new Date(template.updatedAt) | dateFormat("DD.MM.YYYY HH:mm") }}
    </td>
    <td class="text-end">
      <v-btn icon @click="onMenuClick">
        <v-icon>{{ $icon.REGULAR.ACTION.MENU }}</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    template: Object,
    isHighlighted: Boolean,
    hideCategory: Boolean,
  },
  computed: {
    highlightedClass() {
      return this.isHighlighted ? "list_tr--selected" : "";
    },
  },
  methods: {
    onTemplateEditClick() {
      this.$emit("edit:click", { template: this.template });
    },
    onMenuClick(event) {
      this.$emit("menu:click", { template: this.template, event });
    },
  },
};
</script>
