<template>
  <div>
    <AppNavigationList>
      <AppNavigationItem
        :to="{ name: $routeNames.PROJECT.INFO }"
        :prependIcon="$icon.REGULAR.ROUTE.OVERVIEW"
        :title="$t('drawer.route.overview')"
        prepend-icon-small
        replace
      />
      <AppNavigationItem
        :to="{ name: $routeNames.PROJECT.VIEW_MEMBERS }"
        :prependIcon="$icon.REGULAR.ROUTE.MEMBER"
        :title="$t('drawer.route.members')"
        prepend-icon-small
        replace
      />
      <AppNavigationItem
        :to="{ name: $routeNames.PROJECT.VIEW_CONTACTS }"
        :prependIcon="$icon.REGULAR.ROUTE.CONTACT"
        :title="$t('drawer.route.contacts')"
        prepend-icon-small
        replace
      />
      <AppNavigationItem
        v-if="isCommentFeatureEnabled"
        :to="{ name: $routeNames.PROJECT.VIEW_COMMENTS }"
        :prependIcon="$icon.REGULAR.ROUTE.INBOX"
        :title="$t('drawer.route.inbox')"
        prepend-icon-small
        replace
      />
      <AppNavigationItem
        :to="{ name: $routeNames.PROJECT.SUB_PROJECTS }"
        :prependIcon="$icon.REGULAR.ROUTE.SUB_PROJECT"
        :title="$t('drawer.route.subProjects')"
        prepend-icon-small
        replace
      >
        <template #append-item>
          <div v-if="isLoadingProjectDetails">
            <v-skeleton-loader
              class="skeleton-loader-text-custom-2"
              type="text"
              width="22"
            />
          </div>
          <div v-else class="text-body-2">({{ projectSubProjectsCount }})</div>
        </template>
      </AppNavigationItem>
    </AppNavigationList>
    <AppMiniContentSelectionDivider />
    <AppNavigationList>
      <AppContentSectionHeader>
        {{ $t("drawer.routeLabel.manage") }}
      </AppContentSectionHeader>
      <AppNavigationItem
        :to="{ name: $routeNames.PROJECT.VIEW_TASK }"
        :prependIcon="$icon.REGULAR.ROUTE.TASK"
        :title="$t('drawer.route.tasks')"
        prepend-icon-small
        replace
      />
      <AppNavigationItem
        :to="{ name: $routeNames.PROJECT.VIEW_FORM }"
        :prependIcon="$icon.REGULAR.ROUTE.FORM"
        :title="$t('drawer.route.forms')"
        prepend-icon-small
        replace
      />
      <AppNavigationItem
        :to="{ name: $routeNames.PROJECT.STORAGE }"
        :prependIcon="$icon.REGULAR.ROUTE.STORAGE"
        :title="$t('drawer.route.files')"
        prepend-icon-small
        replace
      />
      <AppNavigationItem
        :to="{ name: $routeNames.PROJECT.PRODUCTS }"
        :prependIcon="$icon.REGULAR.ROUTE.PRODUCT"
        :title="$t('drawer.route.products')"
        prepend-icon-small
        replace
      />
      <AppNavigationItem
        v-if="!isHighestPriorityRoleContact"
        :to="{ name: $routeNames.PROJECT.E_BYGGESOK }"
        :prependIcon="$icon.REGULAR.ROUTE.EBYGGESOK"
        :title="$t('drawer.route.buildingApplications')"
        prepend-icon-small
        replace
      />
    </AppNavigationList>
  </div>
</template>

<script>
import { getProjectDetails } from "@/services/project/projectService";
import { mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      isLoadingProjectDetails: false,
      projectDetails: null,
    };
  },
  watch: {
    projectId: {
      handler() {
        this.getProjectDetails();
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("auth", {
      activeDomainId: "activeDomainId",
      isCommentFeatureEnabled: "isCommentFeatureEnabled",
      isHighestPriorityRoleContact: "isHighestPriorityRoleContact",
    }),
    ...mapState("auth", {
      user: (state) => state.user,
    }),
    projectId() {
      return this.$route.params.projectId;
    },
    projectSubProjectsCount() {
      return this.projectDetails?.find((detail) => {
        return detail.projectId === this.projectId;
      })?.subProjectsCount;
    },
  },
  methods: {
    async getProjectDetails() {
      this.isLoadingProjectDetails = true;
      const params = {
        projectIds: [this.projectId],
        fields: ["subProjectsCount"],
      };
      getProjectDetails(params)
        .then((projectDetails) => {
          this.projectDetails = projectDetails;
        })
        .finally(() => {
          this.isLoadingProjectDetails = false;
        });
    },
  },
};
</script>
