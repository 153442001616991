<template>
  <v-dialog v-model="localDialog" max-width="600px" scrollable>
    <v-card v-if="isDeclined">
      <v-card-title class="headline">
        {{ $t("timeRegistration.seeDeclineReason.title") }}
      </v-card-title>
      <v-card-text>
        <div class="text-body-1 primary-text--text pb-6">
          {{
            $t("timeRegistration.seeDeclineReason.declinedBy", {
              name: declinedByName,
              date: date,
            })
          }}
        </div>

        <div class="text-body-2 font-italic primary-text--text pb-6">
          {{ declineComment }}
        </div>

        <div class="text-body-1 primary-text--text">
          {{ $t("timeRegistration.seeDeclineReason.resolve") }}
        </div>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          :show="false"
          @cancel="close"
          :cancelText="$t('common.close')"
        />
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-actions class="pa-4">
        <AppDialogActionBtnPair
          :show="false"
          @cancel="close"
          :cancelText="$t('common.close')"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";

export default {
  props: {
    dialog: Boolean,
    timeEntry: Object,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    isDeclined() {
      return this.timeEntry?.status === "DECLINED";
    },
    date() {
      return this.timeEntry?.date ? moment(this.timeEntry.date).format("DD.MM.YYYY") : "";
    },
    declinedByName() {
      return this.timeEntry?.statusChanger?.name;
    },
    declineComment() {
      return this.timeEntry?.declineComment;
    },
  },
  methods: {
    close() {
      this.localDialog = false;
    },
  },
};
</script>
