<script>
import Title from "./Title.vue";
import { VIcon, VBtn, VTreeview } from "vuetify/lib";
import StorageItem from "./StorageItem.vue";
export default {
  props: {
    storageItems: Array,
    storageTreeView: Array,
  },
  data() {
    return {
      opened: [],
    };
  },
  methods: {
    genTreeView() {
      if (this.storageTreeView.length === 0) return;
      return this.$createElement(VTreeview, {
        class: "border-a rounded-b-lg",
        props: {
          items: this.storageTreeView,
          openOnClick: true,
          openAll: true,
          transition: true,
          open: this.opened,
        },
        on: {
          "update:open": (e) => {
            this.opened = e;
          },
        },
        scopedSlots: {
          label: ({ item, open }) =>
            this.$createElement(StorageItem, {
              props: { item, open, storageItems: this.storageItems },
              on: {
                "item:createSubFolder": ({ item, type, parentId }) => {
                  this.createUnsavedStorageItem({ type, parentId });
                  this.openFolder(item);
                },
                "item:delete": (e) => this.$emit("item:delete", e),
                "button:click": (e) => this.$emit("button:click", e),
                "item:update": (e) => this.$emit("item:update", e),
              },
            }),
        },
      });
    },
    showBorderAndBorderRadiusIfNoItems() {
      return this.storageTreeView.length === 0 ? "border-b rounded-b-lg" : "";
    },
    createUnsavedStorageItem({ type, parentId = null }) {
      const data = {
        type: "STORAGE",
        parentId,
        values: { type },
      };
      this.$emit("item:createUnsavedItem", { data });

      setTimeout(() => {
        const item = this.storageItems[this.storageItems.length - 1];
        document.getElementById(`storageItemTextField_${item?.refId}`)?.focus();
      }, 20);
    },
    openFolder(item) {
      this.opened = [...new Set([...this.opened, item.id])];
    },
    scrollToBottom() {
      const stuff = document.getElementById("create-folder-btn").offsetHeight;
      const height = document
        .getElementById("project-template-scroll-div")
        .getBoundingClientRect().height;
      this.$vuetify.goTo("#create-folder-btn", {
        container: "#project-template-scroll-div",
        easing: "linear",
        duration: 3000,
      });
    },
  },
  render(h) {
    return h("div", [
      h(Title, {
        props: { title: this.$t("templateManagement.project.builder.storage.title") },
      }),
      h(
        "div",
        {
          class: `d-flex rounded-t-lg pa-2 px-4 border-t border-l border-r ${this.showBorderAndBorderRadiusIfNoItems()} text-subtitle-2`,
        },
        [h("div", this.$t("templateManagement.project.builder.storage.name"))],
      ),
      this.genTreeView(),
      h("div", { class: "pt-2" }, [
        h(
          VBtn,
          {
            props: { text: true },
            on: {
              click: (e) => {
                this.createUnsavedStorageItem({
                  type: "FOLDER",
                });
                this.scrollToBottom();
              },
            },
          },
          [
            h(VIcon, { class: "pr-3" }, this.$icon.LIGHT.ACTION.CIRCLE_PLUS),
            h(
              "div",
              { class: "font-weight-bold", attrs: { id: "create-folder-btn" } },
              this.$t("templateManagement.project.builder.storage.createFolder"),
            ),
          ],
        ),
      ]),
    ]);
  },
};
</script>
