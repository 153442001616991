<template>
  <div>
    <div v-if="isEditMode" class="d-flex">
      <v-btn text depressed @click="cancel" color="error" :disabled="isSavingLayout">
        {{ $t("common.close") }}
      </v-btn>
      <v-btn
        :loading="isSavingLayout"
        class="ml-2"
        color="primary"
        depressed
        @click="save"
      >
        {{ $t("common.save") }}
      </v-btn>
    </div>

    <v-btn
      v-if="!isEditMode"
      :disabled="isLoadingLayout"
      depressed
      v-ripple="false"
      @click="enableEditMode"
      class="primary--text"
      :class="background"
    >
      <v-icon class="mr-2" small>{{ $icon.REGULAR.ACTION.ADD_WIDGETS }}</v-icon>
      {{ $t("widget.dashboard.enableEditBtn") }}
    </v-btn>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    background: String,
  },
  computed: {
    ...mapState("widgetLayout", {
      isLoadingLayout: (state) => state.isLoading,
      isSavingLayout: (state) => state.isSaving,
      isEditMode: (state) => state.isEditMode,
    }),
  },
  methods: {
    save() {
      this.$emit("save");
    },
    cancel() {
      this.$emit("cancel");
    },
    enableEditMode() {
      this.$emit("enableEditMode");
    },
  },
};
</script>
