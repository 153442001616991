<template>
  <div>
    <AppDataTable
      v-model="localSelectedStorages"
      :loading="isLoading"
      :headers="_headers"
      :items="storages"
      :noDataText="$t('common.noStorage')"
      disable-pagination
      show-select
    >
      <template v-slot:item="{ item, isSelected, select }">
        <AppStorageTableItem
          :storage="item"
          :isSelected="isSelected"
          @isSelected:change="select"
          @name:click="onNameClick"
          @menu:click="onMenuClick"
        />
      </template>
    </AppDataTable>
    <AppStorageContextMenu
      ref="contextMenu"
      :selectedStorages="selectedStorages"
      :showBoligmappa="showBoligmappa"
      :folderRouterName="folderRouterName"
      :rootId="rootId"
      @action:click="onContextMenuActionClick"
    />
  </div>
</template>

<script>
export default {
  props: {
    storages: Array,
    isLoading: Boolean,
    selectedStorages: Array,
    showBoligmappa: Boolean,
    folderRouterName: String,
    rootId: String,
  },
  model: {
    prop: "selectedStorages",
    event: "selectedStorages:change",
  },
  computed: {
    _headers() {
      return this.headers.filter((header) => header.show);
    },
    headers() {
      return [
        {
          text: this.$t("fileManagement.list.name"),
          align: "start",
          value: "name",
          show: true,
        },
        {
          text: this.$t("fileManagement.list.owner"),
          align: "start",
          value: "storageOwner.firstName",
          show: true,
        },
        {
          text: this.$t("fileManagement.list.lastModified"),
          align: "start",
          value: "updatedAt",
          show: true,
        },
        {
          text: "",
          sortable: false,
          show: true,
        },
      ];
    },
    localSelectedStorages: {
      get() {
        return this.selectedStorages;
      },
      set(value) {
        this.$emit("selectedStorages:change", value);
      },
    },
  },
  methods: {
    onMenuClick({ event, storage }) {
      if (!this.localSelectedStorages.some((s) => s.id === storage.id)) {
        this.localSelectedStorages = [storage];
      }
      this.$refs.contextMenu.open({ pointerEvent: event });
    },
    onNameClick({ storage }) {
      this.$emit("storage:click", { storage });
    },
    onContextMenuActionClick(e) {
      this.$emit("menuAction:click", e);
    },
  },
};
</script>
