<template>
  <div>
    <div class="label--small" style="padding-bottom: 0px !important">
      {{ label | capitalize }}
    </div>
    <v-menu
      ref="menu"
      v-model="monthMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-wdith="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-layout v-on="on" v-bind="attrs">
          <AppDefaultTextField
            style="width: 310px"
            class="date-field-cursor-pointer text-centered-input"
            :value="`${selectedStartDateFormatted} - ${selectedEndDateFormatted}`"
            :label="placeholder"
            :clearable="clearable"
            :hide-details="hideDetails"
            readonly
          >
            <template v-slot:prepend-inner>
              <v-btn small icon @click.stop="onPrev">
                <v-icon>
                  {{ $icon.LIGHT.COMMON.CHEVRON_LEFT }}
                </v-icon>
              </v-btn>
            </template>
            <template v-slot:append>
              <v-btn small icon @click.stop="onNext">
                <v-icon>
                  {{ $icon.LIGHT.COMMON.CHEVRON_RIGHT }}
                </v-icon>
              </v-btn>
            </template>
          </AppDefaultTextField>
        </v-layout>
      </template>

      <v-card class="align-center justify-center d-flex">
        <!-- Setting locale-first-day-of-year to 4 to align with ISO 8601 standards, where the first week of the year includes January 4th -->
        <v-date-picker
          ref="datePickerRef"
          v-model="localValue"
          show-week
          locale-first-day-of-year="4"
          range
          no-title
          :first-day-of-week="dateMixins_firstDayOfWeek"
          color="primary"
        >
          <template>
            <v-row no-gutters>
              <v-col>
                <v-btn
                  depressed
                  small
                  block
                  style="text-transform: unset !important"
                  @click="onTodayClick"
                >
                  {{ $t("common.today") }}
                </v-btn>
                <v-btn
                  depressed
                  small
                  block
                  style="text-transform: unset !important"
                  @click="onThisPeriodClick('week')"
                >
                  {{ $t("common.thisWeek") }}
                </v-btn>
                <v-btn
                  depressed
                  small
                  block
                  style="text-transform: unset !important"
                  @click="onLastPeriodClick('week')"
                >
                  {{ $t("common.lastWeek") }}
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  depressed
                  small
                  block
                  style="text-transform: unset !important"
                  @click="onThisPeriodClick('month')"
                >
                  {{ $t("common.thisMonth") }}
                </v-btn>
                <v-btn
                  depressed
                  small
                  block
                  style="text-transform: unset !important"
                  @click="onLastPeriodClick('month')"
                >
                  {{ $t("common.lastMonth") }}
                </v-btn>
                <v-btn
                  depressed
                  small
                  block
                  style="text-transform: unset !important"
                  @click="onThisPeriodClick('year')"
                >
                  {{ $t("common.thisYear") }}
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-date-picker>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import * as moment from "moment";
import { dateMixins } from "@/helpers/mixins";

export default {
  mixins: [dateMixins],
  props: {
    label: String,
    selectedStartDate: String,
    selectedEndDate: String,
    placeholder: String,
    hideDetails: Boolean,
    disabled: Boolean,
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      monthMenu: false,
      localValue: [
        moment(this.selectedStartDate).format("YYYY-MM-DD"),
        moment(this.selectedEndDate).format("YYYY-MM-DD"),
      ],
    };
  },
  watch: {
    localValue: {
      handler(newDates) {
        const newFrom = this.sortedLocalValue[0];
        const newTo = this.sortedLocalValue[1];
        this.emitChanges(newFrom, newTo);
      },
      deep: true,
    },
    monthMenu: {
      handler(val) {
        //set selectedEndDate on menu close if no selectedEndDate
        if (!val) {
          if (!this.sortedLocalValue[1]) {
            this.localValue = [this.sortedLocalValue[0], this.sortedLocalValue[0]];
          }
        } else {
          //set view on menu open
          const selectedStartDate = this.sortedLocalValue[0];
          if (selectedStartDate) {
            this.$nextTick(() => {
              this.setView(selectedStartDate);
            });
          }
        }
      },
    },
  },
  computed: {
    sortedLocalValue() {
      const sorted = this.localValue
        .map((dateString) => moment(dateString, "YYYY-MM-DD"))
        .sort((a, b) => a - b)
        .map((date) => date.format("YYYY-MM-DD"));
      return sorted;
    },
    selectedStartDateFormatted() {
      if (!this.selectedStartDate) return "";
      return moment(this.selectedStartDate).format("ll");
    },
    selectedEndDateFormatted() {
      if (!this.selectedEndDate) return "";
      return moment(this.selectedEndDate).format("ll");
    },
  },
  methods: {
    diffInDaysBetweenTwoDates(selectedStartDate, selectedEndDate) {
      const diff = moment(selectedEndDate).diff(moment(selectedStartDate), "days") + 1;
      return diff;
    },
    emitChanges(selectedStartDate, selectedEndDate) {
      this.$emit("dateRange:change", { selectedStartDate, selectedEndDate });
    },
    onTodayClick() {
      const date = moment();
      this.onQuickActionClick(date, date);
    },
    onThisPeriodClick(timePeriod) {
      const selectedStartDate = moment().startOf(timePeriod);
      const selectedEndDate = moment().endOf(timePeriod);
      this.onQuickActionClick(selectedStartDate, selectedEndDate);
    },
    onLastPeriodClick(timePeriod) {
      const lastWeek = moment().subtract(1, timePeriod);
      const selectedStartDate = moment(lastWeek).startOf(timePeriod);
      const selectedEndDate = moment(lastWeek).endOf(timePeriod);
      this.onQuickActionClick(selectedStartDate, selectedEndDate);
    },
    setView(YYYYMM) {
      this.$refs.datePickerRef.tableDate = YYYYMM;
    },
    onQuickActionClick(selectedStartDate, selectedEndDate) {
      const formattedFrom = selectedStartDate.format("YYYY-MM-DD");
      const formattedTo = selectedEndDate.format("YYYY-MM-DD");
      const tableMonth = selectedStartDate.format("YYYY-MM");
      this.setView(tableMonth);
      this.localValue = [formattedFrom, formattedTo];
    },
    isRangeWholeMonth(oldFrom, oldTo) {
      const isOldFromStartOfMonth = moment(oldFrom).isSame(
        moment(oldFrom).startOf("month"),
        "day",
      );
      const isOldToEndOfMonth = moment(oldTo).isSame(moment(oldTo).endOf("month"), "day");
      const isSameMonth = moment(oldTo).isSame(moment(oldFrom), "month");
      const isSameYear = moment(oldTo).isSame(moment(oldFrom), "year");

      return isOldFromStartOfMonth && isOldToEndOfMonth && isSameMonth && isSameYear;
    },
    isRangeWholeYear(oldFrom, oldTo) {
      const isOldFromStartOfYear = moment(oldFrom).isSame(
        moment(oldFrom).startOf("year"),
        "day",
      );
      const isOldToEndOfYear = moment(oldTo).isSame(moment(oldTo).endOf("year"), "day");
      const isSameYear = moment(oldTo).isSame(moment(oldFrom), "year");

      return isOldFromStartOfYear && isOldToEndOfYear && isSameYear;
    },
    onNext() {
      const oldFrom = this.sortedLocalValue[0];
      const oldTo = this.sortedLocalValue[1]
        ? this.sortedLocalValue[1]
        : this.sortedLocalValue[0];

      if (this.isRangeWholeMonth(oldFrom, oldTo)) {
        // Move an entire month at a time
        const newTo = moment(oldTo).add(1, "month").endOf("month").format("YYYY-MM-DD");
        const newFrom = moment(newTo).startOf("month").format("YYYY-MM-DD");
        this.localValue = [newFrom, newTo];
      } else if (this.isRangeWholeYear(oldFrom, oldTo)) {
        // Move an entire year at a time
        const newTo = moment(oldTo).add(1, "year").endOf("year").format("YYYY-MM-DD");
        const newFrom = moment(newTo).startOf("year").format("YYYY-MM-DD");
        this.localValue = [newFrom, newTo];
      } else {
        // Move selected interval at a time
        const diffBetweenFromAndTo = this.diffInDaysBetweenTwoDates(oldFrom, oldTo);
        const newFrom = this.dateMixins_addDaysToDate(
          oldFrom,
          diffBetweenFromAndTo,
        ).format("YYYY-MM-DD");
        const newTo = this.dateMixins_addDaysToDate(oldTo, diffBetweenFromAndTo).format(
          "YYYY-MM-DD",
        );
        this.localValue = [newFrom, newTo];
      }
    },
    onPrev() {
      const oldFrom = this.sortedLocalValue[0];
      const oldTo = this.sortedLocalValue[1]
        ? this.sortedLocalValue[1]
        : this.sortedLocalValue[0];

      if (this.isRangeWholeMonth(oldFrom, oldTo)) {
        // Move an entire month at a time
        const newFrom = moment(oldFrom)
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        const newTo = moment(newFrom).endOf("month").format("YYYY-MM-DD");
        this.localValue = [newFrom, newTo];
      } else if (this.isRangeWholeYear(oldFrom, oldTo)) {
        // Move an entire year at a time
        const newTo = moment(oldTo)
          .subtract(1, "year")
          .endOf("year")
          .format("YYYY-MM-DD");
        const newFrom = moment(newTo).startOf("year").format("YYYY-MM-DD");
        this.localValue = [newFrom, newTo];
      } else {
        //move selected interval at a time
        const diffBetweenFromAndTo = this.diffInDaysBetweenTwoDates(oldFrom, oldTo);
        const newFrom = this.dateMixins_subtractDaysFromDate(
          oldFrom,
          diffBetweenFromAndTo,
        ).format("YYYY-MM-DD");
        const newTo = this.dateMixins_subtractDaysFromDate(
          oldTo,
          diffBetweenFromAndTo,
        ).format("YYYY-MM-DD");
        this.localValue = [newFrom, newTo];
      }
    },
  },
};
</script>
