<template>
  <div class="d-flex align-center">
    <v-btn
      small
      depressed
      v-if="!title && clickable"
      @click="onClick"
      style="text-transform: unset !important"
      color="secondary--text"
    >
      {{ $t("common.add") }}
      <v-icon small class="ml-3">{{
        isUpdating ? $icon.SOLID.ACTION.LOADING : $icon.LIGHT.ACTION.PLUS
      }}</v-icon>
    </v-btn>
    <AppColorChip v-else color="primary--text" @click="onClick" :clickable="clickable">
      <AppTextTruncateValue
        :text="title"
        :class="clickable ? 'mr-2' : ''"
        :truncate="20"
      />
      <template v-slot:append-item v-if="clickable">
        <v-icon color="primary" small>{{
          isUpdating ? $icon.SOLID.ACTION.LOADING : $icon.SOLID.ACTION.DROPDOWN
        }}</v-icon>
      </template>
    </AppColorChip>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    isUpdating: Boolean,
    clickable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onClick(e) {
      if (!this.clickable) return;
      this.$emit("click", e);
    },
  },
};
</script>
