<template>
  <v-list :expand="true" class="pt-0">
    <v-list-group
      v-for="(section, sectionIndex) in sections"
      :key="sectionIndex"
      v-model="isExpanded[sectionIndex]"
      class="app-section-list-border-t"
      :class="showBorderB(expanded(sectionIndex), sectionIndex, section)"
    >
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>
            {{ section.title }} ({{ itemsLength(section) }})
          </v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item v-for="(item, index) in section.data" :key="index">
        <div class="w-100">
          <slot name="item" :item="item"></slot>
          <v-divider v-if="showDivider(section, index, sectionIndex)" />
        </div>
      </v-list-item>
    </v-list-group>
  </v-list>
</template>

<script>
export default {
  props: {
    sections: Array,
    isSectionsExpanded: Array,
  },
  data() {
    return {
      isExpanded: [],
    };
  },
  computed: {
    sectionsLength() {
      return this.sections?.length;
    },
  },
  methods: {
    expanded(sectionIndex) {
      return this.isExpanded[sectionIndex];
    },
    showDivider(section, index, sectionIndex) {
      const isLastSection = this.isLastSection(sectionIndex);
      const isLastItemInSection = index === this.itemsLength(section) - 1;

      return !(isLastItemInSection && !isLastSection);
    },
    showBorderB(isExpanded, sectionIndex, section) {
      const isLastSection = this.isLastSection(sectionIndex);
      const isNotLastAndHasNoItems = !isLastSection && this.itemsLength(section) === 0;
      if (isNotLastAndHasNoItems) {
        return "";
      }
      return isExpanded || isLastSection ? "app-section-list-border-b" : "";
    },
    itemsLength(items) {
      return items?.data?.length || 0;
    },
    isLastSection(sectionIndex) {
      return sectionIndex === this.sectionsLength - 1;
    },
  },
  beforeMount() {
    this.isExpanded = this.isSectionsExpanded;
  },
};
</script>
