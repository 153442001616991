<template>
  <div>
    <AppTeamDialog
      v-model="dialog.updateTeam"
      :data="selectedItem"
      :title="$t('team.updateTeam.title')"
      @submit:form="updateTeam"
      :buttonText="$t('common.save')"
    />
    <AppDeleteConfirmationDialog
      v-model="dialog.deleteTeam"
      :title="$t('team.deleteTeam.title')"
      :subtitle="$t('team.deleteTeam.subTitle')"
      skip-validation
      :item="selectedItem"
      @delete="deleteTeam"
    />
    <AppDataTable
      :loading="isLoading"
      :headers="_headers"
      :items="teams"
      :noDataText="$t('common.noTeams')"
      :statsText="teamsStats"
      :isStatsLoading="isTeamStatsLoading"
    >
      <template v-slot:item="{ item }">
        <AppTeamTableItem
          :team="item"
          :isHighlighted="isHighlighted(item)"
          @menu:click="onMenuClick"
        />
      </template>
    </AppDataTable>
    <AppContextMenu ref="contextMenu" @input="isContextMenuOpen = $event">
      <AppMenuEditBtn @click="onTeamEdit" :disabled="isEditDisabled" />
      <AppMenuDeleteBtn @click="onTeamDelete" :disabled="isDeleteDisabled" />
    </AppContextMenu>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  props: {
    teams: Array,
    isLoading: Boolean,
  },
  data() {
    return {
      dialog: {
        deleteTeam: false,
        updateTeam: false,
      },
      selectedItem: null,
      isContextMenuOpen: false,
    };
  },
  computed: {
    ...mapState("teamStats", {
      isTeamStatsLoading: (state) => state.isLoading,
    }),
    ...mapGetters("teamStats", {
      statCountByType: "statCountByType",
    }),
    ...mapState("teamStats", {
      isStatsLoading: (state) => state.isLoading,
    }),
    isEditDisabled() {
      return !this.selectedItem?.canEdit;
    },
    isDeleteDisabled() {
      return !this.selectedItem?.canDelete;
    },
    teamsStats() {
      return `
             ${this.$tc(
               "common.informationHeader.teamsPrepend",
               this.statCountByType("LEADER"),
             )}
              ${this.$tc(
                "common.informationHeader.teamsAppend",
                this.statCountByType("MEMBER"),
              )}
              `;
    },
    _headers() {
      return this.headers.filter((header) => header.show);
    },
    headers() {
      return [
        {
          text: this.$options.filters.capitalize(this.$t("team.list.teamName")),
          value: "name",
          show: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("team.list.teamOwner")),
          value: "owner.name",
          show: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.members")),
          value: "members",
          show: true,
        },
        {
          text: "",
          sortable: false,
          show: true,
        },
      ];
    },
  },
  methods: {
    isHighlighted(item) {
      const isCurrentItem = this.selectedItem === item;
      if (!isCurrentItem) return false;

      const isAnyDialogsOpen = Object.values(this.dialog).some((value) => value === true);
      const isDetailsOpen = item.id === this.taskId;

      return this.isContextMenuOpen || isAnyDialogsOpen || isDetailsOpen;
    },
    onMenuClick({ event, team }) {
      this.selectedItem = team;
      this.$refs.contextMenu.open({ pointerEvent: event });
    },
    onTeamEdit() {
      this.dialog.updateTeam = true;
    },
    onTeamDelete() {
      this.dialog.deleteTeam = true;
    },
    deleteTeam({ item }) {
      this.$store.dispatch("teams/deleteTeam", item.id).then(() => {
        this.getTeamsStats();
      });
    },
    updateTeam({ id, body }) {
      this.$store.dispatch("teams/updateTeam", { id, body });
    },
    getTeamsStats() {
      this.$store.dispatch("teamStats/getStats", { type: ["MEMBER", "LEADER"] });
    },
  },
  mounted() {
    this.getTeamsStats();
  },
};
</script>
