<template>
  <v-dialog v-model="localDialog" max-width="600px" scrollable>
    <v-card>
      <v-card-title>
        <span>{{ statusMixins_tooltip(model) }}</span>
        <v-spacer />
        <v-btn @click="localDialog = false" icon
          ><v-icon>
            {{ $icon.REGULAR.ACTION.CLOSE }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="primary-text--text">
        <div v-for="category in statusMixins_categories" :key="category">
          <div class="d-flex align-center justify pt-1">
            <div
              class="label--small user-select-none"
              style="padding-bottom: 0px !important"
            >
              {{ $t(`statuses.categories.${category}`).toUpperCase() }}
            </div>
            <AppDefaultTooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small class="pl-2 cursor-pointer">
                  {{ $icon.LIGHT.COMMON.CIRCLE_QUESTION }}
                </v-icon>
              </template>
              <span>
                {{
                  $t(`statuses.edit.helpers.${model}.${category}`, {
                    moduleDefinitePlural: statusMixins_moduleDefinitePlural(model),
                  })
                }}
              </span>
            </AppDefaultTooltip>
          </div>
          <draggable
            v-model="localStatuses[category]"
            :group="{ name: 'statuses' }"
            draggable=".draggable-item"
            filter=".block-draggable"
            @end="onMoveEnd"
            :forceFallback="true"
            :ghostClass="$constant.DRAG_SETTINGS.GHOST_CLASS_DASHED_OUTLINE"
            :dragClass="$constant.DRAG_SETTINGS.DRAG_CLASS"
            :animation="$constant.DRAG_SETTINGS.ANIMATION_DURATION"
            :emptyInsertThreshold="20"
            :preventOnFilter="false"
            :fallbackTolerance="4"
            class="my-1 draggable-container-gap-4"
          >
            <div
              v-for="(status, index) in localStatuses[category]"
              :key="status.id"
              class="draggable-item"
            >
              <AppEditCustomStatusDialogItem
                :status="status"
                :category="category"
                :isRenaming="isRenaming"
                :predefinedColors="predefinedColors"
                :editStatus="editStatus"
                @editStatus:nameChange="editStatus.name = $event"
                @delete:click="onDeleteClick(category, status)"
                @changeColor:click="onChangeColorClick(status, category, $event)"
                @rename:start="onRenameStart(status)"
                @rename:end="
                  onRenameEnd({ category: category, shouldUpdate: $event.shouldUpdate })
                "
              />
            </div>
          </draggable>
          <div v-if="category === 'ACTIVE'">
            <AppDefaultTextField
              ref="createNewStatusInput"
              v-if="isCreateActive"
              class="pb-1"
              @blur="onCreateStatus"
              @keypress.enter="$refs.createNewStatusInput.blur()"
              v-model="newStatusName"
              autofocus
              hide-details
            />
            <v-btn block depressed color="primary" @click="isCreateActive = true">{{
              $t("common.create")
            }}</v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <AppDeleteConfirmationDialog
      v-model="deleteStatusConfirmationDialog.active"
      :title="$t('statuses.edit.deleteStatus.title')"
      :subtitle="$t('statuses.edit.deleteStatus.subTitle')"
      :validator="$t('common.delete').toLowerCase()"
      :validatorText="
        $t('statuses.edit.deleteStatus.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      @delete="deleteStatus"
    />
  </v-dialog>
</template>
<script>
import Draggable from "vuedraggable";
import { mapGetters } from "vuex";
import { statusMixins } from "@/helpers/mixins";

export default {
  mixins: [statusMixins],
  props: {
    dialog: Boolean,
    isCreating: Boolean,
    model: String,
  },
  components: {
    Draggable,
  },
  watch: {
    dialog: {
      handler() {
        if (!this.hasFetchedStatuses(this.model)) {
          this.getStatuses();
        }
      },
    },
  },
  data() {
    return {
      isCreateActive: false,
      isRenaming: false,
      newStatusName: "",
      renameName: "",
      editStatus: null,
      showColorMenu: false,
      deleteStatusConfirmationDialog: {
        active: false,
        value: {
          status: null,
          category: null,
        },
      },
      predefinedColors: [
        "#5F55EE",
        "#4466FF",
        "#1090E9",
        "#0F9D9F",
        "#3DB88B",
        "#008844",
        "#F8AE00",
        "#E16B16",
        "#D33D44",
        "#EE5E99",
        "#B660E0",
        "#AA8D80",
        "#656F7D",
        "#87909E",
      ],
    };
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    localStatuses: {
      get() {
        return this.getStatusesByModel(this.model);
      },
      set(value) {
        this.onStatusesChange(value);
      },
    },
    ...mapGetters("statuses", {
      getStatusesByModel: "getStatusesByModel",
      hasFetchedStatuses: "hasFetchedStatuses",
    }),
  },
  methods: {
    onDeleteClick(category, status) {
      this.deleteStatusConfirmationDialog.value = { status, category };
      this.deleteStatusConfirmationDialog.active = true;
    },
    onRenameStart(status) {
      this.editStatus = { ...status };
      this.isRenaming = true;
    },
    onRenameCompleted() {
      this.isRenaming = false;
      this.editStatus = null;
    },
    onRenameEnd({ category, shouldUpdate }) {
      if (shouldUpdate) {
        this.onEditEnd(category);
      }
      this.onRenameCompleted();
    },
    onChangeColorClick(status, category, color) {
      this.onStatusChange({ status: { ...status, color }, category });
      this.editStatus = status;
    },
    onMoveEnd() {
      this.onStatusesChange(this.localStatuses);
    },
    onStatusCreateEnd() {
      this.newStatusName = "";
      this.isCreateActive = false;
    },
    onEditEnd(category) {
      this.onStatusChange({
        category,
        status: this.editStatus,
      });
    },
    randomColor() {
      const randomIndex = Math.floor(Math.random() * this.predefinedColors.length);
      return this.predefinedColors[randomIndex];
    },
    onCreateStatus() {
      if (this.newStatusName.trim()) {
        this.createStatus();
      }
      this.onStatusCreateEnd();
    },
    onStatusesChange(event) {
      this.$store
        .dispatch("statuses/updateStatuses", { model: this.model, statuses: event })
        .then(() => {});
    },
    onStatusChange({ category, status }) {
      this.$store
        .dispatch("statuses/updateStatus", { model: this.model, category, status })
        .then(() => {});
    },
    createStatus() {
      this.$store.dispatch("statuses/createStatus", {
        status: {
          name: this.newStatusName,
          color: this.randomColor(),
          category: "ACTIVE",
          model: this.model,
        },
      });
    },
    deleteStatus() {
      const { category, status } = this.deleteStatusConfirmationDialog.value;
      this.$store.dispatch("statuses/deleteStatus", {
        category,
        status,
        model: this.model,
      });
    },
    getStatuses() {
      this.$store.dispatch("statuses/getStatuses", { model: this.model });
    },
  },
};
</script>
