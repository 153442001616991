<template>
  <v-dialog v-model="localDialog" max-width="1000px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $t("fileManagement.preview.navMenu.e-signature.prepDocumentDialog.title") }}
        </span>
      </v-card-title>
      <v-card-text>
        <div class="d-flex flex-column overflow-y-auto" style="max-height: 600px">
          <v-form ref="signersForm" v-model="isValid" @submit="onSubmit">
            <div class="d-flex pt-1" v-for="(recipient, index) in signers" :key="index">
              <AppUserAndContactGroupedUserAndContactCombobox
                class="pr-3"
                :loading="isLoading"
                :users="users"
                :contacts="contacts"
                :label="
                  $t(
                    'fileManagement.preview.navMenu.e-signature.prepDocumentDialog.addFromListOrEnterFreeText',
                  )
                "
                :value="recipient"
                @change="changeRecipientNameAndEmail($event, recipient)"
              />
              <AppDefaultTextField
                style="width: 200px"
                :rules="[...$rules.EMAIL_RULES, ...$rules.REQUIRED]"
                class="pr-3"
                :label="
                  $t(
                    'fileManagement.preview.navMenu.e-signature.prepDocumentDialog.recipientEmail',
                  )
                "
                v-model="recipient.email"
              />

              <AppDefaultSelect
                style="width: 150px"
                class="pr-3"
                :items="signerTypes"
                v-model="recipient.signerType"
                :clearable="false"
              >
                <template v-slot:selection="{ item }">
                  <v-icon>{{ item.icon }}</v-icon>
                  <div class="pl-2">
                    {{ item.text }}
                  </div>
                </template>
                <template v-slot:item="{ item }">
                  <v-icon>{{ item.icon }}</v-icon>
                  <div class="pl-2">{{ item.text }}</div>
                </template>
              </AppDefaultSelect>

              <AppDefaultSelect
                style="width: 50px"
                :items="languages"
                v-model="recipient.language"
                :clearable="false"
              >
                <template v-slot:item="{ item }">
                  <div>{{ item.text }} ({{ item.value }})</div>
                </template>
              </AppDefaultSelect>

              <v-btn icon class="mx-3" @click="onRecipientDelete(recipient)">
                <v-icon>{{ $icon.LIGHT.ACTION.DELETE }}</v-icon>
              </v-btn>
            </div>
          </v-form>
        </div>
        <v-btn outlined color="primary" @click="addRecipient" class="mt-2">
          <v-icon class="pr-3">{{ $icon.REGULAR.ACTION.ADD }}</v-icon>
          {{
            $t(
              "fileManagement.preview.navMenu.e-signature.prepDocumentDialog.addRecipientButton",
            )
          }}
        </v-btn>
        <AppErrorAlert class="mt-2" v-if="showNeedsAtLeastOneSignerError">
          {{
            $t(
              "fileManagement.preview.navMenu.e-signature.prepDocumentDialog.errorMessages.needAtLeastOneSigner",
            )
          }}
        </AppErrorAlert>
        <AppErrorAlert class="mt-2" v-if="showNoDuplicateEmailsError">
          {{
            $t(
              "fileManagement.preview.navMenu.e-signature.prepDocumentDialog.errorMessages.noDuplicateEmails",
            )
          }}
        </AppErrorAlert>
        <AppErrorAlert class="mt-2" v-if="showApiError">
          {{
            $t(
              "fileManagement.preview.navMenu.e-signature.prepDocumentDialog.errorMessages.apiError",
            )
          }}
        </AppErrorAlert>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onSubmit"
          @cancel="closeDialog"
          :loading="isSendingDocumentToESign"
          :confirmText="
            $t(
              'fileManagement.preview.navMenu.e-signature.prepDocumentDialog.completeButton',
            )
          "
        />
      </v-card-actions>
    </v-card>
    <AppDeleteConfirmationDialog
      v-model="dialogs.deleteRecipient.active"
      :title="
        $t(
          'fileManagement.preview.navMenu.e-signature.prepDocumentDialog.confirmDeleteDialog.title',
        )
      "
      :subtitle="
        $t(
          'fileManagement.preview.navMenu.e-signature.prepDocumentDialog.confirmDeleteDialog.subTitle',
        )
      "
      skip-validation
      :item="dialogs.deleteRecipient.item"
      @delete="deleteRecipient"
    />
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { getSearchUsers } from "@/services/search/users";
import { getSearchContacts } from "@/services/search/contact";

const genEmptyRecipient = () => ({
  name: "",
  email: "",
  signerType: "SIGNER",
  language: "NO",
  userId: null,
});

export default {
  data() {
    return {
      isValid: false,
      dialogs: {
        deleteRecipient: {
          item: null,
          active: false,
        },
      },
      signers: [genEmptyRecipient()],
      signerTypes: [
        {
          value: "SIGNER",
          text: this.$t(
            "fileManagement.preview.navMenu.e-signature.prepDocumentDialog.signerType.signer",
          ),
          icon: this.$icon.LIGHT.FILE.FILE_SIGNATURE,
        },
        {
          value: "REVIEWER",
          text: this.$t(
            "fileManagement.preview.navMenu.e-signature.prepDocumentDialog.signerType.reviewer",
          ),
          icon: this.$icon.LIGHT.COMMON.CIRCLE_CHECK,
        },
      ],
      languages: [
        {
          value: "EN",
          text: this.$t(
            "fileManagement.preview.navMenu.e-signature.prepDocumentDialog.language.en",
          ),
        },
        {
          value: "ES",
          text: this.$t(
            "fileManagement.preview.navMenu.e-signature.prepDocumentDialog.language.es",
          ),
        },
        {
          value: "DE",
          text: this.$t(
            "fileManagement.preview.navMenu.e-signature.prepDocumentDialog.language.de",
          ),
        },
        {
          value: "FR",
          text: this.$t(
            "fileManagement.preview.navMenu.e-signature.prepDocumentDialog.language.fr",
          ),
        },
        {
          value: "RO",
          text: this.$t(
            "fileManagement.preview.navMenu.e-signature.prepDocumentDialog.language.ro",
          ),
        },
        {
          value: "NO",
          text: this.$t(
            "fileManagement.preview.navMenu.e-signature.prepDocumentDialog.language.no",
          ),
        },
      ],
      users: [],
      isLoadingUsers: false,
      contacts: [],
      isLoadingContacts: false,
      showNeedsAtLeastOneSignerError: false,
      showNoDuplicateEmailsError: false,
      showApiError: false,
    };
  },
  props: {
    dialog: Boolean,
    storageId: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  watch: {
    dialog: {
      handler(val) {
        if (val) {
          this.getUserNameList();
          this.getContacts();
        }
      },
    },
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    ...mapState("storageESign", {
      isSendingDocumentToESign: (state) => state.isSendingDocumentToESign,
    }),
    isLoading() {
      return this.isLoadingUsers || this.isLoadingContacts;
    },
  },
  methods: {
    changeRecipientNameAndEmail(event, recipient) {
      if (!event) {
        recipient.userId = null;
        return;
      }
      if (typeof event === "string") {
        recipient.name = event;
      } else {
        recipient.name = event.name;
        recipient.userId = event.id;
        recipient.email = event.email;
      }
    },
    isEmpty(recipient) {
      const isUndefined = !recipient.name && !recipient.email;
      const isEmpty = !recipient?.name?.trim() && !recipient?.email?.trim();
      return isUndefined || isEmpty;
    },
    onRecipientDelete(recipient) {
      let isEmpty = this.isEmpty(recipient);

      if (isEmpty) {
        this.deleteRecipient({ item: recipient });
        return;
      }

      this.dialogs.deleteRecipient.item = recipient;
      this.dialogs.deleteRecipient.active = true;
    },
    deleteRecipient({ item }) {
      const index = this.signers.indexOf(item);
      if (index === -1) return;
      this.signers.splice(index, 1);
    },
    addRecipient() {
      this.signers.push(genEmptyRecipient());
    },
    hasAtLeastOneSigner() {
      const hasAtLestOneSigner = this.signers.some(
        (signer) => signer.signerType == "SIGNER",
      );
      return hasAtLestOneSigner;
    },
    hasNoDuplicateEmails() {
      const emails = this.signers.map((recipient) => recipient.email);
      return new Set(emails).size === emails.length;
    },
    validate() {
      this.showApiError = false;
      this.isValid = this.$refs.signersForm.validate();
      const isValidNoDuplicateEmails = this.hasNoDuplicateEmails();
      this.showNoDuplicateEmailsError = !isValidNoDuplicateEmails;
      const isValidHasAtLeastOneSigner = this.hasAtLeastOneSigner();
      this.showNeedsAtLeastOneSignerError = !isValidHasAtLeastOneSigner;
      return this.isValid && isValidNoDuplicateEmails && isValidHasAtLeastOneSigner;
    },
    async getUserNameList() {
      this.isLoadingUsers = true;
      const params = {
        alwaysIncludeIds: this.signers?.map((signer) => signer?.userId) ?? [],
      };
      this.users = await getSearchUsers({ params });
      this.isLoadingUsers = false;
    },
    async getContacts() {
      this.isLoadingContacts = true;
      getSearchContacts()
        .then((contacts) => {
          this.contacts = contacts;
        })
        .finally(() => (this.isLoadingContacts = false));
    },
    onSubmit() {
      const isValid = this.validate();
      if (!isValid) return;
      this.$store
        .dispatch("storageESign/sendDocumentToESign", {
          storageId: this.storageId,
          body: { signers: this.signers },
        })
        .then((data) => {
          this.$emit("submit", data.embeddedUrl);
          this.resetAndCloseDialog();
        })
        .catch((err) => {
          this.showApiError = true;
          throw new Error(err);
        });
    },
    resetAndCloseDialog() {
      this.signers = [genEmptyRecipient()];
      this.closeDialog();
    },
    closeDialog() {
      this.localDialog = false;
      this.showNeedsAtLeastOneSignerError = false;
      this.showNoDuplicateEmailsError = false;
      this.showApiError = false;
      this.$refs.signersForm.resetValidation();
    },
  },
};
</script>
