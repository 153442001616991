<template>
  <span
    @click.prevent="click"
    :class="`primary-link--text ${underlineClass} cursor-pointer`"
  >
    <slot>link text</slot>
  </span>
</template>

<script>
export default {
  props: {
    link: String,
    underline: Boolean,
    underlineOnHover: Boolean,
  },
  computed: {
    underlineClass() {
      if (this.underline) return "underline";
      else if (this.underlineOnHover) return "underline-on-hover";
      return "";
    },
  },
  methods: {
    click(e) {
      e.stopPropagation();
      if (this.link) {
        window.open(this.link, "_blank");
      } else {
        this.$emit("click");
      }
    },
  },
};
</script>
