<template>
  <div
    v-if="showDrawer"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    @touchend="handleTouchEnd"
  >
    <AppDrawerVariantToggleBtn />
    <v-navigation-drawer
      :width="240"
      :mini-variant-width="64"
      app
      permanent
      :miniVariant="isMini"
      touchless
    >
      <template v-slot:prepend>
        <MainPrepend v-if="drawerType === 'MAIN'" />
        <SettingsPrepend v-else-if="drawerType === 'SETTINGS'" />
        <ProjectViewPrepend v-else-if="drawerType === 'PROJECT'" />
        <TemplatePrepend v-else-if="drawerType === 'TEMPLATE'" />
        <CMSPrepend v-else-if="drawerType === 'CMS'" />
      </template>

      <div class="d-flex flex-column h-100">
        <MainContent v-if="drawerType === 'MAIN'" />
        <SettingsContent v-else-if="drawerType === 'SETTINGS'" />
        <ProjectViewContent v-else-if="drawerType === 'PROJECT'" />
        <TemplateContent v-else-if="drawerType === 'TEMPLATE'" />
        <CMSContent v-else-if="drawerType === 'CMS' && isCmsAdmin" />
        <v-spacer />

        <template v-if="isDomainOwner">
          <AppTrialDaysLeftLabel />
        </template>
        <AppNavigationList>
          <AppToggleMenuBtn right v-if="!isHighestPriorityRoleContact">
            <template v-slot:activator="{ attrs, on }">
              <div v-bind="attrs" v-on="on">
                <AppNavigationItem
                  :prependIcon="$icon.REGULAR.ACTION.ADD"
                  :title="$t('drawer.route.quickActions.title')"
                  prepend-icon-small
                />
              </div>
            </template>
            <AppNavigationItem
              v-if="isTimeEntryFeatureEnabled"
              :prependIcon="$icon.REGULAR.ROUTE.TIME_REGISTRATION"
              :title="$t('drawer.route.quickActions.logHours')"
              @click="onLogHoursClick"
              prepend-icon-small
            />
            <AppNavigationItem
              :prependIcon="$icon.REGULAR.ROUTE.PROJECT"
              :title="$t('drawer.route.quickActions.createProject')"
              @click="onCreateProjectClick"
              prepend-icon-small
            />
            <AppNavigationItem
              :prependIcon="$icon.REGULAR.ROUTE.TASK"
              :title="$t('drawer.route.quickActions.newTask')"
              @click="onNewTaskClick"
              prepend-icon-small
            />
            <AppNavigationItem
              :prependIcon="$icon.REGULAR.ROUTE.FORM"
              :title="$t('drawer.route.quickActions.fillOutForm')"
              @click="onFillOutFormClick"
              prepend-icon-small
            />
          </AppToggleMenuBtn>

          <AppToggleMenuBtn right>
            <template v-slot:activator="{ attrs, on }">
              <div v-bind="attrs" v-on="on">
                <AppNavigationItem
                  :prependIcon="$icon.REGULAR.COMMON.COMMENTS_QUESTION"
                  :title="$t('drawer.route.support')"
                  prepend-icon-small
                />
              </div>
            </template>
            <AppNavigationItem
              :prependIcon="$icon.REGULAR.ACTION.EMAIL"
              :title="$t('drawer.route.supportChat')"
              :href="`mailto:${$constant.CONTACT_EMAIL}?subject=${$t(
                'common.supportHelpSubject',
              )}`"
              prepend-icon-small
            />
            <AppNavigationItem
              :prependIcon="$icon.REGULAR.COMMON.BOOK_OPEN_READER"
              :title="$t('drawer.route.articles')"
              @click="onArticlesOpen"
              prepend-icon-small
            />
            <AppNavigationItem
              :prependIcon="$icon.REGULAR.COMMON.CIRCLE_QUESTION"
              :title="$t('drawer.route.support')"
              @click="onSleekOpen"
              prepend-icon-small
            />
          </AppToggleMenuBtn>

          <AppNavigationItem
            v-if="shouldShowSettingsButton"
            :to="{ name: $routeNames.SETTINGS.ROOT }"
            :title="$t('drawer.route.settings')"
            :prependIcon="$icon.REGULAR.ROUTE.SETTINGS"
            :appendIcon="$icon.SOLID.ACTION.GO_NEXT_LEVEL"
            prepend-icon-small
            show-append-icon-only-on-hover
          />
        </AppNavigationList>
      </div>

      <template v-slot:append>
        <MainAppend />
      </template>
      <AppTimeEntryDialog
        v-if="isTimeEntryFeatureEnabled"
        v-model="dialog.newTimeEntry.active"
        :title="$t('timeRegistration.newTimeEntry.createTitle')"
        :defaultProjectId="projectId"
      />
      <AppProjectDialog
        v-model="dialog.newProject.active"
        :title="$t('project.newProject.title')"
        :defaultValue="dialog.newProject.defaultValue"
        :parentId="projectId"
      />
      <AppTaskDialog v-model="dialog.newTask.active" :data="{ projectId }" />
      <AppFormTemplatePickerDialog
        v-model="dialog.newForm.active"
        @select="onFormSelect"
      />
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import MainPrepend from "./prepend/MainPrepend";
import MainContent from "./content/MainContent";
import MainAppend from "./append/MainAppend";
import SettingsPrepend from "./prepend/SettingsPrepend";
import SettingsContent from "./content/SettingsContent";
import ProjectViewContent from "./content/ProjectViewContent";
import ProjectViewPrepend from "./prepend/ProjectViewPrepend";
import TemplateContent from "./content/TemplateContent";
import TemplatePrepend from "./prepend/TemplatePrepend";
import CMSContent from "./content/CMSContent";
import CMSPrepend from "./prepend/CMSPrepend";

export default {
  name: "AppDrawer",
  components: {
    MainPrepend,
    MainContent,
    MainAppend,
    SettingsPrepend,
    SettingsContent,
    ProjectViewContent,
    ProjectViewPrepend,
    TemplateContent,
    TemplatePrepend,
    CMSContent,
    CMSPrepend,
  },
  data() {
    return {
      swipe: {
        startX: 0,
        startY: 0,
      },
      disabledDrawerRouteNames: [
        this.$routeNames.GET_STARTED.NEW_WORKSPACE,
        this.$routeNames.GET_STARTED.LANDING,
      ],
      dialog: {
        newTimeEntry: {
          active: false,
        },
        newProject: {
          active: false,
          defaultValue: { startDate: null },
        },
        newTask: {
          active: false,
        },
        newForm: {
          active: false,
        },
      },
    };
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    ...mapState("auth", {
      user: (state) => state.user,
    }),
    ...mapGetters("auth", {
      isCmsAdmin: "isCmsAdmin",
      isDomainOwner: "isDomainOwner",
      isTimeEntryFeatureEnabled: "isTimeEntryFeatureEnabled",
      isHighestPriorityRoleContact: "isHighestPriorityRoleContact",
    }),
    ...mapGetters("auth", {
      currentDomain: "currentDomain",
    }),
    ...mapGetters("appDrawer", {
      isMini: "isDrawerMiniVariant",
    }),
    isDisabledRouteName() {
      return this.disabledDrawerRouteNames.some((x) => x === this.$route.name);
    },
    isOnRoot() {
      return this.$route.fullPath === process.env.BASE_URL;
    },
    showDrawer() {
      if (this.isOnRoot) return false;
      if (!this.user) return false;
      if (this.isDisabledRouteName) return false;

      const isFormView = this.isUnderRoute(this.$routeNames.FORM_VIEW.ROOT);
      if (isFormView) return false;

      const isTaskView = this.isUnderRoute(this.$routeNames.TASK_VIEW.ROOT);
      if (isTaskView) return false;

      const isFormTemplateBuilder = this.isUnderRoute(
        this.$routeNames.TEMPLATE.FORM_TEMPLATE_BUILDER,
      );
      if (isFormTemplateBuilder) return false;

      const isProjectTemplateBuilder = this.isUnderRoute(
        this.$routeNames.TEMPLATE.PROJECT_TEMPLATE_BUILDER,
      );
      if (isProjectTemplateBuilder) return false;

      const isCMSFormTemplateBuilder = this.isUnderRoute(
        this.$routeNames.CMS.FORM_TEMPLATE_BUILDER,
      );
      if (isCMSFormTemplateBuilder) return false;

      const isCMSProjectTemplateBuilder = this.isUnderRoute(
        this.$routeNames.CMS.PROJECT_TEMPLATE_BUILDER,
      );
      if (isCMSProjectTemplateBuilder) return false;

      const isOfficeOnline = this.isUnderRoute(this.$routeNames.OFFICE_ONLINE.ROOT);
      if (isOfficeOnline) return false;

      return true;
    },
    drawerType() {
      const isSettingsRoute = this.isUnderRoute(this.$routeNames.SETTINGS.ROOT);
      if (isSettingsRoute) return "SETTINGS";

      const isProjectViewRoute = this.isUnderRoute(this.$routeNames.PROJECT.VIEW);
      if (isProjectViewRoute) return "PROJECT";

      const isTemplateRoute = this.isUnderRoute(this.$routeNames.TEMPLATE.ROOT);
      if (isTemplateRoute) return "TEMPLATE";

      const isCMSRoute = this.isUnderRoute(this.$routeNames.CMS.ROOT);
      if (isCMSRoute) return "CMS";

      return "MAIN";
    },
    shouldShowSettingsButton() {
      const isOnSettings = this.drawerType === "SETTINGS";
      const isOnProjectView = this.drawerType === "PROJECT";
      return !(isOnProjectView || isOnSettings);
    },
  },
  methods: {
    onSleekOpen(v) {
      window.$sleek.open(v);
    },
    onArticlesOpen() {
      window.open("https://hjelp.gripr.no/", "_blank");
    },
    isUnderRoute(routeName) {
      return this.$route.matched.some((m) => m.name === routeName);
    },
    onLogHoursClick() {
      this.dialog.newTimeEntry.active = true;
    },
    onCreateProjectClick() {
      this.dialog.newProject.active = true;
    },
    onNewTaskClick() {
      this.dialog.newTask.active = true;
    },
    onFillOutFormClick() {
      this.dialog.newForm.active = true;
    },
    async onFormSelect({ templateId, onFormCreated, onFormFinally }) {
      this.$store
        .dispatch("forms/convertTemplate", {
          id: templateId,
          body: { projectId: this.projectId },
        })
        .then((form) => {
          onFormCreated();
          if (form) {
            this.$router.push({
              name: this.$routeNames.FORM_VIEW.ROOT,
              params: { formId: form.id },
            });
          }
        })
        .finally(() => {
          onFormFinally();
        });
    },

    //swipe functionality for drawer on tablets
    onDrawerVariantToggle() {
      this.$store.commit("appDrawer/setIsDrawerMiniVariant", !this.isMini);
    },
    handleTouchStart(evt) {
      const firstTouch = evt.touches[0];
      this.swipe.startX = firstTouch.clientX;
      this.swipe.startY = firstTouch.clientY;
    },

    handleTouchMove(evt) {
      if (!this.swipe.startX || !this.swipe.startY) {
        return;
      }
      evt.preventDefault();
    },
    handleTouchEnd(evt) {
      if (!this.swipe.startX || !this.swipe.startY) {
        return;
      }

      const endX = evt.changedTouches[0].clientX;
      const endY = evt.changedTouches[0].clientY;

      const swipeXLength = endX - this.swipe.startX;
      const swipeYLength = endY - this.swipe.startY;

      const swipeThreshold = 20;

      const isSwipeAboveThreshold = Math.abs(swipeXLength) > swipeThreshold;
      if (isSwipeAboveThreshold) {
        const isMoreHorizontal = Math.abs(swipeXLength) > Math.abs(swipeYLength);
        if (isMoreHorizontal) {
          const isRightSwipeAndMini = swipeXLength > 0 && this.isMini;
          const isLeftSwipeAndNotMini = swipeXLength < 0 && !this.isMini;
          if (isRightSwipeAndMini) {
            // Right swipe
            this.onDrawerVariantToggle();
          } else if (isLeftSwipeAndNotMini) {
            // Left swipe
            this.onDrawerVariantToggle();
          }
        }
      }

      // Reset values
      this.swipe.startX = 0;
      this.swipe.startY = 0;
    },
  },
};
</script>

<style>
.v-navigation-drawer__border {
  background-color: var(--v-primary-border-base) !important;
}
.v-icon.v-icon {
  transition: 0s;
}
</style>
